//  LoginTheme.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface for user login.
//  allowing users to login with their email, and password.
import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { history } from "_helpers";
import { login, loginReset } from "_store/login.slice";
import LoaderComponent from "partials/Loader";
import Footer from "partials/Footer";
import { ClipLoader } from "react-spinners";
import SideBarComponent from "partials/Sidebar";

const LoginTheme = () => {
	const dispatch = useDispatch();
	const authUser = useSelector((x) => x.auth.user);
	const authError = useSelector((x) => x.auth.error);

	useEffect(() => {
		console.log({ authUser });

		if (authUser && authUser?.roleId == 1) {
			history.navigate("/dashboard");
		}
		if ((authUser && authUser?.roleId == 4) || authUser?.roleId == 3) history.navigate("/");
	}, [authUser]);

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			console.log({ values });
			await dispatch(login(values));
			setSubmitting(false);
		} catch (error) {
			setSubmitting(false);
		}
	};
	useEffect(() => {
		dispatch(loginReset());
	}, []);

	const validationSchema = Yup.object().shape({
		email: Yup.string().email("Please use correct email").required("Email is required"),
		password: Yup.string().required("Password is required"),
	});

	return (
		<div className="login-img">
			<LoaderComponent />

			<div className="page">
				{/* PAGE */}
				<div className="page login-page">
					<div>
						{/* CONTAINER OPEN */}
						<div className="col col-login mx-auto mt-7">
							<div className="text-center">
								<img src="../images/brand-logos/desktop-white.png" className="header-brand-img" alt="" />
							</div>
						</div>
						<div className="container-login100">
							<div className="card  wrap-login100 p-0">
								<div className="col-md-12 col-login mx-auto mt-7">
									<div className="text-center">
										{authError && (
											<p className={`alert alert-danger`} role="alert">
												{authError}
											</p>
										)}{" "}
									</div>
								</div>
								<div className="card-body">
									<Formik initialValues={{ email: "", password: "" }} validationSchema={validationSchema} onSubmit={handleSubmit}>
										{({ isSubmitting, errors, touched }) => (
											<Form className="login100-form validate-form">
												<span className="login100-form-title">Login</span>
												<div className="wrap-input100 ">
													<Field type="text" id="email" name="email" className={`form-control input100 ${touched.email && errors.email ? "is-invalid" : ""}`} placeholder="Email" />
													<span className="focus-input100" />
													<span className="symbol-input100">
														<i className="ri-mail-fill" aria-hidden="true" />
													</span>
												</div>
												<ErrorMessage name="email" component="div" className={`invalid-feedback ${touched.email ? "d-block" : ""}`} />

												<div className="mb-3 wrap-input100 validate-input" data-bs-validate="Password is required">
													<Field type="password" id="input2" name="password" className={`form-control input100 ${touched.password && errors.password ? "is-invalid" : ""}`} placeholder="Password" />
													<span className="focus-input100" />
													<span className="symbol-input100">
														<i className="ri-lock-fill" aria-hidden="true" />
													</span>
												</div>
												<ErrorMessage name="password" component="div" className={`invalid-feedback ${touched.password ? "d-block" : ""}`} />

												{/* <button type="submit" disabled={isSubmitting} className="btn btn-primary">
													{isSubmitting && <span className="spinner-border spinner-border-sm me-1" />}
													Login
												</button> */}
												<div className="text-end pt-1">
													<p className="mb-0">
														<a href="#" className="text-primary ms-1">
															Forgot Password?
														</a>
													</p>
												</div>
												<div className="container-login100-form-btn">
													<button type="submit" disabled={isSubmitting} className="login100-form-btn btn-primary">
														Login <ClipLoader loading={isSubmitting} size={"13px"} color="white" />
													</button>
												</div>
												<div className="text-center pt-3">
													<p className="text-dark mb-0">
														Not a Customer? {"        "}
														<Link to="/register" className="text-primary ms-1">
															Register
														</Link>
													</p>
													<p className="text-dark mb-0">
														Not a Technician?
														<Link to="/register/technician" className="text-primary ms-1">
															Register
														</Link>
													</p>
												</div>
											</Form>
										)}
									</Formik>
								</div>
								{/*<div className="card-footer border-top">*/}
								{/*	<div className="d-flex justify-content-center my-3">*/}
								{/*		<a href="javascript:void(0);" className="social-login  text-center">*/}
								{/*			<i className="ri-google-fill" />*/}
								{/*		</a>*/}
								{/*		<a href="javascript:void(0);" className="social-login  text-center mx-4">*/}
								{/*			<i className="ri-facebook-fill" />*/}
								{/*		</a>*/}
								{/*		<a href="javascript:void(0);" className="social-login  text-center">*/}
								{/*			<i className="ri-twitter-fill" />*/}
								{/*		</a>*/}
								{/*	</div>*/}
								{/*</div>*/}
							</div>
						</div>
						{/* CONTAINER CLOSED */}
					</div>
				</div>
				{/* End PAGE */}
				{/* @SPK@include("partials/headersearch_modal.html") */}
				{/* @SPK@include("partials/footer.html") */}
			</div>
		</div>
	);
};

export default LoginTheme;
