//  machineSave.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's machine save state on techincian panel.
import axios from "axios";
import { logout } from "_store/login.slice";
import Swal from "sweetalert2";
import { fetchMachines } from "./machine.slice";
import { history } from "_helpers";

// Action Types
const SAVE_EMACHINE_REQUEST = "SAVE_EMACHINE_REQUEST";
const SAVE_EMACHINE_SUCCESS = "SAVE_EMACHINE_SUCCESS";
const SAVE_EMACHINE_FAILURE = "SAVE_EMACHINE_FAILURE";
const RESET_EMACHINE_REQUEST = "RESET_EMACHINE_REQUEST";

// Action Creators
const saveEMachineRequest = () => ({
	type: SAVE_EMACHINE_REQUEST,
});

export const resetEMachineSaved = () => ({
	type: RESET_EMACHINE_REQUEST,
});

export const saveEMachineSuccess = (eMachine) => ({
	type: SAVE_EMACHINE_SUCCESS,
	payload: eMachine,
});

const saveEMachineFailure = (error) => ({
	type: SAVE_EMACHINE_FAILURE,
	payload: error,
});

// Thunk Action
export const saveEMachine = (eMachineData) => {
	return async (dispatch) => {
		dispatch(saveEMachineRequest());
		const user = JSON.parse(localStorage.getItem("user"));
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/admin/e-machine`, eMachineData, {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			});
			dispatch(saveEMachineSuccess(response.data.response));
			history.navigate('/dashboard/e-machine')
			// Additional logic if needed
		} catch (error) {
			if (error.response && error.response.status === 401) {
				Swal.fire({
					icon: "error",
					title: "You have been logged out! Please login again",
					timer: 3000,
				});
				dispatch(logout());
			} else {
				dispatch(saveEMachineFailure(error.message));
			}
		}
	};
};

// Initial State
const initialState = {
	savedEMachine: null,
	loadingSaveEMachine: false,
	errorSaveEMachine: null,
};

// Reducer
const saveEMachineReducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_EMACHINE_REQUEST:
			return {
				...state,
				loadingSaveEMachine: true,
				errorSaveEMachine: null,
			};
		case RESET_EMACHINE_REQUEST:
			return {
				savedEMachine: null,
				loadingSaveEMachine: false,
				errorSaveEMachine: null,
			};
		case SAVE_EMACHINE_SUCCESS:
			return {
				...state,
				loadingSaveEMachine: false,
				savedEMachine: action.payload,
			};
		case SAVE_EMACHINE_FAILURE:
			return {
				...state,
				loadingSaveEMachine: false,
				errorSaveEMachine: action.payload,
			};
		default:
			return state;
	}
};

export default saveEMachineReducer;
