//  UserManagementTheme.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to manege User Listing on admin side.
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { fetchUserProfile, resetUserProfile } from "_store/admin/users.slice";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import { approveProfile } from "_store/admin/approval.slice";
import { approveCompanyProfile } from "_store/admin/approvalCompany.slice";

const UserManagement = ({ fetchUserProfile, resetUserProfile, approveProfile, approveCompanyProfile, users }) => {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [sortBy, setSortBy] = useState({});
	const dispatch = useDispatch();
	useEffect(() => {
		console.log();
		fetchUserProfile({ page, perPage, sortBy });
		return () => {
			// Reset user profile state when component unmounts
			resetUserProfile();
		};
	}, [fetchUserProfile, page, perPage, sortBy]);

	useEffect(() => {
		console.log({ users });
	}, [users]);
	// Sample JSON data for user list
	const data = users?.user?.users || [];

	// // Define columns for the DataTable
	const columns = [
		{
			name: "Full Name",
			selector: (row) => row.fullName,
			sortable: true,
		},

		{
			name: "Email",
			selector: (row) => row.emailAddress,
			sortable: true,
		},
		{
			name: "Role",
			selector: (row) => {
				if (row.roleId) {
					switch (row.roleId) {
						case "1":
							return "Admin";
						case "2":
							return "Support Staff";
						case "3":
							return "Technician";
						case "4":
							return "Customer";
					}
				}
			},
			sortable: true,
		},
		{
			name: "Technician Profile",
			selector: (row) => {
				if (row?.Technician !== null) {
					return "Created";
				} else {
					return "Not Created";
				}
			},
			sortable: true,
		},
		{
			name: "Company Profile",
			selector: (row) => {
				if (row?.Technician !== null) {
					if (row?.Technician?.Company !== null) return "Created";
				} else {
					return "Not Created";
				}
			},
			sortable: true,
		},
		// {
		// 	name: "Verified",
		// 	selector: "verified",
		// 	sortable: true,
		// },
		{
			name: "Actions",
			cell: (row) => {
				return (
					<div className="row">
						<div className="col-12">
							{row?.Technician !== null && (
								<button className="btn btn-primary " onClick={() => handleApprovedProfile(row)}>
									{row?.Technician?.isApproved == true ? <i className="fa fa-unlock" aria-hidden="true"></i> : <i className="fa fa-lock" aria-hidden="true"></i>} Profile
								</button>
							)}
						</div>
						<div className="col-12 " style={{ marginTop: "10px" }}>
							{row?.Technician !== null && row?.Technician?.Company !== null && (
								<button className="btn btn-primary" onClick={() => handleApprovedCompany(row)}>
									{row?.Technician?.Company?.isApproved == true ? <i className="fa fa-unlock" aria-hidden="true"></i> : <i className="fa fa-lock" aria-hidden="true"></i>} Company
								</button>
							)}
						</div>
					</div>
				);
			},
		},
	];
	// const columns = [
	// 	{
	// 		name: 'Title',
	// 		selector: row => row.title,
	// 	},
	// 	{
	// 		name: 'Year',
	// 		selector: row => row.year,
	// 	},
	// ];

	// Define action to handle blocking user
	const handleApprovedProfile = (user) => {
		// Implement logic to block user
		approveProfile(user, page);
	};
	const handleApprovedCompany = (user) => {
		// Implement logic to block user
		approveCompanyProfile(user, page);
	};

	// Handle pagination change
	const handlePageChange = (page) => {
		setPage(page);
	};

	// Handle rows per page change
	const handlePerPageChange = (newPerPage, page) => {
		setPage(1);
		setPerPage(newPerPage);
	};

	// Handle sorting change
	const handleSort = (column, direction) => {
		setSortBy({ field: column.selector, direction });
	};

	return (
		<>
			{/* Main content wrapper */}
			<div className="content-wrapper">
				{/* Content header */}
				<div className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h1 className="m-0">User Management</h1>
							</div>
						</div>
					</div>
				</div>

				{/* Users list */}
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<DataTable
											columns={columns}
											data={data}
											fixedHeader={true}
											persistTableHead={true}
											progressPending={users?.loadingUser}
											progressComponent={
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														height: "300px",
													}}>
													<ClipLoader loading={users?.loadingUser} />
												</div>
											}
											pagination
											paginationServer
											paginationTotalRows={users?.user?.total}
											onChangePage={handlePageChange}
											onChangeRowsPerPage={handlePerPageChange}
											paginationPerPage={perPage}
											paginationRowsPerPageOptions={[10, 20, 30]}
											// sortServer
											sortIcon={<span className="fas fa-sort" />}
											onSort={handleSort}
											className=""
											id="datatable-basic"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	users: state.admin, // Assuming the user data is stored in state.admin.user
});

const mapDispatchToProps = {
	fetchUserProfile,
	resetUserProfile,
	approveProfile,
	approveCompanyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
