//  TechnicianServiceRequestListing.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to manage technician service request listing for technician
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { history } from "_helpers";
import PageHeader from "../../partials/PageHeader";
import Pagination from "../../partials/Pagination";
import {fetchAdminServices, resetAdminServiceRequest } from'../../_store/admin/adminService.slice';
import {fetchTechnicianServices, resetTechnicianServiceRequest} from "../../_store/tehnician/technicianService.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { faSortUp, faSortDown, faSort, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {ClipLoader} from "react-spinners";
import * as Yup from "yup";
import Swal from "sweetalert2";
const TechnicianServiceRequestListing =({fetchTechnicianServices,resetAdminServiceRequest,technicianService,isLoadingData}) =>{
    const [page, setPage] = useState(1);
    const [requestId, setRequesId] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState({});
    const [sortConfig, setSortConfig] = React.useState({
        column: "id",
        order: "desc",
    });
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [searchFilters, setSearchFilters] = React.useState({
        id: "",
        fullName: "",
        email: "",
        role: "",
    });
    const pageSize = 10;
    const handleSortChange = (columnName) => {
        if (sortConfig.column === columnName) {
            // Toggle the sorting order if the same column is clicked again
            setSortConfig({
                ...sortConfig,
                order: sortConfig.order === "asc" ? "desc" : "asc",
            });
        } else {
            // If a new column is clicked, set it as the sorting column and reset the order to 'asc'
            setSortConfig({ column: columnName, order: "asc" });
        }
    };
    const technician = [
        { label: "Chris Thompson", value: 1},
        { label: "John Doe", value: 1},
        { label: "Jane Smith", value: 1 },
        { label: "Emily Brown", value: 1 },
        { label: "David Wilson", value: 1 },
        { label: "Sarah Martinez", value: 1 },
        { label: "Ella Hernandez", value: 1 },
        { label: "William Lewis", value: 1 },
        { label: "Grace Ward", value:1 },
        { label: "Luke Foster", value: 1 },
        { label: "Chloe Cooper", value: 1 },

    ];
    const result = [
        {
            "emachine": "Electric Bike Model X",
            "description": "Battery replacement required",
            "assignee": "John Doe",
            "status": "In Progress"
        },
        {
            "emachine": "Electric Scooter Model Y",
            "description": "Brake repair needed",
            "assignee": "Jane Smith",
            "status": "Completed"
        },
        {
            "emachine": "E-Bike Model Z",
            "description": "Motor not working",
            "assignee": "Not Assigned",
            "status": "Pending"
        },
        {
            "emachine": "Electric Skateboard",
            "description": "Wheels need replacement",
            "assignee": "Emily Brown",
            "status": "In Progress"
        },
        {
            "emachine": "Electric Unicycle",
            "description": "Charger malfunctioning",
            "assignee": "David Wilson",
            "status": "Pending"
        },
        {
            "emachine": "Electric Hoverboard",
            "description": "Balance sensor issue",
            "assignee": "Sarah Martinez",
            "status": "Completed"
        },
        {
            "emachine": "E-Scooter",
            "description": "Handlebar repair needed",
            "assignee": "Chris Thompson",
            "status": "In Progress"
        }
    ]
// // Define columns for the DataTable
    const columns = [
        {
            name: "#",
            selector: "id",
            sort: true,
        },
        {
            name: "Customer",
            selector: "customerId",
            sort: true,
        },
        {
            name: "E-Machine",
            selector: "eMachineId",
            sort: true,
        },
        {
            name: "Problem Description",
            selector: "description",
            sort: true,
        },
        {
            name: "State",
            selector: "state",
            sort: true,
        },
        {
            name: "City",
            selector: "city",
            sort: true,
        },
        {
            name: "ZipCode",
            selector: "zipCode",
            sort: true,
        },
        {
            name: "Status",
            selector: "statusId",
            sort: true,
        },
        // {
        //     name: "Action",
        //     selector: "action",
        //     sort: false,
        // },

    ];
    // Handle pagination change
    const handlePageChange = async (page) => {
        setCurrentPage(page);
        await dispatch(fetchTechnicianServices({ pageSize, searchFilters, sortConfig, currentPage, perPage }));
    };

    // Handle rows per page change
    const handlePerPageChange = (newPerPage, page) => {
        setPage(1);
        setPerPage(newPerPage);
    };

    // Handle sorting change
    const handleSort = (column, direction) => {
        setSortBy({ field: column.selector, direction });
    };
    useEffect(() => {
        console.log(); // Logs an empty message
        fetchTechnicianServices({ pageSize, searchFilters, sortConfig, currentPage, perPage }); // Fetches technician services with provided parameters
        return () => {
            resetTechnicianServiceRequest(); // Resets technician service request
        };
    }, [fetchTechnicianServices, pageSize, searchFilters, sortConfig, currentPage, perPage]); // Dependencies for useEffect

    useEffect(() => {
        // Calculate the total number of pages based on the total items and items per page
        setTotalPages(Math.ceil(technicianService?.technicianServiceRequest?.totalItems / perPage));
    }, [technicianService]);


    return (
        <>
            <PageHeader title={"Service Requests"} subtitle={"Service"} />

            {/* Main content wrapper */}
            <div className="content-wrapper">
                {/* Content header */}

                {/* Users list */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card custom-card card-with-button">
                                    <div class="card-header d-flex justify-content-between align-items-center">
                                        <div class="card-title">Service Request</div>

                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive rounded card-table">
                                            <div className="row" style={{ marginBottom: "10px" }}>
                                                <div className="col-sm-12 col-md-6 ">
                                                    <div className="dataTables_length" id="datatable-basic_length">
                                                        <label className="d-inline-flex align-items-center">
                                                            Show{" "}
                                                            <select name="datatable-basic_length" aria-controls="datatable-basic" className="form-select form-select-sm ms-2 mr-2	" onChange={(e) => setPerPage(e.target.value)}>
                                                                <option value={10}>10</option>
                                                                <option value={25}>25</option>
                                                                <option value={50}>50</option>
                                                                <option value={100}>100</option>
                                                            </select>
                                                            <span className="ml-2">entries</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <div className="col-sm-12 col-md-6">
												<div id="datatable-basic_filter" className="dataTables_filter">
													<label>
														<input type="search" className="form-control form-control-sm" placeholder="Search..." aria-controls="datatable-basic" />
													</label>
												</div>
											</div> */}
                                            </div>
                                            {isLoadingData ? (
                                                <div className="text-center">
                                                    <ClipLoader  size={50} loading={true} />
                                                </div>
                                            ) : (
                                                <>

                                            <table className="table table-bordered text-nowrap dataTable no-footer" id="example1">
                                                <thead>
                                                <tr>
                                                    {columns.map((column, index) => (
                                                        <th key={index} onClick={() => (column.sort ? handleSortChange(column.selector) : null)} style={{ cursor: column.sort ? "pointer" : "default" }}>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <span>{column.name}</span>
                                                                {column.sort && (
                                                                    <FontAwesomeIcon
                                                                        icon={sortConfig.column === column.selector ? (sortConfig.order === "asc" ? faSortUp : faSortDown) : faSort}
                                                                        style={{ fontSize: "0.8em", marginLeft: "5px" }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </th>
                                                    ))}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {technicianService?.technicianServiceRequest?.serviceRequests &&
                                                technicianService?.technicianServiceRequest?.serviceRequests.length !== 0 &&
                                                technicianService?.technicianServiceRequest?.serviceRequests.map((tech, i) => (
                                                    <tr className="hover-primary" key={i}>
                                                        <td>{tech.id}</td>
                                                        <td>{tech.Customer?.name}</td>
                                                        <td>{tech.EMachine?.deviceName}</td>
                                                        <td>{tech.description}</td>
                                                        <td>{tech.state?tech.state:"N/A"}</td>
                                                        <td>{tech.city?tech.city:"N/A"}</td>

                                                        <td>{tech.zipCode?tech.zipCode:"N/A"}</td>

                                                        <td>
                                                            {tech.ServiceRequestStatus.name === "Pending" ? <span className="badge bg-warning"> {tech.ServiceRequestStatus.name} </span>:''}
                                                            {tech.ServiceRequestStatus.name === "In Progress" ? <span className="badge bg-secondary"> {tech.ServiceRequestStatus.name} </span>:''}
                                                            {tech.ServiceRequestStatus.name === "Completed" ? <span className="badge bg-success"> {tech.ServiceRequestStatus.name} </span>:''}
                                                        </td>

                                                    </tr>
                                                ))}


                                                </tbody>

                                            </table>

                                            <div>
                                                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                            </div>
                                            </>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({

    technicianService:state.technicianService, // Assuming the technicianService data is stored in state.technicianService
    isLoadingData:state.technicianService?.loadingTechnicianService, // Assuming the technicianService data is stored in state.technicianService
});

const mapDispatchToProps = {

    fetchTechnicianServices,
    resetTechnicianServiceRequest,

};

export default connect(mapStateToProps, mapDispatchToProps)(TechnicianServiceRequestListing);