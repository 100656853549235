//  serviceSave.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store's save service state on customer panel.
import axios from "axios";
import { logout } from "_store/login.slice";
import Swal from "sweetalert2";
import { history } from "_helpers";

// Action Types
const SAVE_SERVICE_REQUEST = "SAVE_SERVICE_REQUEST";
const SAVE_SERVICE_SUCCESS = "SAVE_SERVICE_SUCCESS";
const SAVE_SERVICE_FAILURE = "SAVE_SERVICE_FAILURE";
const RESET_SERVICE_REQUEST = "RESET_SERVICE_REQUEST";

// Action Creators
const saveServiceRequest = () => ({
	type: SAVE_SERVICE_REQUEST,
});

export const resetEMachineSaved = () => ({
	type: RESET_SERVICE_REQUEST,
});

export const saveServiceSuccess = (service) => ({
	type: SAVE_SERVICE_SUCCESS,
	payload: service,
});

const saveServiceFailure = (error) => ({
	type: SAVE_SERVICE_FAILURE,
	payload: error,
});

// Thunk Action
export const saveService = (serviceData) => {
	return async (dispatch) => {
		dispatch(saveServiceRequest());
		const user = JSON.parse(localStorage.getItem("user"));
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/customer/service-requests/generate`, serviceData, {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			});
			dispatch(saveServiceSuccess(response.data.response));
			Swal.fire({
				icon: "success",
				title: response.data.message,
				timer: 3000,
			});
			history.navigate("/customer/service-request-list");
			// Additional logic if needed
		} catch (error) {
			if (error.response && error.response.status === 401) {
				Swal.fire({
					icon: "error",
					title: "You have been logged out! Please login again",
					timer: 3000,
				});
				dispatch(logout());
			} else {
				dispatch(saveServiceFailure(error.message));
			}
		}
	};
};

// Initial State
const initialState = {
	savedService: null,
	loadingSaveService: false,
	errorSaveService: null,
};

// Reducer
const saveServiceReducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_SERVICE_REQUEST:
			return {
				...state,
				loadingSaveService: true,
				errorSaveService: null,
			};
		case RESET_SERVICE_REQUEST:
			return {
				savedService: null,
				loadingSaveService: false,
				errorSaveService: null,
			};
		case SAVE_SERVICE_SUCCESS:
			return {
				...state,
				loadingSaveService: false,
				savedService: action.payload,
			};
		case SAVE_SERVICE_FAILURE:
			return {
				...state,
				loadingSaveService: false,
				errorSaveService: action.payload,
			};
		default:
			return state;
	}
};

export default saveServiceReducer;
