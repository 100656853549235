//  Sidebar.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file displays navigation links and user information.
//
import { history } from "_helpers";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const SideBarComponent = () => {
	const authUser = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const activeProfile = useSelector((state) => state.users.profileActive);
	const { company } = useSelector((state) => state.company.profile);
	useEffect(() => {
		// Load external scripts after the component mounts
		// window.location.reload();
		console.log(company?.name,'ssss');
	}, []); // Run effect only once when the component mounts

	return (
		<>
			{/* Start::app-sidebar */}
			<aside className="app-sidebar sticky" id="sidebar">
				{/* Start::main-sidebar-header */}
				<div className="main-sidebar-header">
				<span onClick={()=>history.navigate("/")} className="header-logo" style={{cursor:'pointer'}}>
						<h1 style={{fontSize:'20px'}}>LSolutions</h1>
						{/*<img src="../images/brand-logos/desktop-logo.png" alt="logo" className="desktop-logo" />*/}
						{/*<img src="../images/brand-logos/toggle-logo.png" alt="logo" className="toggle-logo" />*/}
						{/*<img src="../images/brand-logos/desktop-white.png" alt="logo" className="desktop-dark" />*/}
						{/*<img src="../images/brand-logos/toggle-dark.png" alt="logo" className="toggle-dark" />*/}
						{/*<img src="../images/brand-logos/desktop-white.png" alt="logo" className="desktop-white" />*/}
						{/*<img src="../images/brand-logos/toggle-white.png" alt="logo" className="toggle-white" />*/}
					</span>
				</div>
				{/* End::main-sidebar-header */}
				{/* Start::main-sidebar */}
				<div className="main-sidebar" id="sidebar-scroll">
					{/* Start::nav */}
					<nav className="main-menu-container nav nav-pills flex-column sub-open">
						<div className="slide-left" id="slide-left">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width={24} height={24} viewBox="0 0 24 24">
								<path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
							</svg>
						</div>
						<ul className="main-menu">
							{/* Start::slide__category */}
							<li className="slide__category">
								<span className="category-name">Main</span>
							</li>
							{/* End::slide__category */}
							{/* Start::slide */}
							{authUser?.roleId == "1" && (
								<li className="slide">
									<NavLink to="/admin" className="side-menu__item">
										<i className="fe fe-home side-menu__icon" />
										<span className="side-menu__label">Dashboard</span>
									</NavLink>
								</li>
							)}
							{authUser?.roleId == "3" && (
								<li className="slide">
									<NavLink to="/" className="side-menu__item">
										<i className="fe fe-home side-menu__icon" />
										<span className="side-menu__label">Dashboard</span>
									</NavLink>
								</li>
							)}
							{authUser?.roleId === "4" && (
								<>
								<li className="slide">
									<NavLink to="/" className="side-menu__item">
										<i className="fe fe-home side-menu__icon" />
										<span className="side-menu__label">Dashboard</span>
									</NavLink>
								</li>
									<li className="slide__category">
										<span className="category-name">Service</span>
									</li>
								<li className={`slide `}>
								<NavLink to="/customer/e-machine-request" className="side-menu__item">
								<i className="bi bi-robot side-menu__icon" />
								<span className="side-menu__label">E-Machine Requests</span>
								</NavLink>
								</li>

								<li className={`slide `}>
								<NavLink to="/customer/service-request-list" className="side-menu__item">
								<i className="bi bi-robot side-menu__icon" />
								<span className="side-menu__label">Service Requests</span>
								</NavLink>
								</li>
									<li className={`slide `}>
										<NavLink to="/customer/generate-service-request" className="side-menu__item">
											<i className="bi bi-robot side-menu__icon" />
											<span className="side-menu__label">Generate Service Request</span>
										</NavLink>
									</li>

								</>
							)}

							{authUser?.roleId == "3" &&  (
									<>
										<li className="slide__category">
											<span className="category-name">PROFILES</span>
										</li>
										{/* End::slide__category */}
										{/* Start::slide */}
										<li className="slide">
											{authUser?.roleId == "3" && (
												<NavLink to="/technician/profile" className="side-menu__item">
													<i className="fe fe-user side-menu__icon" />
													<span className="side-menu__label">Technician</span>
												</NavLink>
											)}
										</li>
										<li className={`slide `}>
											{authUser?.roleId == "3" && (company?.name || authUser.profile?.Company?.id) && (
												<NavLink to="/technician/company" className="side-menu__item">
													<i className="bi bi-building side-menu__icon" />
													<span className="side-menu__label">Company</span>
												</NavLink>
											)}
										</li>
										<li className={`slide `}>
											{authUser?.roleId == "3" &&  (
												<NavLink to="/technician/service-request" className="side-menu__item">
													<i className="bi bi-robot side-menu__icon" />
													<span className="side-menu__label">Service Request</span>
												</NavLink>
											)}
										</li>
									</>
								)}
							{authUser?.roleId == "1" && (
								<>
									<li className="slide__category">
										<span className="category-name">CONTROLS</span>
									</li>
									{/* End::slide__category */}
									{/* Start::slide */}
									<li className="slide">
										<NavLink to="/dashboard/users" className="side-menu__item">
											<i className="fe fe-user side-menu__icon" />
											<span className="side-menu__label">Users </span>
										</NavLink>
									</li>
									<li className={`slide `}>
										<NavLink to="/dashboard/e-machine" className="side-menu__item">
											<i className="bi bi-robot side-menu__icon" />
											<span className="side-menu__label">E Machine </span>
										</NavLink>
									</li>
									<li className={`slide `}>
										<NavLink to="/dashboard/service-request-listing" className="side-menu__item">
											<i className="bi bi-robot side-menu__icon" />
											<span className="side-menu__label">Service Requests</span>
										</NavLink>
									</li>
									<li className={`slide `}>
										<NavLink to="/dashboard/e-machine-request-listing" className="side-menu__item">
											<i className="bi bi-robot side-menu__icon" />
											<span className="side-menu__label">E-Machine Requests</span>
										</NavLink>
									</li>
									
								</>
							)}
						</ul>
						<div className="slide-right" id="slide-right">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width={24} height={24} viewBox="0 0 24 24">
								<path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
							</svg>
						</div>
					</nav>
					{/* End::nav */}
				</div>
				{/* End::main-sidebar */}
			</aside>
			{/* End::app-sidebar */}
		</>
	);
};

export default SideBarComponent;
