//  assignTechnician.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's assign technician state on admin panel.
import axios from "axios";
import { logout } from "_store/login.slice";
import Swal from "sweetalert2";
import {fetchAdminServices} from "./adminService.slice";
import { history } from "_helpers";

// Action Types
const ASSIGN_TECHNICIAN_REQUEST = "ASSIGN_TECHNICIAN_REQUEST";
const ASSIGN_TECHNICIAN_SUCCESS = "ASSIGN_TECHNICIAN_SUCCESS";
const ASSIGN_TECHNICIAN_FAILURE = "ASSIGN_TECHNICIAN_FAILURE";
const RESET_ASSIGN_TECHNICIAN_REQUEST = "RESET_ASSIGN_TECHNICIAN_REQUEST";

// Action Creators
const assignTechnicianRequest = () => ({
    type: ASSIGN_TECHNICIAN_REQUEST,
});

export const resetTechnicianRequest = () => ({
    type: RESET_ASSIGN_TECHNICIAN_REQUEST,
});

export const assignTechnicianSuccess = (service) => ({
    type: ASSIGN_TECHNICIAN_SUCCESS,
    payload: service,
});

const assignTechnicianFailure = (error) => ({
    type: ASSIGN_TECHNICIAN_FAILURE,
    payload: error,
});

// Thunk Action
export const assignTechnician = (serviceData,pageSize, searchFilters, sortConfig, currentPage, perPage) => {
    return async (dispatch) => {
        dispatch(assignTechnicianRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/service-requests/assign-technician`, {requestId:serviceData?.requestId,technicianId:serviceData?.technicianId}, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            dispatch(assignTechnicianSuccess(response.data.response));
            Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 3000,
            });
            dispatch(
                fetchAdminServices({
                    pageSize: serviceData.pageSize,
                    searchFilters: serviceData.searchFilters,
                    sortConfig: serviceData.sortConfig,
                    currentPage: serviceData.currentPage,
                    perPage: serviceData.perPage
                })
            );
            // history.navigate('/dashboart/service-request-listing')
            // Additional logic if needed
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(assignTechnicianFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    technicianRequest: null,
    loadingTechnicianRequest: false,
    errorTechnicianRequest: null,
};

// Reducer
const assignTechnicianReducer = (state = initialState, action) => {
    switch (action.type) {
        case ASSIGN_TECHNICIAN_REQUEST:
            return {
                ...state,
                loadingTechnicianRequest: true,
                errorTechnicianRequest: null,
            };
        case RESET_ASSIGN_TECHNICIAN_REQUEST:
            return {
                technicianRequest: null,
                loadingTechnicianRequest: false,
                errorTechnicianRequest: null,
            };
        case ASSIGN_TECHNICIAN_SUCCESS:
            return {
                ...state,
                loadingTechnicianRequest: false,
                technicianRequest: action.payload,
            };
        case ASSIGN_TECHNICIAN_FAILURE:
            return {
                ...state,
                loadingTechnicianRequest: false,
                errorTechnicianRequest: action.payload,
            };
        default:
            return state;
    }
};

export default assignTechnicianReducer;
