//  generateServiceRequest.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface for customer generate service Request
//
//  and add new E-Machine request if E-Machine not exist in E-Machine List.
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { history } from "_helpers";
import PageHeader from "../../../partials/PageHeader";
import Swal from "sweetalert2";
import {eMachineListing, resetEmachineRequest} from "../../../_store/customer/eMachineListing.slice";
import {createMachine} from "../../../_store/customer/createMachine.slice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSort, faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../partials/Pagination";
import {ErrorMessage, Field, Form, Formik, } from "formik";
import {saveService} from "../../../_store/customer/serviceSave.slice";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import {ClipLoader} from "react-spinners";
import { State, City } from "country-state-city";
import * as Yup from "yup";
import {NavLink} from "react-router-dom";

const GenerateServiceRequest = ({ service: { savedService, loadingSaveService, errorSaveService }, saveService ,eMachineListing,eMachines,createMachine})=>{
// Initialize state for new eMachine
const Emachines = [
        { label: "Electric Bike Model X", value: 1 },
        { label: "Electric Scooter Model Y", value: 1},
        { label: "E-Bike Model Z", value: 1 },
        { label: "Electric Skateboard", value: 1 },
        { label: "Electric Unicycle", value: 1},
        { label: "Electric Hoverboard", value:1 },
        { label: "E-Scooter", value: 1 },
        { label: "Electric Bicycle", value: 1 },
        { label: "Electric Motorcycle", value: 1 },
        { label: "Electric Scooter", value: 1 },
    ];
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState();
    const [value, setValue] = useState();
    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });
    const handleCreate = (inputValue,setFieldValue) => {
        setIsLoading(true);
        const newOption = createOption(inputValue);
        createMachine({
            "deviceName": newOption.value,
            // "serialNumber": String(Math.floor(Math.random() * 100)),
            // "model": "N/A",
            // "manufacturer": "N/A",
            // "purchaseDate": "2024-04-25",
            // "warrantyExpiryDate": "2024-04-25",
            // "description": "N/A",
            // "macAddress": "N/A",
            // "location": "N/A",
            // "operatingSystem": "N/A"
        })
        setTimeout(() => {
            // Assuming createOption returns an object
            setIsLoading(false);
            // Update options array using setNewEMachine
            // setNewEMachine((prevOptions) => [...prevOptions, newOption]);
            // Update selected value to the newly created option
            // setFieldValue("eMachineId", newOption);
            setValue(newOption);

        }, 1000);
    };


// Initialize state for new eMachine
const [newEMachine, setNewEMachine] = useState([]);

// Function to get updated states for a given country
const updatedStates = (countryId) => State.getStatesOfCountry(countryId).map((state) => ({ label: state.name, value: state.isoCode, ...state }));

// Function to get updated cities for a given country and state
const updatedCities = (countryId, stateId) => {
    // Get cities of a state and map to include label, value, and other properties
    const cities = City.getCitiesOfState(countryId, stateId).map((city) => ({ label: city.name, value: city.name, ...city }));
    return cities;
};

     useEffect(() => {
    eMachineListing(); // Invokes the eMachineListing function
    return () => {
        resetEmachineRequest(); // Resets the eMachine request
    };
     }, [eMachineListing]); // Runs the effect on eMachineListing dependency change


    useEffect(() => {
        // Print the machines from eMachines if it exists
        console.log(eMachines?.emachine?.machines);
        // Map over the machines to create an array of formatted devices
        const formattedDevices = eMachines?.emachine?.machines.map(device => ({
            label: device.deviceName,
            value: device.id
        }));

        // Set the newly formatted devices to state
        setNewEMachine(formattedDevices)
        setOptions(formattedDevices);
    }, [eMachines]);



    // Initial values for form fields
    const initialValues = {
        eMachineId: "",
        description: "",
        state: "",
        city: "",
        zipCode: "",
    };

    // Validation schema for form fields using Yup
    const validationSchema = Yup.object().shape({
        eMachineId: Yup.object().required("E Machine selection is required"),
        state: Yup.object().required("State is required"),
        city: Yup.object().required("City is required"),
        zipCode: Yup.number().required("ZipCode is required"),
        description: Yup.string().required("Problem Description is required"),
    });


const handleSubmit = (values) => {
        // Save the values to the service after mapping eMachineId, state, and city values from objects to simple string values
        saveService({ ...values, eMachineId: values.eMachineId.value ,state:values.state.value,city:values.city.value});
    };


    return (
        <>
            <PageHeader title={"Generate Service Request"} subtitle={"Service"} />

            {/* Main content wrapper */}
            <div className="content-wrapper">
                {/* Content header */}

                {/* Users list */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">

                                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                            {({ isSubmitting, errors, touched, values, setFieldValue, setFieldTouched }) => (
                                                <Form>

                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <div className="card custom-card edit-password-section">
                                                                <div className="card-header">
                                                                    <div className="card-title">Service Request</div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-3">
                                                                    <div className="form-group mb-3">
                                                                        <label className="form-label" htmlFor="eMachineId">E-Machine</label>
                                                                        {/*{newEMachine && <Select*/}
                                                                        {/*    className={`${touched.eMachineId && errors.eMachineId ? "is-invalid" : ""}`}*/}
                                                                        {/*    placeholder="Select E-Machine"*/}
                                                                        {/*    name="eMachineId"*/}
                                                                        {/*    options={newEMachine}*/}
                                                                        {/*    classNamePrefix="react-select"*/}
                                                                        {/*    onChange={(selectedOption) => {*/}
                                                                        {/*        setFieldValue("eMachineId", selectedOption);*/}
                                                                        {/*    }}*/}
                                                                        {/*    value={newEMachine.find((option) => option.value === values.eMachineId)}*/}
                                                                        {/*/>}*/}

                                                                        {newEMachine && (
                                                                            <CreatableSelect
                                                                                className={`${touched.eMachineId && errors.eMachineId ? "is-invalid" : ""}`}
                                                                                placeholder="Select or create E-Machine"
                                                                                name="eMachineId"
                                                                                isClearable
                                                                                isDisabled={isLoading}
                                                                                isLoading={isLoading}
                                                                                onChange={(selectedOption) => {
                                                                                    setFieldValue("eMachineId", selectedOption);
                                                                                }}
                                                                                onCreateOption={(inputValue) => handleCreate(inputValue, setFieldValue)}
                                                                                options={newEMachine}
                                                                                value={newEMachine.find((option) => option.value === values.eMachineId)}
                                                                            />
                                                                        )}


                                                                        <ErrorMessage name="eMachineId" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
                                                                    </div>
                                                                        </div>
                                                                        <div className="col-lg-9">
                                                                            <div className="row">
                                                                                <div className="col-lg-4">
                                                                                    <div className="form-group">

                                                                                        <label htmlFor="state" className="form-label"> State</label>

                                                                                        <Select
                                                                                            id="state"
                                                                                            name="state"
                                                                                            options={updatedStates("US")}
                                                                                            value={values.state}
                                                                                            onChange={(value) => {
                                                                                                setFieldValue("state", value);
                                                                                            }}
                                                                                        />
                                                                                        <ErrorMessage name="state" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="city" className="form-label"> City</label>
                                                                                        <Select
                                                                                            id="city"
                                                                                            name="city"
                                                                                            options={updatedCities("US", values.state ? values.state.value : null)}
                                                                                            value={values.city}
                                                                                            onChange={(value) => setFieldValue("city", value)}
                                                                                        />{" "}
                                                                                        <ErrorMessage name="city" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="zipCode" className="form-label">Zip Code</label>
                                                                                        <Field type="text" name="zipCode" className={`form-control ${touched.zipCode && errors.zipCode ? "is-invalid mb-2" : ""}`} />
                                                                                        <ErrorMessage name="zipCode" component="div" className="invalid-feedback" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    {/*<div className="form-group mb-3">*/}
                                                                    {/*    <label className="form-label" htmlFor="location">Location</label>*/}
                                                                    {/*    <Field type="text" name="location" className={`form-control ${touched.location && errors.location ? "is-invalid mb-2" : ""}`} />*/}
                                                                    {/*    <ErrorMessage name="location" component="div" className="invalid-feedback" />*/}
                                                                    {/*</div>*/}




                                                                        </div>

                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                    <div className="form-group mb-3">
                                                                        <label className="form-label" htmlFor="problem">Problem Description </label>
                                                                        <Field type="text" as="textarea" name="description" className={`form-control ${touched.description && errors.description ? "is-invalid mb-2" : ""}`} />
                                                                        <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                                                    </div>
                                                                        </div>
                                                                    </div>

                                                                    <button type="submit" className="btn btn-success generate-btn" disabled={isSubmitting || loadingSaveService}>
                                                                         <ClipLoader loading={loadingSaveService} size={10} /> Generate
                                                                    </button>
                                                                    {/*<button type="submit" className="btn btn-success">*/}
                                                                    {/*    Submit*/}
                                                                    {/*</button>*/}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>

                    </div>
                </section>
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({
    service: state.saveService, // Assuming the saveService data is stored in state.saveService
    eMachines:state.eMachines,  // Assuming the eMachines data is stored in state.eMachines
});

const mapDispatchToProps = {
    saveService,
    eMachineListing,
    createMachine,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateServiceRequest);