//  prerequsite.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's prerequsite state on techincian panel.
import axios from "axios";

// Action Types
const FETCH_TECHNICIAN_PROFILE_PREREQUISITE_REQUEST = "FETCH_TECHNICIAN_PROFILE_PREREQUISITE_REQUEST";
const FETCH_TECHNICIAN_PROFILE_PREREQUISITE_SUCCESS = "FETCH_TECHNICIAN_PROFILE_PREREQUISITE_SUCCESS";
const FETCH_TECHNICIAN_PROFILE_PREREQUISITE_FAILURE = "FETCH_TECHNICIAN_PROFILE_PREREQUISITE_FAILURE";

// Action Creators
const fetchTechnicianProfilePrerequisiteRequest = () => ({
  type: FETCH_TECHNICIAN_PROFILE_PREREQUISITE_REQUEST,
});

const fetchTechnicianProfilePrerequisiteSuccess = (prerequisite) => ({
  type: FETCH_TECHNICIAN_PROFILE_PREREQUISITE_SUCCESS,
  payload: prerequisite,
});

const fetchTechnicianProfilePrerequisiteFailure = (error) => ({
  type: FETCH_TECHNICIAN_PROFILE_PREREQUISITE_FAILURE,
  payload: error,
});

// Thunk Action
export const fetchTechnicianProfilePrerequisite = () => {
  return async (dispatch) => {
    dispatch(fetchTechnicianProfilePrerequisiteRequest());
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/technician-profile/prerequisite`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      dispatch(fetchTechnicianProfilePrerequisiteSuccess(response.data?.response));
    } catch (error) {
      dispatch(fetchTechnicianProfilePrerequisiteFailure(error.message));
    }
  };
};

// Initial State
const initialState = {
  data: null,
  loadingPrerequisite: false,
  error: null,
};

// Reducer
const technicianProfilePrerequisiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TECHNICIAN_PROFILE_PREREQUISITE_REQUEST:
      return {
        ...state,
        loadingPrerequisite: true,
        error: null,
      };
    case FETCH_TECHNICIAN_PROFILE_PREREQUISITE_SUCCESS:
      return {
        ...state,
        loadingPrerequisite: false,
        data: action.payload,
      };
    case FETCH_TECHNICIAN_PROFILE_PREREQUISITE_FAILURE:
      return {
        ...state,
        loadingPrerequisite: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default technicianProfilePrerequisiteReducer;
