//  updateService.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store's update service state on customer panel.
import { logout } from "_store/login.slice";
import axios from "axios";
import Swal from "sweetalert2";
import { history } from "_helpers";

// Action Types
const FETCH_UPDATE_SERVICE_REQUEST = "FETCH_UPDATE_SERVICE_REQUEST";
const FETCH_UPDATE_SERVICE_REQUEST_SUCCESS = "FETCH_UPDATE_SERVICE_REQUEST_SUCCESS";
const FETCH_UPDATE_SERVICE_REQUEST_FAILURE = "FETCH_UPDATE_SERVICE_REQUEST_FAILURE";
const RESET_UPDATE_SERVICE_REQUEST = "RESET_UPDATE_SERVICE_REQUEST";

// Action Creators
const fetchUpdateServiceRequest = () => ({
	type: FETCH_UPDATE_SERVICE_REQUEST,
});

const fetchUpdateServiceRequestSuccess = (serviceRequest) => ({
	type: FETCH_UPDATE_SERVICE_REQUEST_SUCCESS,
	payload: serviceRequest,
});

const fetchUpdateServiceRequestFailure = (error) => ({
	type: FETCH_UPDATE_SERVICE_REQUEST_FAILURE,
	payload: error,
});

export const resetUpdateServiceRequest = () => ({
	type: RESET_UPDATE_SERVICE_REQUEST,
});

// Thunk Action
export const updateServices = (serviceData) => {
	return async (dispatch) => {
		dispatch(fetchUpdateServiceRequest());
		const user = JSON.parse(localStorage.getItem("user"));
		try {
			const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/customer/service-requests/update`, serviceData, {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			});
			// await new Promise((resolve) => setTimeout(resolve, 4000));
			dispatch(fetchUpdateServiceRequestSuccess(response.data?.response));
			Swal.fire({
				icon: "success",
				title: response.data.message,
				timer: 3000,
			});
			history.navigate("/customer/service-request-list");
		} catch (error) {
			if (error.response && error.response.status === 404) {
				dispatch(fetchUpdateServiceRequestSuccess(null));
			}

			if (error.response && error.response.status === 401) {
				// If the response status is 401 (Unauthorized), dispatch the logout action
				Swal.fire({
					icon: "error",
					title: "You have been logged out! Please login again",
					timer: 3000,
				});
				dispatch(logout());
			} else {
				dispatch(fetchUpdateServiceRequestFailure(error.message));
			}
		}
	};
};

// Initial State
const initialState = {
	updateServiceRequest: null,
	loadingUpdateService: false,
	error: null,
};

// Reducer
const updateServiceRequestReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_UPDATE_SERVICE_REQUEST:
			return {
				...state,
				loadingUpdateService: true,
				error: null,
			};
		case RESET_UPDATE_SERVICE_REQUEST:
			return {
				updateServiceRequest: null,
				loadingUpdateService: false,
				error: null,
			};
		case FETCH_UPDATE_SERVICE_REQUEST_SUCCESS:
			return {
				...state,
				loadingUpdateService: false,
				updateServiceRequest: action.payload,
			};
		case FETCH_UPDATE_SERVICE_REQUEST_FAILURE:
			return {
				...state,
				loadingUpdateService: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default updateServiceRequestReducer;
