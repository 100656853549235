//  TechnicianProfile.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to create technician profile
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAll } from "_store/users.slice";
import { Nav } from "_components";
import * as Yup from "yup";
// import PhoneInput from "react-phone-number-input/input";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../styles.custom.css";
import { fetchTechnicianProfile } from "_store/tehnician/profile.slice";
import { ClipLoader } from "react-spinners";
import { resetTechnicianProfileSave, saveTechnicianProfile } from "_store/tehnician/profileSave.slice";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { State, City } from "country-state-city";
import TagsInput from "react-tagsinput";
import "../tags.css";
import { fetchTechnicianProfilePrerequisite } from "_store/tehnician/prerequsite.slice";
import CreatableSelect from "react-select/creatable";

const TechnicianProfile = () => {
	const dispatch = useDispatch();
	const [newEMachine, setNewEMachine] = useState([]);
	const [createEMachine, setCreateEMachine] = useState([]);
	const { user: authUser } = useSelector((state) => state.auth);
	const { users } = useSelector((state) => state.users);
	const {
		profile: { profile, loadingProfile },
		savedProfile,
	} = useSelector((state) => state.technician);
	const {
		prerequisite: { data, loadingPrerequisite },
	} = useSelector((state) => state.technician);
	const { company } = useSelector((state) => state.company.profile);

	// Trigger additional actions when the authenticated user changes
	useEffect(() => {
		// Add any side effects here
		dispatch(fetchTechnicianProfilePrerequisite());
		dispatch(fetchTechnicianProfile());
	}, []);
	useEffect(() => {
		console.log(data, "profile");
		setNewEMachine(data?.eMachine);
	}, [data]);

	const updatedStates = (countryId) => State.getStatesOfCountry(countryId).map((state) => ({ label: state.name, value: state.isoCode, ...state }));
	const updatedCities = (countryId, stateId) => {
		const cities = City.getCitiesOfState(countryId, stateId).map((city) => ({ label: city.name, value: city.name, ...city }));
		return cities;
	};
	useEffect(() => {});
	console.log({ updatedStates });

	const initialValues = {
		name: profile?.technicianProfile?.name || "",
		phoneNo: profile?.technicianProfile?.phoneNo || "",
		education: profile?.technicianProfile?.education || "",
		certifications: profile && profile?.technicianProfile?.certifications ? profile?.technicianProfile?.certifications : [],
		yearsOfExperience: profile?.technicianProfile?.yearsOfExperience || "",
		serviceType: profile?.technicianProfile?.serviceType || "",
		machineExpertise: profile?.technicianProfile?.machineExpertise.map((id) => parseInt(id, 10)) || [],
		experienceDetails: profile?.technicianProfile?.experienceDetails || "",
		city: profile && profile?.technicianProfile?.city ? JSON.parse(profile?.technicianProfile?.city) : "",
		serviceCoverageState: profile && profile?.technicianProfile?.serviceCoverageState ? JSON.parse(profile?.technicianProfile?.serviceCoverageState) : "",
		zipCodes: profile?.technicianProfile?.zipCodes || "",
	};
	console.log({ initialValues });

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Name is required"),
		phoneNo: Yup.string().required("Phone number is required"),
		education: Yup.string().required("Education is required"),
		certifications: Yup.array().min(1, "Certifications are required"),
		yearsOfExperience: Yup.number().required("Years of experience is required"),
		serviceType: Yup.string().required("Service type is required"),
		machineExpertise: Yup.array().min(1, "Select at least one machine expertise"),
		experienceDetails: Yup.string().required("Experience details are required"),
		serviceCoverageState: Yup.object().required("Service coverage state is required"),
		city: Yup.object().required("City is required"),
		zipCodes: Yup.string().required("ZipCode is required"),
	});
	useEffect(() => {
		if (savedProfile?.savedProfile) {
			Swal.fire({
				icon: "success",
				title: "Your Profile Saved Successfully",
				timer: 3000,
				timerProgressBar: true,
			}).then((result) => {
				/* Read more about handling dismissals below */
				if (result.dismiss === Swal.DismissReason.timer) {
					console.log("I was closed by the timer");
				}
			});
			dispatch(resetTechnicianProfileSave());
		}
	}, [savedProfile]);

	const handleSubmit = (values, { setSubmitting }) => {
		dispatch(saveTechnicianProfile({...values, machines: createEMachine}));
		setSubmitting(false);
	};
	const options = [
		{ value: "machine1", label: "Machine 1" },
		{ value: "machine2", label: "Machine 2" },
		{ value: "machine3", label: "Machine 3" },
	];

	return (
		<>
			{/* Render the navigation component */}
			<Nav />

			<div>
				<div className="row">
					<div className="col-md-6 offset-md-3">
						{loadingProfile && (
							<div className="loader-container">
								<div className="loader-wrapper">
									<ClipLoader loading={loadingProfile} size={100} color="white" />
								</div>
							</div>
						)}
						{!loadingProfile && (
							<div className="card profileForm">
								<div className="card-header">
									<span className="h1">Profile </span>
									{company?.name && (
										<NavLink to="/technician/company" className=" float-right text-decoration-none">
											Company Profile
										</NavLink>
									)}
									{profile?.technicianProfile?.isApproved &&(
									<span style={{color:'green'}}>Approved</span>)}
									{profile?.technicianProfile?.isApproved != null && !profile?.technicianProfile?.isApproved &&(
									<span style={{color:'red'}}>Not Approved</span>)}
									
								</div>
								<div className="card-body forms">
									<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
										{({ isSubmitting, errors, touched, values, setFieldValue, setFieldTouched }) => (
											<Form>
												{console.log(values)}
												<div className="section">
													<h3>Personal Info</h3>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="name">Name</label>
																<Field type="text" name="name" className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`} />
																<ErrorMessage name="name" component="div" className="invalid-feedback" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="education">Email</label>
																<Field type="text" value={authUser?.email} disabled className={`form-control`} />
															</div>
														</div>

														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="phoneNo">Phone Number</label>
																<PhoneInput
																	country="us" // Set your default country
																	inputProps={{
																		name: "phoneNo",
																		className: ` w-100 h-auto form-control ${touched.phoneNo && errors.phoneNo ? "is-invalid" : ""}`,
																	}}
																	value={values.phoneNo}
																	onChange={(phoneNo) => setFieldValue("phoneNo", phoneNo)}
																/>
																<ErrorMessage name="phoneNo" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
															</div>
														</div>
													</div>
												</div>

												<div className="section">
													<h3>Qualifications</h3>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="education">Education</label>
																<Field type="text" name="education" className={`form-control ${touched.education && errors.education ? "is-invalid" : ""}`} />
																<ErrorMessage name="education" component="div" className="invalid-feedback" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="yearsOfExperience">Years of Experience</label>
																<Field type="number" name="yearsOfExperience" className={`form-control ${touched.yearsOfExperience && errors.yearsOfExperience ? "is-invalid" : ""}`} />
																<ErrorMessage name="yearsOfExperience" component="div" className="invalid-feedback" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="certifications">Certifications</label>
																<TagsInput
																	inputProps={{
																		className: "react-tagsinput-input",
																		placeholder: "Add Certification",
																	}}
																	placeholder="Add Certifications"
																	onlyUnique={true}
																	value={values.certifications}
																	onChange={(tags) => setFieldValue("certifications", tags)}
																/>
																<ErrorMessage name="certifications" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
															</div>
														</div>
													</div>
												</div>
												<div className="section">
													<h3>Service Area</h3>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="serviceCoverageState">Service Coverage State</label>

																<Select
																	id="serviceCoverageState"
																	name="serviceCoverageState"
																	options={updatedStates("US")}
																	value={values.serviceCoverageState}
																	onChange={(value) => {
																		setFieldValue("serviceCoverageState", value);
																	}}
																/>
																<ErrorMessage name="serviceCoverageState" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="city">Service Coverage City</label>
																<Select
																	id="city"
																	name="city"
																	options={updatedCities("US", values.serviceCoverageState ? values.serviceCoverageState.value : null)}
																	value={values.city}
																	onChange={(value) => setFieldValue("city", value)}
																/>{" "}
																<ErrorMessage name="city" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="zipCodes">Service Coverage Zip Codes</label>
																<Field type="number" name="zipCodes" className={`form-control ${touched.zipCodes && errors.zipCodes ? "is-invalid" : ""}`} />
																<ErrorMessage name="zipCodes" component="div" className="invalid-feedback" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className={` ${touched.serviceType && errors.serviceType ? "is-invalid" : ""}`}>Service Type</label>
																<div>
																	<div className="form-check form-check-inline">
																		<label className="form-check-label">
																			<Field type="radio" name="serviceType" value="incall" className="form-check-input" />
																			Incall
																		</label>
																	</div>
																	<div className="form-check form-check-inline">
																		<label className="form-check-label">
																			<Field type="radio" name="serviceType" value="outcall" className="form-check-input" />
																			Outcall
																		</label>
																	</div>
																</div>
																<ErrorMessage name="serviceType" component="div" className="invalid-feedback" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="machineExpertise">Types of Machine Expertise</label>
																{data?.eMachine && (
																	<CreatableSelect
																		id="machineExpertise"
																		name="machineExpertise"
																		options={newEMachine}
																		value={newEMachine.filter((option) => values?.machineExpertise.includes(option.value))}
																		isMulti
																		onChange={(selectedOptions) => {
																			setFieldValue("machineExpertise", selectedOptions ? selectedOptions.map((option) => option.value) : []);
																		}}
																		onCreateOption={(newOption) => {
																			// Add logic to handle creating a new option
																			const nextValue =  newEMachine.length + 1;
																			const newValue = { value: nextValue, label: newOption };

																			// Update the options list
																			const updatedOptions = [...data.eMachine, newValue];

																			// Update the state with the updated options list
																			setNewEMachine([...newEMachine, newValue]);
																			setCreateEMachine([...createEMachine, newValue]);

																			setFieldValue("machineExpertise", [...values.machineExpertise, newValue.value]);
																		}}
																	/>
																)}
																<ErrorMessage name="machineExpertise" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
															</div>
														</div>
														<div className="col-md-12">
															<div className="form-group">
																<label htmlFor="experienceDetails">Tell us more about your experience</label>
																<Field as="textarea" name="experienceDetails" className={`form-control ${touched.experienceDetails && errors.experienceDetails ? "is-invalid" : ""}`} />
																<ErrorMessage name="experienceDetails" component="div" className="invalid-feedback" />
															</div>
														</div>
													</div>
												</div>

												{/* Add other form fields similarly */}
												{!profile?.technicianProfile?.name && (
													<button type="submit" className="btn btn-primary" disabled={isSubmitting || savedProfile?.loadingSaveProfile || savedProfile?.savedProfile !== null}>
														Submit <ClipLoader loading={savedProfile?.loadingSaveProfile} size={"10"} />
													</button>
												)}
											</Form>
										)}
									</Formik>
								</div>
								{profile?.technicianProfile?.name && !company?.name && !authUser.profile?.Company?.id && profile?.technicianProfile?.isApproved && (
									<div className="card-footer float-right ">
										<NavLink to="/technician/company" className="btn btn-primary float-right" >
											Setup Company
										</NavLink>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default TechnicianProfile;
