//  users.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's user state on admin panel
import { logout } from "_store/login.slice";
import axios from "axios";
import Swal from "sweetalert2";

// Action Types
const FETCH_USER_PROFILE_REQUEST = "FETCH_USER_PROFILE_REQUEST";
const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";
const RESET_USER_PROFILE = "RESET_USER_PROFILE";

// Action Creators
const fetchUserProfileRequest = () => ({
	type: FETCH_USER_PROFILE_REQUEST,
});

const fetchUserProfileSuccess = (profile) => ({
	type: FETCH_USER_PROFILE_SUCCESS,
	payload: profile,
});

const fetchUserProfileFailure = (error) => ({
	type: FETCH_USER_PROFILE_FAILURE,
	payload: error,
});

export const resetUserProfile = () => ({
	type: RESET_USER_PROFILE,
});

// Thunk Action
export const fetchUserProfile = ({pageSize, searchFilters, sortConfig, currentPage,perPage}) => {
	return async (dispatch) => {
		dispatch(fetchUserProfileRequest());
		const user = JSON.parse(localStorage.getItem("user"));
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/admin/users`, {
				params: {pageSize, sortConfig, currentPage,perPage},
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			});
            // await new Promise((resolve) => setTimeout(resolve, 4000));
			dispatch(fetchUserProfileSuccess(response.data?.response));
		} catch (error) {
			if (error.response && error.response.status === 404) {
				dispatch(fetchUserProfileSuccess(null));
			}

			if (error.response && error.response.status === 401) {
				// If the response status is 401 (Unauthorized), dispatch the logout action
				Swal.fire({
					icon: "error",
					title: "You have been logged out! Please login again",
					timer: 3000,
				});
				dispatch(logout());
			} else {
				dispatch(fetchUserProfileFailure(error.message));
			}
		}
	};
};

// Initial State
const initialState = {
	user: null,
	loadingUser: false,
	error: null,
};

// Reducer
const userProfileReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_USER_PROFILE_REQUEST:
			return {
				...state,
				loadingUser: true,
				error: null,
			};
		case RESET_USER_PROFILE:
			return {
				user: null,
				loadingUser: false,
				error: null,
			};
		case FETCH_USER_PROFILE_SUCCESS:
			return {
				...state,
				loadingUser: false,
				user: action.payload,
			};
		case FETCH_USER_PROFILE_FAILURE:
			return {
				...state,
				loadingUser: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default userProfileReducer;
