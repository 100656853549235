//  adminEmachineRequest.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to manage  admin E-Machine Request Listing

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { history } from "_helpers";
import PageHeader from "../../../partials/PageHeader";
import Pagination from "../../../partials/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {fetchAdminEmachine, resetAdminEmachineRequest} from "../../../_store/admin/adminEmachine.slice";
import {approveEMachine} from "../../../_store/admin/approveEmachine.slice";
import { ClipLoader } from "react-spinners";
import { faSortUp, faSortDown, faSort, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
const AdminEmachineRequest =({fetchAdminEmachine, resetAdminEmachineRequest,adminEmachine, isLoadingData,approveEMachine}) =>{
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState({});
    const [sortConfig, setSortConfig] = React.useState({
        column: "id",
        order: "desc",
    });
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState();
    const [searchFilters, setSearchFilters] = React.useState({
        id: "",
        fullName: "",
        email: "",
        role: "",
    });
    const pageSize = 10;
    const onlyCustomerRequest=true;
    const handleSortChange = (columnName) => {
        if (sortConfig.column === columnName) {
            // Toggle the sorting order if the same column is clicked again
            setSortConfig({
                ...sortConfig,
                order: sortConfig.order === "asc" ? "desc" : "asc",
            });
        } else {
            // If a new column is clicked, set it as the sorting column and reset the order to 'asc'
            setSortConfig({ column: columnName, order: "asc" });
        }
    };
// // Define columns for the DataTable
    const columns = [
        {
            name: "#",
            selector: "id",
            sort: true,
        },
        {
            name: "E-Machine",
            selector: "deviceName",
            sort: true,
        },
        // {
        //     name: "Problem Description",
        //     selector: "description",
        //     sort: true,
        // },
        {
            name: "Status",
            selector: "isApproved",
            sort: true,
        },
        {
            name: "Action",
            selector: "action",
            sort: false,
        },

    ];
    // Define dummy data
    const result = [
        {
            "emachine": "Electric Bike Model X",
            "description": "Battery replacement required",
            "assignee": "John Doe",
            "status": "In Progress"
        },
        {
            "emachine": "Electric Scooter Model Y",
            "description": "Brake repair needed",
            "assignee": "Jane Smith",
            "status": "Completed"
        },
        {
            "emachine": "E-Bike Model Z",
            "description": "Motor not working",
            "assignee": "Michael Johnson",
            "status": "Pending"
        },
        {
            "emachine": "Electric Skateboard",
            "description": "Wheels need replacement",
            "assignee": "Emily Brown",
            "status": "In Progress"
        },
        {
            "emachine": "Electric Unicycle",
            "description": "Charger malfunctioning",
            "assignee": "David Wilson",
            "status": "Pending"
        },
        {
            "emachine": "Electric Hoverboard",
            "description": "Balance sensor issue",
            "assignee": "Sarah Martinez",
            "status": "Completed"
        },
        {
            "emachine": "E-Scooter",
            "description": "Handlebar repair needed",
            "assignee": "Chris Thompson",
            "status": "In Progress"
        }
    ]

    const handlePageChange = async (page) => {
        setCurrentPage(page);
        await dispatch(fetchAdminEmachine({ pageSize, searchFilters, sortConfig, currentPage, perPage, onlyCustomerRequest}));
    };

    // Handle rows per page change
    const handlePerPageChange = (newPerPage, page) => {
        setPage(1);
        setPerPage(newPerPage);
    };

    // Handle sorting change
    const handleSort = (column, direction) => {
        setSortBy({ field: column.selector, direction });
    };
    useEffect(() => {
        // console.log();
        fetchAdminEmachine({ pageSize, searchFilters, sortConfig, currentPage, perPage, onlyCustomerRequest });
        return () => {
            // Reset user service request state when component unmounts
            resetAdminEmachineRequest();
        };
    }, [fetchAdminEmachine, pageSize, searchFilters, sortConfig, currentPage, perPage, onlyCustomerRequest]);

    useEffect(() => {
        // console.log("total machines =>",adminEmachine?.emachine?.machines);
        setTotalPages(Math.ceil(adminEmachine?.emachine?.total / perPage));
    }, [adminEmachine]);

    // const markAsComplete = (e,data)=>{
    //     e.preventDefault();
    //     // console.log('here',data);
    //     let req ={
    //         "requestId": parseInt(data.id)
    //     }
    //     // You can dispatch an action to update service state data
    //     updateServiceStatus({"requestId": parseInt(data.id),pageSize, searchFilters, sortConfig, currentPage, perPage});
    //
    // }
    // const EditServiceRequest = (e,data)=>{
    //     e.preventDefault();
    //     // console.log('here',data);
    //     history.navigate(`/customer/service-request-list/${data.id}`)
    //
    //
    // }
    const approveRequest=(e, data)=>{
        e.preventDefault();
        approveEMachine({"eMachineId": parseInt(data.id),pageSize, searchFilters, sortConfig, currentPage, perPage});

    }
    const EditMachineRequest = (e, data) => { // Function to handle edit service request
        e.preventDefault(); // Prevent default behavior of event
        history.navigate(`/dashboard/e-machine-request/${data.id}`) // Navigate to edit service request page with specific data id
    }
    return (
        <>
            <PageHeader title={"E-Machine Requests"} subtitle={"Control"} />

            {/* Main content wrapper */}
            <div className="content-wrapper">
                {/* Content header */}

                {/* Users list */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card custom-card card-with-button">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <div className="card-title">E-Machine Requests</div>

                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive rounded card-table">
                                            <div className="row" style={{ marginBottom: "10px" }}>
                                                <div className="col-sm-12 col-md-6 ">
                                                    <div className="dataTables_length" id="datatable-basic_length">
                                                        <label className="d-inline-flex align-items-center">
                                                            Show{" "}
                                                            <select name="datatable-basic_length" aria-controls="datatable-basic" className="form-select form-select-sm ms-2 mr-2	" onChange={(e) => setPerPage(e.target.value)}>
                                                                <option value={10}>10</option>
                                                                <option value={25}>25</option>
                                                                <option value={50}>50</option>
                                                                <option value={100}>100</option>
                                                            </select>
                                                            <span className="ml-2">entries</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <div className="col-sm-12 col-md-6">
												<div id="datatable-basic_filter" className="dataTables_filter">
													<label>
														<input type="search" className="form-control form-control-sm" placeholder="Search..." aria-controls="datatable-basic" />
													</label>
												</div>
											</div> */}
                                            </div>
                                            {isLoadingData ? (
                                                <div className="text-center">
                                                    <ClipLoader  size={50} loading={true} />
                                                </div>
                                            ) : (
                                                <>
                                                    <table className="table table-bordered text-nowrap dataTable no-footer" id="example1">
                                                        <thead>
                                                        <tr>
                                                            {columns.map((column, index) => (
                                                                <th key={index} onClick={() => (column.sort ? handleSortChange(column.selector) : null)} style={{ cursor: column.sort ? "pointer" : "default" }}>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                        <span>{column.name}</span>
                                                                        {column.sort && (
                                                                            <FontAwesomeIcon
                                                                                icon={sortConfig.column === column.selector ? (sortConfig.order === "asc" ? faSortUp : faSortDown) : faSort}
                                                                                style={{ fontSize: "0.8em", marginLeft: "5px" }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </th>
                                                            ))}
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {adminEmachine?.emachine?.machines &&
                                                        adminEmachine?.emachine?.machines.length !== 0 &&
                                                        adminEmachine?.emachine?.machines.map((machine, i) => (
                                                            <tr className="hover-primary" key={i}>
                                                                <td>{machine?.id}</td>
                                                                <td>{machine?.deviceName}</td>
                                                                <td>{machine?.isApproved === true ? <span className="badge bg-success"> Approved </span> : <span className="badge bg-warning"> Not Approved </span>}</td>
                                                                <td>
                                                                    {machine?.isApproved === false ? (
                                                                        <>
                                                                            <button className="btn btn-success" onClick={(e) => approveRequest(e, machine)}>
                                                                                <i className="bi bi-check-lg" title="Approve Request" style={{ fontSize: '12px', cursor: "pointer" }} />
                                                                            </button>
                                                                            &nbsp;
                                                                            <button className="btn btn-info" onClick={(e) => EditMachineRequest(e, machine)}>
                                                                                <i className="bi bi-pencil-square" title="Edit Service Request" style={{ fontSize: '12px', cursor: "pointer" }} />
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <button className="btn btn-info" onClick={(e) => EditMachineRequest(e, machine)}>
                                                                            <i className="bi bi-pencil-square" title="Edit Service Request" style={{ fontSize: '12px', cursor: "pointer" }} />
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}

                                                        </tbody>
                                                    </table>
                                                    <div>
                                                        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({
    adminEmachine: state.adminEmachine, // Assuming the serviceRequest data is stored in state.serviceRequest
    isLoadingData:state.adminEmachine?.loadingAdminEmachine,
});

const mapDispatchToProps = {
    fetchAdminEmachine,
    resetAdminEmachineRequest,
    approveEMachine,

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmachineRequest);