//  register.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's register state.
import axios from "axios";

// Action Types
export const REGISTRATION_REQUEST = "REGISTRATION_REQUEST";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAILURE = "REGISTRATION_FAILURE";
export const REGISTRATION_RESET = "REGISTRATION_RESET";

// Action Creators

/**
 * Action creator for registration request
 * @returns {object} Action object
 */
export const registrationRequest = () => ({
	type: REGISTRATION_REQUEST,
});

/**
 * Action creator for registration success
 * @param {string} variant - The variant of the alert
 * @param {string} message - The message to display in the alert
 * @returns {object} Action object
 */
export const registrationSuccess = (variant, message) => ({
	type: REGISTRATION_SUCCESS,
	payload: { variant, message },
});

/**
 * Action creator for registration failure
 * @param {string} error - The error message
 * @returns {object} Action object
 */
export const registrationFailure = (error) => ({
	type: REGISTRATION_FAILURE,
	payload: error,
});

/**
 * Action creator for resetting registration state
 * @returns {object} Action object
 */
export const registrationReset = () => ({
	type: REGISTRATION_RESET,
});

// Thunk Action Creator for user registration

/**
 * Thunk action creator for registering a user
 * @param {object} values - The user registration data
 * @returns {function} Thunk function
 */
export const registerUser = (values) => {
	return async (dispatch) => {
		dispatch(registrationReset());
		dispatch(registrationRequest());
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/public/register`, values);

			if (response.status === 201) {
				dispatch(registrationSuccess("alert-success", response.data.message));
			} else if (response.status === 200) {
				dispatch(registrationSuccess("alert-danger", response.data.message));
			} else {
				throw new Error("Email is already registered");
			}
		} catch (error) {
			dispatch(registrationFailure(error.response ? error.response.data.message : "Failed to register"));
		}
	};
};

// Initial State
const initialState = {
	isLoading: false,
	alertVariant: "",
	alertMessage: "",
};

// Reducer

/**
 * Reducer for handling registration state
 * @param {object} state - Current state
 * @param {object} action - Action object
 * @returns {object} New state
 */
const registrationReducer = (state = initialState, action) => {
	switch (action.type) {
		case REGISTRATION_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case REGISTRATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				alertVariant: action.payload.variant,
				alertMessage: action.payload.message,
			};
		case REGISTRATION_FAILURE:
			return {
				...state,
				isLoading: false,
				alertVariant: "alert-danger",
				alertMessage: action.payload,
			};
		case REGISTRATION_RESET:
			return {
				isLoading: false,
				alertVariant: "",
				alertMessage: "",
			};
		default:
			return state;
	}
};

export default registrationReducer;
