//  EmachineManagementTheme.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to Manage  E-Machine Listing
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { fetchUserProfile, resetUserProfile } from "_store/admin/users.slice";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import { approveProfile } from "_store/admin/approval.slice";
import { approveCompanyProfile } from "_store/admin/approvalCompany.slice";
import { history } from "_helpers";

import { fetchMachines } from "_store/tehnician/machine.slice";
import PageHeader from "partials/PageHeader";
import Pagination from "partials/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown, faSort, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";

const EMachineManagementTheme = ({ fetchMachines, resetUserProfile, approveProfile, approveCompanyProfile, machines, total,isLoadingData }) => {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [sortBy, setSortBy] = useState({});
	const [sortConfig, setSortConfig] = React.useState({
		column: "id",
		order: "desc",
	});
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = React.useState(1);
	const [totalPages, setTotalPages] = React.useState(0);
	const [searchFilters, setSearchFilters] = React.useState({
		id: "",
		fullName: "",
		email: "",
		role: "",
	});
	const pageSize = 10;

	const handleSortChange = (columnName) => {
		if (sortConfig.column === columnName) {
			// Toggle the sorting order if the same column is clicked again
			setSortConfig({
				...sortConfig,
				order: sortConfig.order === "asc" ? "desc" : "asc",
			});
		} else {
			// If a new column is clicked, set it as the sorting column and reset the order to 'asc'
			setSortConfig({ column: columnName, order: "asc" });
		}
	};

	useEffect(() => {
		fetchMachines({ pageSize, searchFilters, sortConfig, currentPage, perPage });
		return () => {
			// Reset user profile state when component unmounts
			resetUserProfile();
		};
	}, [fetchMachines, pageSize, searchFilters, sortConfig, currentPage, perPage]);

	useEffect(() => {
		console.log({ machines });
	}, [machines]);
	// Sample JSON data for user list
	const data = machines || [];

	// // Define columns for the DataTable
	const columns = [
		{
			name: "#",
			selector: "id",
			sort: true,
		},
		{
			name: "Device Name",
			selector: "deviceName",
			sort: true,
		},
		{
			name: "Serial Number",
			selector: "serialNumber",
			sort: true,
		},
		{
			name: "Model",
			selector: "model",
			sort: true,
		},
		{
			name: "Manufacturer",
			selector: "manufacturer",
			sort: true,
		},
		{
			name: "Purchase Date",
			selector: "purchaseDate",
			sort: true,
		},
		{
			name: "Warranty Expiry Date",
			selector: "warrantyExpiryDate",
			sort: true,
		},
		{
			name: "Description",
			selector: "description",
			sort: true,
		},
		{
			name: "MAC Address",
			selector: "macAddress",
			sort: true,
		},
		{
			name: "Location",
			selector: "location",
			sort: true,
		},
		{
			name: "Operating System",
			selector: "operatingSystem",
			sort: true,
		},
		{
			name: "Approved",
			selector: "isApproved",
			sort: true,
		},
		// {
		// 	name: "Action",
		// 	selector: "action",
		// 	sort: true,
		// },
	];

	// Define action to handle blocking user
	const handleApprovedProfile = (user) => {
		// Implement logic to block user
		// approveProfile(user, page);
	};
	const handleApprovedCompany = (user) => {
		// Implement logic to block user
		// approveCompanyProfile(user, page);
	};
	const markAsComplete = (e)=>{
		e.preventDefault();
		console.log('here',e);
	}
	// Handle pagination change
	const handlePageChange = async (page) => {
		setCurrentPage(page);
		await dispatch(fetchMachines({ pageSize, searchFilters, sortConfig, currentPage, perPage }));
	};

	// Handle rows per page change
	const handlePerPageChange = (newPerPage, page) => {
		setPage(1);
		setPerPage(newPerPage);
	};

	// Handle sorting change
	const handleSort = (column, direction) => {
		setSortBy({ field: column.selector, direction });
	};
	useEffect(() => {
		console.log("total", machines);
		setTotalPages(Math.ceil(total / perPage));
	}, [machines, total]);

	const approveRequest=(e)=>{
		e.preventDefault();

	}
	return (
		<>
			<PageHeader title={"E Machine Management"} subtitle={"Control"} />

			{/* Main content wrapper */}
			<div className="content-wrapper">
				{/* Content header */}

				{/* Users list */}
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="card custom-card card-with-button">
									<div class="card-header d-flex justify-content-between align-items-center">
										<div class="card-title">E Machines</div>
										<button class="btn btn-primary" onClick={() => history.navigate("/dashboard/e-machine/register")}>
											Add <i class="fas fa-plus-circle ml-1"></i>
										</button>

									</div>
									<div className="card-body">
										<div className="table-responsive rounded card-table">
											<div className="row" style={{ marginBottom: "10px" }}>
												<div className="col-sm-12 col-md-6 ">
													<div className="dataTables_length" id="datatable-basic_length">
														<label className="d-inline-flex align-items-center">
															Show{" "}
															<select name="datatable-basic_length" aria-controls="datatable-basic" className="form-select form-select-sm ms-2 mr-2	" onChange={(e) => setPerPage(e.target.value)}>
																<option value={10}>10</option>
																<option value={25}>25</option>
																<option value={50}>50</option>
																<option value={100}>100</option>
															</select>
															<span className="ml-2">entries</span>
														</label>
													</div>
												</div>
												{/* <div className="col-sm-12 col-md-6">
												<div id="datatable-basic_filter" className="dataTables_filter">
													<label>
														<input type="search" className="form-control form-control-sm" placeholder="Search..." aria-controls="datatable-basic" />
													</label>
												</div>
											</div> */}
											</div>
											{isLoadingData ? (
												<div className="text-center">
													<ClipLoader  size={50} loading={true} />
												</div>
											) : (
                                              <>
											<table className="table table-bordered text-nowrap dataTable no-footer" id="example1">
												<thead>
													<tr>
														{columns.map((column, index) => (
															<th key={index} onClick={() => (column.sort ? handleSortChange(column.selector) : null)} style={{ cursor: column.sort ? "pointer" : "default" }}>
																<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
																	<span>{column.name}</span>
																	{column.sort && (
																		<FontAwesomeIcon
																			icon={sortConfig.column === column.selector ? (sortConfig.order === "asc" ? faSortUp : faSortDown) : faSort}
																			style={{ fontSize: "0.8em", marginLeft: "5px" }}
																		/>
																	)}
																</div>
															</th>
														))}
													</tr>
												</thead>
												<tbody>
													{data &&
														data.length !== 0 &&
														data.map((machine, i) => (
															<tr className="hover-primary" key={i}>
																<td>{machine.id}</td>
																<td>{machine.deviceName}</td>
																<td>{machine.serialNumber}</td>
																<td>{machine.model}</td>
																<td>{machine.manufacturer}</td>
																<td>{new Date(machine.purchaseDate).toLocaleString()}</td>
																{/*<td>{machine.purchaseDate}</td>*/}
																<td>{new Date(machine.warrantyExpiryDate).toLocaleString()}</td>
																{/*<td>{machine.warrantyExpiryDate}</td>*/}
																<td>{machine.description}</td>
																<td>{machine.macAddress}</td>
																<td>{machine.location}</td>
																<td>{machine.operatingSystem}</td>
																<td>{machine.isApproved === true? "Yes" : "No"}</td>
{/*																<td>*/}
{/*																	{machine?.isApproved?.name === false ?*/}

{/*<>*/}
{/*																	<button className="btn btn-success" onClick={(e) => { approveRequest(e,machine)}}> <i className="bi bi-check-lg"  title="Mark As Complete" style={{fontSize:'12px',cursor:"pointer"}}  /></button>*/}
{/*																	</>*/}
{/*																	:*/}
{/*																	<></>*/}

{/*																	}																</td>*/}
															</tr>
														))}
												</tbody>
											</table>
											<div>
												<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
											</div>
											</>
												)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	machines: state.machines?.machines?.machines, // Assuming the user data is stored in state.admin.user
	isLoadingData: state.machines?.loadingMachines, // Assuming the loader is stored in state.machines.loadingMachines
	total: state.machines?.machines?.total, // Assuming the user data is stored in state.admin.user
});

const mapDispatchToProps = {
	fetchMachines,
	resetUserProfile,
	approveProfile,
	approveCompanyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(EMachineManagementTheme);
