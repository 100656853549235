//  SidebarOffCanvas.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file displays navigation links and user information based on user authentication.
import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { logout } from "_store/login.slice";
import { setActiveProfile } from "_store/users.slice";
const SideBarOffCanvas = () => {
	const dispatch = useDispatch();
	const activeProfile = useSelector((state) => state.users.profileActive);
	const { company } = useSelector((state) => state.company.profile);
	const logoutUser = () => dispatch(logout());
	const { user: authUser } = useSelector((state) => state.auth);

	return (
		<div className="offcanvas offcanvas-end offcanvas-sidebar overflow-auto" tabIndex={-1} id="offcanvassidebar">
			{/* Sidebar-right */}
			<div className="card custom-card tab-heading shadow-none">
				<div className="card-header rounded-0">
					<div className="card-title">Notifications</div>
					<div className="card-options ms-auto" data-bs-theme="dark">
						<button type="button" className="btn-close text-white" data-bs-dismiss="offcanvas" aria-label="Close" />
					</div>
				</div>
				<div className="card-body p-0">
					<ul className="nav nav-tabs my-3 nav-style-1 justify-content-center" role="tablist">
						<li className="nav-item">
							<a className="nav-link active" data-bs-toggle="tab" role="tab" href="#home31" aria-selected="true">
								{" "}
								<i className="fe fe-user" />
								Profile
							</a>
						</li>
						{/* <li className="nav-item">
							<a className="nav-link" data-bs-toggle="tab" role="tab" href="#about32" aria-selected="false">
								<i className="fe fe-users " /> Contacts
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" data-bs-toggle="tab" role="tab" href="#service33" aria-selected="false">
								<i className="fe fe-settings" />
								Settings
							</a>
						</li> */}
					</ul>
					<div className="tab-content">
						<div className="tab-pane show active text-muted p-0 rounded-0 border-bottom-0 border-end-0" id="home31" role="tabpanel">
							<div className="card-body text-center">
								<div className="dropdown user-pro-body">
									<div className="">
										<img alt="user-img" className="avatar avatar-xl rounded-circle mx-auto text-center" src="../images/faces/6.jpg" />
										<span className="avatar-status profile-status bg-green" />
									</div>
									<div className="user-info mg-t-20">
										<h6 className="fw-semibold  mt-2 mb-0">{authUser?.fullName}</h6>
										<span className="mb-0 text-muted fs-12">{authUser?.roleId === "3" ? "TECHNICIAN" : "CUSTOMER"}</span>
									</div>
								</div>
							</div>
							
							<div className="dropdown-item d-flex border-bottom"  style={{cursor:'pointer'}} onClick={() => logoutUser()}>
								<div className="d-flex">
									<i className="fe fe-power me-3 fs-20 text-muted" />
									<div className="pt-1">
										<h6 className="mb-0">Sign Out</h6>
										<p className="fs-12 mb-0 text-muted" >
											Account Signout
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane text-muted p-0 rounded-0 overflow-auto border-end-0" id="about32" role="tabpanel">
							<div className="list-group list-group-flush ">
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/9.jpg" />
										<span className="avatar-status bg-success" />
									</div>
									<div className="">
										<div className="fw-semibold">Mozelle Belt</div>
										<p className="mb-0 tx-12 text-muted">mozellebelt@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/11.jpg" />
									</div>
									<div className="">
										<div className="fw-semibold">Florinda Carasco</div>
										<p className="mb-0 tx-12 text-muted">florindacarasco@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/10.jpg" />
										<span className="avatar-status bg-success" />
									</div>
									<div className="">
										<div className="fw-semibold">Alina Bernier</div>
										<p className="mb-0 tx-12 text-muted">alinaaernier@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/2.jpg" />
										<span className="avatar-status bg-success" />
									</div>
									<div className="">
										<div className="fw-semibold">Zula Mclaughin</div>
										<p className="mb-0 tx-12 text-muted">zulamclaughin@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/13.jpg" />
									</div>
									<div className="">
										<div className="fw-semibold">Isidro Heide</div>
										<p className="mb-0 tx-12 text-muted">isidroheide@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/12.jpg" />
										<span className="avatar-status bg-success" />
									</div>
									<div className="">
										<div className="fw-semibold">Mozelle Belt</div>
										<p className="mb-0 tx-12 text-muted">mozellebelt@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/4.jpg" />
									</div>
									<div className="">
										<div className="fw-semibold">Florinda Carasco</div>
										<p className="mb-0 tx-12 text-muted">florindacarasco@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/2.jpg" />
										<span className="avatar-status bg-success" />
									</div>
									<div className="">
										<div className="fw-semibold">Zula Mclaughin</div>
										<p className="mb-0 tx-12 text-muted">zulamclaughin@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/7.jpg" />
									</div>
									<div className="">
										<div className="fw-semibold">Alina Bernier</div>
										<p className="mb-0 tx-12 text-muted">alinabernier@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/2.jpg" />
									</div>
									<div className="">
										<div className="fw-semibold">Zula Mclaughin</div>
										<p className="mb-0 tx-12 text-muted">zulamclaughin@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/14.jpg" />
										<span className="avatar-status bg-success" />
									</div>
									<div className="">
										<div className="fw-semibold">Isidro Heide</div>
										<p className="mb-0 tx-12 text-muted">isidroheide@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/11.jpg" />
									</div>
									<div className="">
										<div className="fw-semibold">Florinda Carasco</div>
										<p className="mb-0 tx-12 text-muted">florindacarasco@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/9.jpg" />
									</div>
									<div className="">
										<div className="fw-semibold">Alina Bernier</div>
										<p className="mb-0 tx-12 text-muted">alinabernier@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/15.jpg" />
										<span className="avatar-status bg-success" />
									</div>
									<div className="">
										<div className="fw-semibold">Zula Mclaughin</div>
										<p className="mb-0 tx-12 text-muted">zulamclaughin@gmail.com</p>
									</div>
								</div>
								<div className="list-group-item d-flex  align-items-center">
									<div className="me-2">
										<img className="avatar avatar-md rounded-circle cover-image" alt="img" src="../assets/images/faces/4.jpg" />
									</div>
									<div className="">
										<div className="fw-semibold">Isidro Heide</div>
										<p className="mb-0 tx-12 text-muted">isidroheide@gmail.com</p>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane text-muted p-0 rounded-0 border-end-0" id="service33" role="tabpanel">
							<a className="dropdown-item bg-white pd-y-10" href="javascript:void(0);">
								Account Settings
							</a>
							<div className="card-body py-2">
								<div className="form-check form-switch form-check-md my-2 mt-0">
									<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked1" defaultChecked="" />
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked1">
										Updates Automatically
									</label>
								</div>
								<div className="form-check form-switch form-check-md my-2">
									<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked2" />
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked2">
										Allow Location Map
									</label>
								</div>
								<div className="form-check form-switch form-check-md my-2">
									<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked3" defaultChecked="" />
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked3">
										Show Contacts
									</label>
								</div>
								<div className="form-check form-switch form-check-md my-2">
									<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked4" />
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked4">
										Show Notification
									</label>
								</div>
								<div className="form-check form-switch form-check-md my-2">
									<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked5" defaultChecked="" />
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked5">
										Show Tasks Statistics
									</label>
								</div>
								<div className="form-check form-switch form-check-md my-2">
									<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked6" defaultChecked="" />
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked6">
										Show Email Notification
									</label>
								</div>
							</div>
							<a className="dropdown-item bg-white pd-y-10" href="javascript:void(0);">
								General Settings
							</a>
							<div className="card-body py-2">
								<div className="form-check form-switch form-check-md my-2 mt-0">
									<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked7" defaultChecked="" />
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked7">
										Show User Online
									</label>
								</div>
								<div className="form-check form-switch form-check-md my-2">
									<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked8" />
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked8">
										Website Notification
									</label>
								</div>
								<div className="form-check form-switch form-check-md my-2">
									<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked9" />
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked9">
										Show Recent activity
									</label>
								</div>
								<div className="form-check form-switch form-check-md my-2">
									<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked10" defaultChecked="" />
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked10">
										Logout Automatically
									</label>
								</div>
								<div className="form-check form-switch form-check-md my-2">
									<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked12" defaultChecked="" />
									<label className="form-check-label" htmlFor="flexSwitchCheckChecked12">
										Allow All Notifications
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*/Sidebar-right*/}
		</div>
	);
};

export default SideBarOffCanvas;
