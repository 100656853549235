//  serviceRequestListing.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to manage  service request listing on admin side.
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { history } from "_helpers";
import PageHeader from "../../../partials/PageHeader";
import Pagination from "../../../partials/Pagination";
import {fetchAdminServices, resetAdminServiceRequest } from'../../../_store/admin/adminService.slice'
import {assignTechnician,resetTechnicianRequest} from "../../../_store/admin/assignTechnician.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { faSortUp, faSortDown, faSort, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {ClipLoader} from "react-spinners";
import * as Yup from "yup";
import Swal from "sweetalert2";
const ServiceRequestListing =({ fetchAdminServices,resetAdminServiceRequest,assignTechnician, resetTechnicianRequest,adminService,isLoadingData,assignTech: { loadingTechnicianRequest } }) =>{
    const [page, setPage] = useState(1);
    const [requestId, setRequesId] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState({});
    const [sortConfig, setSortConfig] = React.useState({
        column: "id",
        order: "desc",
    });
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [searchFilters, setSearchFilters] = React.useState({
        id: "",
        fullName: "",
        email: "",
        role: "",
    });
    const pageSize = 10;
    const handleSortChange = (columnName) => {
        if (sortConfig.column === columnName) {
            // Toggle the sorting order if the same column is clicked again
            setSortConfig({
                ...sortConfig,
                order: sortConfig.order === "asc" ? "desc" : "asc",
            });
        } else {
            // If a new column is clicked, set it as the sorting column and reset the order to 'asc'
            setSortConfig({ column: columnName, order: "asc" });
        }
    };
    // // Define dummy technicians
    const technician = [
        { label: "Chris Thompson", value: 1},
        { label: "John Doe", value: 1},
        { label: "Jane Smith", value: 1 },
        { label: "Emily Brown", value: 1 },
        { label: "David Wilson", value: 1 },
        { label: "Sarah Martinez", value: 1 },
        { label: "Ella Hernandez", value: 1 },
        { label: "William Lewis", value: 1 },
        { label: "Grace Ward", value:1 },
        { label: "Luke Foster", value: 1 },
        { label: "Chloe Cooper", value: 1 },

    ];
    // // Define dummy data
    const result = [
        {
            "emachine": "Electric Bike Model X",
            "description": "Battery replacement required",
            "assignee": "John Doe",
            "status": "In Progress"
        },
        {
            "emachine": "Electric Scooter Model Y",
            "description": "Brake repair needed",
            "assignee": "Jane Smith",
            "status": "Completed"
        },
        {
            "emachine": "E-Bike Model Z",
            "description": "Motor not working",
            "assignee": "Not Assigned",
            "status": "Pending"
        },
        {
            "emachine": "Electric Skateboard",
            "description": "Wheels need replacement",
            "assignee": "Emily Brown",
            "status": "In Progress"
        },
        {
            "emachine": "Electric Unicycle",
            "description": "Charger malfunctioning",
            "assignee": "David Wilson",
            "status": "Pending"
        },
        {
            "emachine": "Electric Hoverboard",
            "description": "Balance sensor issue",
            "assignee": "Sarah Martinez",
            "status": "Completed"
        },
        {
            "emachine": "E-Scooter",
            "description": "Handlebar repair needed",
            "assignee": "Chris Thompson",
            "status": "In Progress"
        }
    ]
// // Define columns for the DataTable
    const columns = [
        {
            name: "#",
            selector: "id",
            sort: true,
        },
        {
            name: "E-Machine",
            selector: "eMachineId",
            sort: true,
        },
        {
            name: "Problem Description",
            selector: "description",
            sort: true,
        },
        {
            name: "Assignee",
            selector: "technicianId",
            sort: true,
        },
        {
            name: "Status",
            selector: "statusId",
            sort: true,
        },
        {
            name: "Action",
            selector: "action",
            sort: false,
        },

    ];
    // Handle pagination change
    const handlePageChange = async (page) => {
        setCurrentPage(page);
        await dispatch(fetchAdminServices({ pageSize, searchFilters, sortConfig, currentPage, perPage }));
    };

    // Handle rows per page change
    const handlePerPageChange = (newPerPage, page) => {
        setPage(1);
        setPerPage(newPerPage);
    };

    // Handle sorting change
    const handleSort = (column, direction) => {
        setSortBy({ field: column.selector, direction });
    };
    useEffect(() => {
        fetchAdminServices({ pageSize, searchFilters, sortConfig, currentPage, perPage });
        return () => {
            // Reset user admin service request state when component unmounts
            resetAdminServiceRequest();
        };
    }, [fetchAdminServices, pageSize, searchFilters, sortConfig, currentPage, perPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(adminService?.adminServiceRequest?.totalItems / perPage));
    }, [adminService]);
    // Using map() to transform the array into the desired format
    const formattedDevices = adminService?.adminServiceRequest?.technicians.map(device => ({
        label: device.name,
        value: device.id
    }));

    const initialValues = {
        technicianId: "",

    };

    const validationSchema = Yup.object().shape({
        technicianId: Yup.object().required("Technician selection is required"),

    });
    const handleSubmit = (values) => {
        // Your submission logic goes here
        // You can dispatch an action to assign technician data
        assignTechnician({ ...values,requestId:parseInt(requestId), technicianId: values.technicianId.value ,pageSize, searchFilters, sortConfig, currentPage, perPage});

    };
    return (
        <>
            <PageHeader title={"Service Requests"} subtitle={"Control"} />

            {/* Main content wrapper */}
            <div className="content-wrapper">
                {/* Content header */}

                {/* Users list */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card custom-card card-with-button">
                                    <div class="card-header d-flex justify-content-between align-items-center">
                                        <div class="card-title">Service Request</div>

                                    </div>
                                    <div className="card-body">


                                        <div className="table-responsive rounded card-table">
                                            <div className="row" style={{ marginBottom: "10px" }}>
                                                <div className="col-sm-12 col-md-6 ">
                                                    <div className="dataTables_length" id="datatable-basic_length">
                                                        <label className="d-inline-flex align-items-center">
                                                            Show{" "}
                                                            <select name="datatable-basic_length" aria-controls="datatable-basic" className="form-select form-select-sm ms-2 mr-2	" onChange={(e) => setPerPage(e.target.value)}>
                                                                <option value={10}>10</option>
                                                                <option value={25}>25</option>
                                                                <option value={50}>50</option>
                                                                <option value={100}>100</option>
                                                            </select>
                                                            <span className="ml-2">entries</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <div className="col-sm-12 col-md-6">
												<div id="datatable-basic_filter" className="dataTables_filter">
													<label>
														<input type="search" className="form-control form-control-sm" placeholder="Search..." aria-controls="datatable-basic" />
													</label>
												</div>
											</div> */}
                                            </div>
                                            {isLoadingData ? (
                                                <div className="text-center">
                                                    <ClipLoader  size={50} loading={true} />
                                                </div>
                                            ) : (
                                             <>
                                            <table className="table table-bordered text-nowrap dataTable no-footer" id="example1">
                                                <thead>
                                                <tr>
                                                    {columns.map((column, index) => (
                                                        <th key={index} onClick={() => (column.sort ? handleSortChange(column.selector) : null)} style={{ cursor: column.sort ? "pointer" : "default" }}>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <span>{column.name}</span>
                                                                {column.sort && (
                                                                    <FontAwesomeIcon
                                                                        icon={sortConfig.column === column.selector ? (sortConfig.order === "asc" ? faSortUp : faSortDown) : faSort}
                                                                        style={{ fontSize: "0.8em", marginLeft: "5px" }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </th>
                                                    ))}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {adminService?.adminServiceRequest?.serviceRequests &&
                                                adminService?.adminServiceRequest?.serviceRequests.length !== 0 &&
                                                adminService?.adminServiceRequest?.serviceRequests.map((machine, i) => (
                                                    <tr className="hover-primary" key={i}>
                                                <td>{machine.id}</td>
                                                <td>{machine.EMachine?.deviceName}</td>
                                                <td>{machine.description}</td>
                                                <td>{machine.Technician?.name}</td>
                                                <td>
                                                    {machine.ServiceRequestStatus.name === "Pending" ? <span className="badge bg-warning"> {machine.ServiceRequestStatus.name} </span>:''}
                                                    {machine.ServiceRequestStatus.name === "In Progress" ? <span className="badge bg-secondary"> {machine.ServiceRequestStatus.name} </span>:''}
                                                    {machine.ServiceRequestStatus.name === "Completed" ? <span className="badge bg-success"> {machine.ServiceRequestStatus.name} </span>:''}
                                                </td>
                                                        <td>
                                                            {machine.ServiceRequestStatus.name === "Completed"  ? (
                                                                <>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button className="btn btn-primary"
                                                                    onClick={(e)=>{setRequesId(machine.id)}}

                                                                    >  <i
                                                                        className="fe fe-user"
                                                                        title="Assign To"
                                                                        style={{ fontSize: '20px', cursor: "pointer" }}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exampleModal"
                                                                    />
                                                                    </button>

                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}


                                                </tbody>
                                            </table>



                                            <div className="modal fade" id="exampleModal" tabindex="-1"
                                                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div class="modal-header">

                                                            <button type="button" className="btn-close"
                                                                    data-bs-dismiss="modal"
                                                                    aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="row">
                                                                <div className="col-lg-12">

                                                                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                                                        {({ isSubmitting, errors, touched, values, setFieldValue, setFieldTouched }) => (
                                                                            <Form>

                                                                                <div className="row">
                                                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                        <div className="card custom-card edit-password-section">
                                                                                            <div className="card-header">
                                                                                                <div className="card-title">Assign Technician</div>
                                                                                            </div>
                                                                                            <div className="card-body">
                                                                                                <div className="row">
                                                                                                    <div className="col-lg-12">
                                                                                                        <div className="form-group mb-3">
                                                                                                            <label className="form-label" htmlFor="technicianId">Technician</label>
                                                                                                            <Select
                                                                                                                className={`${touched.technicianId && errors.technicianId ? "is-invalid" : ""}`}
                                                                                                                placeholder="Select Technician"
                                                                                                                name="technicianId"
                                                                                                                options={formattedDevices}
                                                                                                                classNamePrefix="react-select"
                                                                                                                onChange={(selectedOption) => {
                                                                                                                    console.log(selectedOption);
                                                                                                                    setFieldValue("technicianId", selectedOption);
                                                                                                                }}
                                                                                                                value={technician.find((option) => option.value === values.technicianId)}
                                                                                                            />
                                                                                                            <ErrorMessage name="technicianId" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
                                                                                                        </div>
                                                                                                    </div>


                                                                                                </div>


                                                                                                <button type="submit" className="btn btn-success generate-btn"
                                                                                                        data-bs-dismiss="modal"
                                                                                                        aria-label="Close" >
                                                                                                    Assign to Request
                                                                                                </button>
                                                                                                {/*<button type="submit" className="btn btn-success">*/}
                                                                                                {/*    Submit*/}
                                                                                                {/*</button>*/}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                </div>

                                                                            </Form>
                                                                        )}
                                                                    </Formik>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*<div class="modal-footer">*/}

                                                        {/*    <button type="button"*/}
                                                        {/*            className="btn btn-primary">Assign to Request*/}
                                                        {/*    </button>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>


                                            <div>
                                                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                            </div>
                                            </>
                                                )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({
    adminService: state.adminService, // Assuming the adminService data is stored in state.adminService
    isLoadingData: state.adminService?.loadingAdminService, // Assuming the adminService loader is stored in state.adminService.loadingAdminService
    assignTech: state.assignTech, // Assuming the assignTech data is stored in state.assignTech
});

const mapDispatchToProps = {
    fetchAdminServices,
    resetAdminServiceRequest,
    assignTechnician,
    resetTechnicianRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestListing);