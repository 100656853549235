//  approveEmachine.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's approve E-Machine state on admin panel.
import axios from "axios";
import { logout } from "_store/login.slice";
import Swal from "sweetalert2";
import {fetchAdminEmachine} from "./adminEmachine.slice";
import { history } from "_helpers";

// Action Types
const APPROVE_MACHINE_REQUEST = "APPROVE_MACHINE_REQUEST";
const APPROVE_MACHINE_SUCCESS = "APPROVE_MACHINE_SUCCESS";
const APPROVE_MACHINE_FAILURE = "APPROVE_MACHINE_FAILURE";
const RESET_APPROVE_MACHINE_REQUEST = "RESET_APPROVE_MACHINE_REQUEST";

// Action Creators
const approveMachineRequest = () => ({
    type: APPROVE_MACHINE_REQUEST,
});

export const resetApproveMachineRequest = () => ({
    type: RESET_APPROVE_MACHINE_REQUEST,
});

export const approveMachineSuccess = (machine) => ({
    type: APPROVE_MACHINE_SUCCESS,
    payload: machine,
});

const approveMachineFailure = (error) => ({
    type: APPROVE_MACHINE_FAILURE,
    payload: error,
});

// Thunk Action
export const approveEMachine = (machineData,pageSize, searchFilters, sortConfig, currentPage, perPage) => {
    const eMachineId ={
       "eMachineId":
     machineData.eMachineId};
    console.log(eMachineId)
    return async (dispatch) => {

        dispatch(approveMachineRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/e-machine/update-status`, eMachineId, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            dispatch(approveMachineSuccess(response.data.response));
            Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 3000,
            });
            dispatch(
                fetchAdminEmachine({
                    pageSize: machineData.pageSize,
                    searchFilters: machineData.searchFilters,
                    sortConfig: machineData.sortConfig,
                    currentPage: machineData.currentPage,
                    perPage: machineData.perPage
                })
            );
            // Additional logic if needed
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(approveMachineFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    approveMachine: null,
    loadingApproveMachine: false,
    errorApproveMachine: null,
};

// Reducer
const approveEmachineReducer = (state = initialState, action) => {
    switch (action.type) {
        case APPROVE_MACHINE_REQUEST:
            return {
                ...state,
                loadingApproveMachine: true,
                errorApproveMachine: null,
            };
        case RESET_APPROVE_MACHINE_REQUEST:
            return {
                approveMachine: null,
                loadingApproveMachine: false,
                errorApproveMachine: null,
            };
        case APPROVE_MACHINE_SUCCESS:
            return {
                ...state,
                loadingApproveMachine: false,
                approveMachine: action.payload,
            };
        case APPROVE_MACHINE_FAILURE:
            return {
                ...state,
                loadingApproveMachine: false,
                errorApproveMachine: action.payload,
            };
        default:
            return state;
    }
};

export default approveEmachineReducer;
