//  company.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to create company
import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Nav } from "_components";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { fetchCompanyProfile } from "_store/tehnician/company.slice";
import { ClipLoader } from "react-spinners";
import { resetCompanySaved, saveCompany } from "_store/tehnician/companySave.slice";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../styles.custom.css";
import Select from "react-select";
import { State, City } from "country-state-city";
import { fetchTechnicianProfilePrerequisite } from "_store/tehnician/prerequsite.slice";
import CreatableSelect from "react-select/creatable";

/**
 * Company component
 * @returns JSX.Element
 */
const Company = () => {
	const dispatch = useDispatch();
	const { user: authUser } = useSelector((state) => state.auth);
	const { company, loadingCompany } = useSelector((state) => state.company.profile);
	const { savedCompany } = useSelector((state) => state.company);
	const [newEMachine, setNewEMachine] = useState([]);
	const [createEMachine, setCreateEMachine] = useState([]);
	const {
		prerequisite: { data, loadingPrerequisite },
	} = useSelector((state) => state.technician);
	const {
		profile: { profile },
	} = useSelector((state) => state.technician);
	useEffect(() => {
		console.log({profile})
		dispatch(fetchTechnicianProfilePrerequisite());
		dispatch(fetchCompanyProfile(profile?.technicianProfile|| authUser?.profile));
	}, []);
	useEffect(() => {
		console.log(data, "company");
		setNewEMachine(data?.eMachine);
	}, [data]);
	useEffect(() => {
		console.log({ company });
	}, [company]);

	const updatedStates = (countryId) => State.getStatesOfCountry(countryId).map((state) => ({ label: state.name, value: state.isoCode, ...state }));
	const updatedCities = (countryId, stateId) => {
		const cities = City.getCitiesOfState(countryId, stateId).map((city) => ({ label: city.name, value: city.name, ...city }));
		return cities;
	};

	const initialValues = {
		name: company?.name || "",
		phoneNo: company?.phoneNo || "",
		email: company?.email || "",
		website: company?.website || "",
		owner: company?.owner || "",
		address: company?.address || "",
		serviceType: company?.serviceType || "",
		state: company && company.state ? JSON.parse(company.state) :"",
		city: company && company.city ? JSON.parse(company.city) : "",
		zipCodes: company?.zipCodes || "",
		machineExpertise: company?.machineExpertise.map((id) => parseInt(id, 10)) || [],
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Name is required"),
		phoneNo: Yup.string().required("Phone number is required"),
		email: Yup.string().email("Invalid email").required("Email is required"),
		website: Yup.string().url("Invalid URL").required("Website URL is required"),
		owner: Yup.string().required("Owner name is required"),
		address: Yup.string().required("Address is required"),
		serviceType: Yup.string().required("Service type is required"),
		state: Yup.object().required("State is required"),
		city: Yup.object().required("City is required"),
		zipCodes: Yup.number().required("ZipCode is required"),
		machineExpertise: Yup.array().min(1, "Select at least one machine expertise"),
	});

	const handleSubmit = (values, { setSubmitting }) => {
		dispatch(saveCompany({ ...values, technicianId: profile?.technicianProfile?.id ,machines: createEMachine}));
		setSubmitting(false);
	};

	useEffect(() => {
		if (savedCompany?.savedCompany) {
			Swal.fire({
				icon: "success",
				title: "Company Profile Saved Successfully",
				timer: 3000,
			});
			dispatch(resetCompanySaved());
		}
	}, [savedCompany]);
	const options = [
		{ value: "machine1", label: "Machine 1" },
		{ value: "machine2", label: "Machine 2" },
		{ value: "machine3", label: "Machine 3" },
	];
	const stateOptions = [
		{ value: "state1", label: "State 1" },
		{ value: "state2", label: "State 2" },
		{ value: "state3", label: "State 3" },
	];
	return (
		<>
			<Nav />

			<div>
				<div className="row">
					<div className="col-md-6 offset-md-3">
						{loadingCompany && (
							<div className="loader-container">
								<div className="loader-wrapper">
									<ClipLoader loading={loadingCompany} size={100} color="white" />
								</div>
							</div>
						)}
						{!loadingCompany && (
							<div className="card profileForm forms">
								<div className="card-header">
									<h2>Company Profile</h2>
									{company?.isApproved &&(
									<span style={{color:'green'}}>Approved</span>)}
									{company?.isApproved != null && !company?.isApproved &&(
									<span style={{color:'red'}}>Not Approved</span>)}
								</div>
								<div className="card-body">
									<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
										{({ isSubmitting, errors, touched, values, setFieldValue, setFieldTouched }) => (
											<Form>
												
												<div className="section">
													<h3>Company Info</h3>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="name">Name</label>
																<Field type="text" name="name" className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`} />
																<ErrorMessage name="name" component="div" className="invalid-feedback" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="email">Email</label>
																<Field type="text" name="email" className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`} />
																<ErrorMessage name="email" component="div" className="invalid-feedback" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="phoneNo">Phone Number</label>
																<PhoneInput
																	country="us" // Set your default country
																	inputProps={{
																		name: "phoneNo",
																		className: ` w-100 h-auto form-control ${touched.phoneNo && errors.phoneNo ? "is-invalid" : ""}`,
																	}}
																	value={values.phoneNo}
																	onChange={(phoneNo) => setFieldValue("phoneNo", phoneNo)}
																/>
																<ErrorMessage name="phoneNo" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="website">Website</label>
																<Field type="text" name="website" className={`form-control ${touched.website && errors.website ? "is-invalid" : ""}`} />
																<ErrorMessage name="website" component="div" className="invalid-feedback" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="owner">Owner Name</label>
																<Field type="text" name="owner" className={`form-control ${touched.owner && errors.owner ? "is-invalid" : ""}`} />
																<ErrorMessage name="owner" component="div" className="invalid-feedback" />
															</div>
														</div>
													</div>
												</div>
												<div className="section">
													<h3>Service Area</h3>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label className={` ${touched.serviceType && errors.serviceType ? "is-invalid" : ""}`}>Service Type</label>
																<div>
																	<div className="form-check form-check-inline">
																		<label className="form-check-label">
																			<Field type="radio" name="serviceType" value="incall" className="form-check-input" />
																			Incall
																		</label>
																	</div>
																	<div className="form-check form-check-inline">
																		<label className="form-check-label">
																			<Field type="radio" name="serviceType" value="outcall" className="form-check-input" />
																			Outcall
																		</label>
																	</div>
																</div>
																<ErrorMessage name="serviceType" component="div" className="invalid-feedback" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="state">Service Coverage State</label>

																<Select
																	id="state"
																	name="state"
																	options={updatedStates("US")}
																	value={values.state}
																	onChange={(value) => {
																		setFieldValue("state", value);
																	}}
																/>
																<ErrorMessage name="state" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="city">Service Coverage City</label>
																<Select
																	id="city"
																	name="city"
																	options={updatedCities("US", values.state ? values.state.value : null)}
																	value={values.city}
																	onChange={(value) => setFieldValue("city", value)}
																/>{" "}
																<ErrorMessage name="city" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="zipCodes"> Service Coverage Zip Code</label>
																<Field type="number" name="zipCodes" className={`form-control ${touched.zipCodes && errors.zipCodes ? "is-invalid" : ""}`} />
																<ErrorMessage name="zipCodes" component="div" className="invalid-feedback" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="address">Street Address</label>
																<Field type="text" name="address" className={`form-control ${touched.address && errors.address ? "is-invalid" : ""}`} />
																<ErrorMessage name="address" component="div" className="invalid-feedback" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="machineExpertise">Types of Machine Expertise</label>
																{data?.eMachine && (
																	<CreatableSelect
																		id="machineExpertise"
																		name="machineExpertise"
																		options={newEMachine}
																		value={newEMachine.filter((option) => values?.machineExpertise.includes(option.value))}
																		isMulti
																		onChange={(selectedOptions) => {
																			setFieldValue("machineExpertise", selectedOptions ? selectedOptions.map((option) => option.value) : []);
																		}}
																		onCreateOption={(newOption) => {
																			// Add logic to handle creating a new option
																			const nextValue =  newEMachine.length + 1;
																			const newValue = { value: nextValue, label: newOption };

																			// Update the options list
																			const updatedOptions = [...data.eMachine, newValue];

																			// Update the state with the updated options list
																			setNewEMachine([...newEMachine, newValue]);
																			setCreateEMachine([...createEMachine, newValue]);

																			setFieldValue("machineExpertise", [...values.machineExpertise, newValue.value]);
																		}}
																	/>
																)}
																<ErrorMessage name="machineExpertise" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
															</div>
														</div>
													</div>
												</div>

												{/* Add other form fields similarly */}
												{!company?.name && (
													<button type="submit" className="btn btn-primary" disabled={isSubmitting || savedCompany?.loadingSaveCompany || savedCompany?.savedCompany !== null}>
														Submit <ClipLoader loading={savedCompany?.loadingSaveCompany} size={"10"} />
													</button>
												)}
											</Form>
										)}
									</Formik>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Company;
