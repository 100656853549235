//  machine.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's machine state on techincian panel.
import { logout } from "_store/login.slice";
import axios from "axios";
import Swal from "sweetalert2";

// Action Types
const FETCH_MACHINES_REQUEST = "FETCH_MACHINES_REQUEST";
const FETCH_MACHINES_SUCCESS = "FETCH_MACHINES_SUCCESS";
const FETCH_MACHINES_FAILURE = "FETCH_MACHINES_FAILURE";
const RESET_MACHINES = "RESET_MACHINES";

// Action Creators
const fetchMachinesRequest = () => ({
    type: FETCH_MACHINES_REQUEST,
});

const fetchMachinesSuccess = (machines) => ({
    type: FETCH_MACHINES_SUCCESS,
    payload: machines,
});

const fetchMachinesFailure = (error) => ({
    type: FETCH_MACHINES_FAILURE,
    payload: error,
});

export const resetMachines = () => ({
    type: RESET_MACHINES,
});

// Thunk Action
export const fetchMachines = ({pageSize, searchFilters, sortConfig, currentPage,perPage}) => {
    return async (dispatch) => {
        dispatch(fetchMachinesRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/admin/e-machine`, {
				params: {pageSize, sortConfig, currentPage,perPage},
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            dispatch(fetchMachinesSuccess(response.data?.response));
        } catch (error) {
            if (error.response && error.response.status === 404) {
                dispatch(fetchMachinesSuccess(null));
            }

            if (error.response && error.response.status === 401) {
                // If the response status is 401 (Unauthorized), dispatch the logout action
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(fetchMachinesFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    machines: null,
    loadingMachines: false,
    error: null,
};

// Reducer
const machinesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MACHINES_REQUEST:
            return {
                ...state,
                loadingMachines: true,
                error: null,
            };
        case RESET_MACHINES:
            return {
                machines: null,
                loadingMachines: false,
                error: null,
            };
        case FETCH_MACHINES_SUCCESS:
            return {
                ...state,
                loadingMachines: false,
                machines: action.payload,
            };
        case FETCH_MACHINES_FAILURE:
            return {
                ...state,
                loadingMachines: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default machinesReducer;
