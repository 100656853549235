//  technicianService.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's technician service state on techincian panel.
import { logout } from "_store/login.slice";
import axios from "axios";
import Swal from "sweetalert2";

// Action Types
const FETCH_TECHNICIAN_SERVICE_REQUEST = "FETCH_TECHNICIAN_SERVICE_REQUEST";
const FETCH_TECHNICIAN_SERVICE_REQUEST_SUCCESS = "FETCH_TECHNICIAN_SERVICE_REQUEST_SUCCESS";
const FETCH_TECHNICIAN_SERVICE_REQUEST_FAILURE = "FETCH_TECHNICIAN_SERVICE_REQUEST_FAILURE";
const RESET_TECHNICIAN_SERVICE_REQUEST = "RESET_TECHNICIAN_SERVICE_REQUEST";

// Action Creators
const fetchTechnicianServiceRequest = () => ({
    type: FETCH_TECHNICIAN_SERVICE_REQUEST,
});

const fetchTechnicianServiceRequestSuccess = (serviceRequest) => ({
    type: FETCH_TECHNICIAN_SERVICE_REQUEST_SUCCESS,
    payload: serviceRequest,
});

const fetchTechnicianServiceRequestFailure = (error) => ({
    type: FETCH_TECHNICIAN_SERVICE_REQUEST_FAILURE,
    payload: error,
});

export const resetTechnicianServiceRequest = () => ({
    type: RESET_TECHNICIAN_SERVICE_REQUEST,
});

// Thunk Action
export const fetchTechnicianServices = ({pageSize, searchFilters, sortConfig, currentPage,perPage}) => {
    return async (dispatch) => {
        dispatch(fetchTechnicianServiceRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/technician/service-requests/list`, {
                params: {pageSize, sortConfig, currentPage,perPage},
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            // await new Promise((resolve) => setTimeout(resolve, 4000));
            dispatch(fetchTechnicianServiceRequestSuccess(response.data?.response));
        } catch (error) {
            if (error.response && error.response.status === 404) {
                dispatch(fetchTechnicianServiceRequestSuccess(null));
            }

            if (error.response && error.response.status === 401) {
                // If the response status is 401 (Unauthorized), dispatch the logout action
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(fetchTechnicianServiceRequestFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    technicianServiceRequest: null,
    loadingTechnicianService: false,
    error: null,
};

// Reducer
const technicianServiceRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TECHNICIAN_SERVICE_REQUEST:
            return {
                ...state,
                loadingTechnicianService: true,
                error: null,
            };
        case RESET_TECHNICIAN_SERVICE_REQUEST:
            return {
                technicianServiceRequest: null,
                loadingTechnicianService: false,
                error: null,
            };
        case FETCH_TECHNICIAN_SERVICE_REQUEST_SUCCESS:
            return {
                ...state,
                loadingTechnicianService: false,
                technicianServiceRequest: action.payload,
            };
        case FETCH_TECHNICIAN_SERVICE_REQUEST_FAILURE:
            return {
                ...state,
                loadingTechnicianService: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default technicianServiceRequestReducer;
