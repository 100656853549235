//  EmachineManagement.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to Manage  E-Machine Listing
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { fetchUserProfile, resetUserProfile } from "_store/admin/users.slice";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import { approveProfile } from "_store/admin/approval.slice";
import { approveCompanyProfile } from "_store/admin/approvalCompany.slice";
import { history } from "_helpers";

import { fetchMachines } from "_store/tehnician/machine.slice";

const EMachineManagement = ({ fetchMachines, resetUserProfile, approveProfile, approveCompanyProfile, machines }) => {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [sortBy, setSortBy] = useState({});
	const dispatch = useDispatch();
	useEffect(() => {
		console.log();
		fetchMachines({ page, perPage, sortBy });
		return () => {
			// Reset user profile state when component unmounts
			resetUserProfile();
		};
	}, [fetchMachines, page, perPage, sortBy]);

	useEffect(() => {
		console.log({ machines });
	}, [machines]);
	// Sample JSON data for user list
	const data = machines || [];

	// // Define columns for the DataTable
	const columns = [
		{
			name: "Device Name",
			selector: (row) => row.deviceName,
			sortable: true,
		},
		{
			name: "Serial Number",
			selector: (row) => row.serialNumber,
			sortable: true,
		},
		{
			name: "Model",
			selector: (row) => row.model,
			sortable: true,
		},
		{
			name: "Manufacturer",
			selector: (row) => row.manufacturer,
			sortable: true,
		},
		{
			name: "Purchase Date",
			selector: (row) => new Date(row.purchaseDate).toLocaleDateString(),
			sortable: true,
		},
		{
			name: "Warranty Expiry Date",
			selector: (row) => new Date(row.warrantyExpiryDate).toLocaleDateString(),
			sortable: true,
		},
		{
			name: "Description",
			selector: (row) => row.description,
			sortable: true,
		},
		{
			name: "MAC Address",
			selector: (row) => row.macAddress,
			sortable: true,
		},
		{
			name: "Location",
			selector: (row) => row.location,
			sortable: true,
		},
		{
			name: "Operating System",
			selector: (row) => row.operatingSystem,
			sortable: true,
		},
		{
			name: "Actions",
			cell: (row) => {
				return (
					<div className="row">
						<div className="col-12">
							{/* You can add action buttons here */}
						</div>
					</div>
				);
			},
		},
	];
	// const columns = [
	// 	{
	// 		name: 'Title',
	// 		selector: row => row.title,
	// 	},
	// 	{
	// 		name: 'Year',
	// 		selector: row => row.year,
	// 	},
	// ];

	// Define action to handle blocking user
	const handleApprovedProfile = (user) => {
		// Implement logic to block user
		// approveProfile(user, page);
	};
	const handleApprovedCompany = (user) => {
		// Implement logic to block user
		// approveCompanyProfile(user, page);
	};

	// Handle pagination change
	const handlePageChange = (page) => {
		setPage(page);
	};

	// Handle rows per page change
	const handlePerPageChange = (newPerPage, page) => {
		setPage(1);
		setPerPage(newPerPage);
	};

	// Handle sorting change
	const handleSort = (column, direction) => {
		setSortBy({ field: column.selector, direction });
	};

	return (
		<>
			{/* Main content wrapper */}
			<div className="content-wrapper">
				{/* Content header */}
				<div className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h1 className="m-0">E Machine Management</h1>
							</div>
							<div className="col-md-12 text-right">
								<button className="btn btn-primary" onClick={()=>history.navigate('/dashboard/e-machine/register')}>
									Add <i className="fas fa-plus-circle ml-1"></i>
								</button>
							</div>
						</div>
					</div>
				</div>

				{/* Users list */}
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<DataTable
											columns={columns}
											data={data}
											fixedHeader={true}
											persistTableHead={true}
											progressPending={machines?.loadingUser}
											progressComponent={
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														height: "300px",
													}}>
													<ClipLoader loading={machines?.loadingUser} />
												</div>
											}
											pagination
											paginationServer
											paginationTotalRows={machines?.user?.total}
											onChangePage={handlePageChange}
											onChangeRowsPerPage={handlePerPageChange}
											paginationPerPage={perPage}
											paginationRowsPerPageOptions={[10, 20, 30]}
											// sortServer
											// sortIcon={<span className="fas fa-sort" />}
											onSort={handleSort}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	machines: state.machines.machines.machines, // Assuming the user data is stored in state.admin.user
});

const mapDispatchToProps = {
	fetchMachines,
	resetUserProfile,
	approveProfile,
	approveCompanyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(EMachineManagement);
