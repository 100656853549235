//  Login.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface for user login.
//  allowing users to login with their email, and password.
import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { history } from "_helpers";
import { login, loginReset } from "_store/login.slice";
import SideBarComponent from "partials/Sidebar";

const Login = () => {
	const dispatch = useDispatch();
	const authUser = useSelector((x) => x.auth.user);
	const authError = useSelector((x) => x.auth.error);

	useEffect(() => {
		if (authUser && authUser?.roleId == 1) {
			history.navigate("/dashboard");
		}
		if ((authUser && authUser?.roleId == 4) || authUser?.roleId == 3) {
			history.navigate("/");
		}
	}, [authUser]);

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			console.log({ values }, authUser?.isLoading);
			await dispatch(login(values));
			setSubmitting(false);
		} catch (error) {
			setSubmitting(false);
		}
	};
	useEffect(() => {
		dispatch(loginReset());
	}, []);

	const validationSchema = Yup.object().shape({
		email: Yup.string().email("Please use correct email").required("Email is required"),
		password: Yup.string().required("Password is required"),
	});

	return (
		
		<div className="container">

			<div className="col-md-6 offset-md-3 mt-5">
				<div className="card">
					<h4 className="card-header">Login</h4>
					<div className="card-body">
						<Formik initialValues={{ email: "", password: "" }} validationSchema={validationSchema} onSubmit={handleSubmit}>
							{({ isSubmitting, errors }) => (
								<Form>
									<div className="mb-3">
										<label htmlFor="email" className="form-label">
											Email
										</label>
										<Field type="text" id="email" name="email" className={`form-control ${errors.email ? "is-invalid" : ""}`} />
										<ErrorMessage name="email" component="div" className="invalid-feedback" />
									</div>
									<div className="mb-3">
										<label htmlFor="password" className="form-label">
											Password
										</label>
										<Field type="password" id="password" name="password" className={`form-control ${errors.password ? "is-invalid" : ""}`} />
										<ErrorMessage name="password" component="div" className="invalid-feedback" />
									</div>
									<button type="submit" disabled={isSubmitting} className="btn btn-primary">
										{isSubmitting && <span className="spinner-border spinner-border-sm me-1" />}
										Login
									</button>
									{authError && <div className="alert alert-danger mt-3 mb-0">{authError}</div>}
								</Form>
							)}
						</Formik>
					</div>
					<div className="card-footer">
						<div className="mt-3">
							<p>
								Want to have an account for Customer? <Link to="/register">Register</Link>
							</p>
							<p>
								Want to have an account For Technician? <Link to="/register/technician">Register as Technician</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
