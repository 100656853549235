//  UserManagementTheme.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to manege User Listing on admin side.
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { fetchUserProfile, resetUserProfile } from "_store/admin/users.slice";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import { approveProfile } from "_store/admin/approval.slice";
import { approveCompanyProfile } from "_store/admin/approvalCompany.slice";
import PageHeader from "partials/PageHeader";
import Pagination from "partials/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown, faSort, faLock, faLockOpen} from "@fortawesome/free-solid-svg-icons";

const UserManagementTheme = ({ fetchUserProfile, resetUserProfile, approveProfile, approveCompanyProfile, users ,isLoadingData}) => {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [sortBy, setSortBy] = useState({});
	const [sortConfig, setSortConfig] = React.useState({
		column: "id",
		order: "desc",
	});
	const [searchFilters, setSearchFilters] = React.useState({
		id: "",
		fullName: "",
		email: "",
		role: "",
	});
	const [currentPage, setCurrentPage] = React.useState(1);
	const [totalPages, setTotalPages] = React.useState(0);
	const pageSize = 10;
	const handleSortChange = (columnName) => {
		if (sortConfig.column === columnName) {
			// Toggle the sorting order if the same column is clicked again
			setSortConfig({
				...sortConfig,
				order: sortConfig.order === "asc" ? "desc" : "asc",
			});
		} else {
			// If a new column is clicked, set it as the sorting column and reset the order to 'asc'
			setSortConfig({ column: columnName, order: "asc" });
		}
	};

	const dispatch = useDispatch();
	useEffect(() => {
		console.log({ sortConfig });
		fetchUserProfile({ pageSize, searchFilters, sortConfig, currentPage, perPage });
		return () => {
			// Reset user profile state when component unmounts
			resetUserProfile();
		};
	}, [fetchUserProfile, page, perPage, sortBy, sortConfig, currentPage]);
	const handlePageChange = async (page) => {
		setCurrentPage(page);
		await dispatch(fetchUserProfile(pageSize, searchFilters, sortConfig, currentPage, perPage));
	};

	// Sample JSON data for user list
	const data = users?.user?.users || [];
	useEffect(() => {
		console.log("total", users?.user?.total);
		setTotalPages(Math.ceil(users?.user?.total / perPage));
	}, [users]);
	// // Define columns for the DataTable
	const columns = [
		{
			name: "Full Name",
			selector: "fullName",
			sort: true,
		},
		{
			name: "Email",
			selector: "emailAddress",
			sort: true,
		},
		{
			name: "Role",
			selector: "roleId",
			sort: true,
		},
		{
			name: "Technician Profile",
			selector: "Technician",
			sort: false,
		},
		{
			name: "Company Profile",
			selector: "Technician?.Company",
			sort: false,
		},
		{
			name: "Action",
			selector: null,
			sort: false,
		},
	];
	
	// Define action to handle blocking user
	const handleApprovedProfile = (user) => {
		// Implement logic to block user
		approveProfile(user, perPage,currentPage,sortConfig	);
	};
	const handleApprovedCompany = (user) => {
		// Implement logic to block user
		approveCompanyProfile(user, perPage,currentPage,sortConfig);
	};

	// Handle rows per page change
	const handlePerPageChange = (newPerPage, page) => {
		setPage(1);
		setPerPage(newPerPage);
	};

	// Handle sorting change
	const handleSort = (column, direction) => {
		setSortBy({ field: column.selector, direction });
	};

	return (
		<>
			<PageHeader title={"User Management"} subtitle={"Home"} />

			{/* Main content wrapper */}
			<div className="content-wrapper">
				{/* Content header */}

				{/* Users list */}
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="card custom-card">
									<div class="card-header">
										<div class="card-title">Users</div>
									</div>
									<div className="card-body">
										<div className="table-responsive rounded card-table">
											<div className="row" style={{marginBottom:'10px'}}>
												<div className="col-sm-12 col-md-6 ">
													<div className="dataTables_length" id="datatable-basic_length">
														<label className="d-inline-flex align-items-center">
															Show{" "}
															<select name="datatable-basic_length" aria-controls="datatable-basic" className="form-select form-select-sm ms-2 mr-2	" onChange={(e) => setPerPage(e.target.value)}>
																<option value={10}>10</option>
																<option value={25}>25</option>
																<option value={50}>50</option>
																<option value={100}>100</option>
															</select>
															<span className="ml-2">entries</span>
														</label>
													</div>
												</div>
												{/* <div className="col-sm-12 col-md-6">
													<div id="datatable-basic_filter" className="dataTables_filter">
														<label>
															<input type="search" className="form-control form-control-sm" placeholder="Search..." aria-controls="datatable-basic" />
														</label>
													</div>
												</div> */}
											</div>
											{isLoadingData ? (
												<div className="text-center">
													<ClipLoader  size={50} loading={true} />
												</div>
											) : (
                                            <>
											<table className="table table-bordered text-nowrap dataTable no-footer" id="example1">
												<thead>
													<tr>
														{columns.map((column, index) => (
															<th
																key={index}
																onClick={() => (column.sort ? handleSortChange(column.selector) : null)}
																style={{ cursor: column.sort ? "pointer" : "default" }}>
																<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
																	<span>{column.name}</span>
																	{column.sort && (
																		<FontAwesomeIcon
																			icon={sortConfig.column === column.selector ? (sortConfig.order === "asc" ? faSortUp : faSortDown) : faSort}
																			style={{ fontSize: "0.8em", marginLeft: "5px" }}
																		/>
																	)}
																</div>
															</th>
														))}
													</tr>
												</thead>
												<tbody>
													{data &&
														data.length !== 0 &&
														data.map((user, i) => (
															<tr className="hover-primary" key={i}>
																<td>{user?.fullName}</td>
																<td>{user?.emailAddress}</td>
																<td>
																	{user.roleId &&
																		(user.roleId === "1" ? "Admin" : user.roleId === "2" ? "Support Staff" : user.roleId === "3" ? "Technician" : user.roleId === "4" ? "Customer" : null)}
																</td>{" "}
																<td>{user?.Technician !== null ? "Created" : "Not Created"}</td> 
																<td>{user?.Technician?.Company && user?.Technician?.Company !== null ? "Created" : "Not Created"}</td>{" "}
																<td>
																	<div className="row">
																		<div className="col-md-12">
																			{user?.Technician !== null && (
																				<button className="btn btn-primary " onClick={() => handleApprovedProfile(user)}>
																					{user?.Technician?.isApproved == true ? <FontAwesomeIcon icon={faLockOpen}/> : <FontAwesomeIcon icon={faLock}/>} Profile
																				</button>
																			)}
																		</div>
																		<div className="col-md-12 " style={{ marginTop: "10px" }}>
																			{user?.Technician !== null && user?.Technician?.Company !== null && (
																				<button className="btn btn-primary" onClick={() => handleApprovedCompany(user)}>
																					{user?.Technician?.Company?.isApproved == true ? <FontAwesomeIcon icon={faLockOpen}/> : <FontAwesomeIcon icon={faLock}/>}{" "}
																					Company
																				</button>
																			)}
																		</div>
																	</div>
																</td>
															</tr>
														))}
												</tbody>
											</table>
											<div>
												<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
											</div>
											</>
												)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	users: state.admin, // Assuming the user data is stored in state.admin.user
	isLoadingData: state.admin?.loadingUser, // Assuming the user data is stored in state.admin.user
});

const mapDispatchToProps = {
	fetchUserProfile,
	resetUserProfile,
	approveProfile,
	approveCompanyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementTheme);
