//  adminEmachine.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's admin E-Machine state on admin panel.
import axios from "axios";
import { logout } from "_store/login.slice";
import Swal from "sweetalert2";
import { history } from "_helpers";

// Action Types
const FETCH_ADMIN_EMACHINE_REQUEST = "FETCH_ADMIN_EMACHINE_REQUEST";
const FETCH_ADMIN_EMACHINE_SUCCESS = "FETCH_ADMIN_EMACHINE_SUCCESS";
const FETCH_ADMIN_EMACHINE_FAILURE = "FETCH_ADMIN_EMACHINE_FAILURE";
const RESET_ADMIN_EMACHINE_REQUEST = "RESET_ADMIN_EMACHINE_REQUEST";

// Action Creators
const fetchAdminEmachineRequest = () => ({
    type: FETCH_ADMIN_EMACHINE_REQUEST,
});

export const resetAdminEmachineRequest = () => ({
    type: RESET_ADMIN_EMACHINE_REQUEST,
});

export const fetchAdminEmachineRequestSuccess = (emachine) => ({
    type: FETCH_ADMIN_EMACHINE_SUCCESS,
    payload: emachine,
});

const fetchAdminEmachineRequestFailure = (error) => ({
    type: FETCH_ADMIN_EMACHINE_FAILURE,
    payload: error,
});

// Thunk Action
export const fetchAdminEmachine = ({ pageSize, searchFilters, sortConfig, currentPage, perPage, onlyCustomerRequest }) => {
    return async (dispatch) => {
        dispatch(fetchAdminEmachineRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/admin/e-machine`, {
                params: {pageSize, sortConfig, currentPage,perPage, onlyCustomerRequest},
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            dispatch(fetchAdminEmachineRequestSuccess(response.data.response));
            // history.navigate('/customer/service-request-list')
            // Additional logic if needed
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(fetchAdminEmachineRequestFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    adminEmachine: null,
    loadingAdminEmachine: false,
    errorAdminEmachine: null,
};

// Reducer
const adminEmachineReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_EMACHINE_REQUEST:
            return {
                ...state,
                loadingAdminEmachine: true,
                errorAdminEmachine: null,
            };
        case RESET_ADMIN_EMACHINE_REQUEST:
            return {
                adminEmachine: null,
                loadingAdminEmachine: false,
                errorAdminEmachine: null,
            };
        case FETCH_ADMIN_EMACHINE_SUCCESS:
            return {
                ...state,
                loadingAdminEmachine: false,
                emachine: action.payload,
            };
        case FETCH_ADMIN_EMACHINE_FAILURE:
            return {
                ...state,
                loadingAdminEmachine: false,
                errorAdminEmachine: action.payload,
            };
        default:
            return state;
    }
};

export default adminEmachineReducer;
