//  TechnicianProfileTheme.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to create technician profile
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAll } from "_store/users.slice";
import { Nav } from "_components";
import * as Yup from "yup";

// import PhoneInput from "react-phone-number-input/input";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../styles.custom.css";
import { fetchTechnicianProfile } from "_store/tehnician/profile.slice";
import { ClipLoader } from "react-spinners";
import { resetTechnicianProfileSave, saveTechnicianProfile } from "_store/tehnician/profileSave.slice";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { State, City } from "country-state-city";
import TagsInput from "react-tagsinput";
import "../tags.css";
import { fetchTechnicianProfilePrerequisite } from "_store/tehnician/prerequsite.slice";
import CreatableSelect from "react-select/creatable";
import PageHeader from "partials/PageHeader";

const TechnicianProfileTheme = () => {
	const dispatch = useDispatch();
	const [newEMachine, setNewEMachine] = useState([]);
	const [createEMachine, setCreateEMachine] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const { user: authUser } = useSelector((state) => state.auth);
	const { users } = useSelector((state) => state.users);
	const {
		profile: { profile, loadingProfile },
		savedProfile,
	} = useSelector((state) => state.technician);
	const {
		prerequisite: { data, loadingPrerequisite },
	} = useSelector((state) => state.technician);
	const { company } = useSelector((state) => state.company.profile);

	// Trigger additional actions when the authenticated user changes
	useEffect(() => {
		// Add any side effects here
		dispatch(fetchTechnicianProfilePrerequisite());
		dispatch(fetchTechnicianProfile());
	}, []);
	useEffect(() => {
	}, [selectedFile]);
	useEffect(() => {
		console.log(data?.eMachine, "profile");
		setNewEMachine(data?.eMachine);
	}, [data]);

	// Mapping states to label and value
	const updatedStates = (countryId) => State.getStatesOfCountry(countryId).map((state) => ({ label: state.name, value: state.isoCode, ...state }));
	// Mapping cities to label and value
	const updatedCities = (countryId, stateId) => {
		const cities = City.getCitiesOfState(countryId, stateId).map((city) => ({ label: city.name, value: city.name, ...city }));
		return cities;
	};
	useEffect(() => {});
	console.log({ updatedStates });
	const handleFileSelect = (event) => {
		const file = event.target.files[0];
		console.log(file);
		if (file) {
			// Update the state with the selected file
			setSelectedFile(file);
		}
	};

	// Initialize the initialValues object with default values extracted from profile or empty strings
	const initialValues = {
		name: profile?.technicianProfile?.name || "",
		phoneNo: profile?.technicianProfile?.phoneNo || "",
		education: profile?.technicianProfile?.education || "",
		// certifications: profile && profile?.technicianProfile?.certifications ? profile?.technicianProfile?.certifications : [],
		certifications: profile && profile?.technicianProfile?.certifications ? profile?.technicianProfile?.certifications.map(cert => cert.split(",")).flat() : [],
		yearsOfExperience: profile?.technicianProfile?.yearsOfExperience || "",
		serviceType: profile?.technicianProfile?.serviceType || "",
		// machineExpertise: profile?.technicianProfile?.machineExpertise.map((id) => parseInt(id, 10)) || [],
		machineExpertise: profile?.technicianProfile?.machineExpertise.map(expertise => JSON.parse(expertise)) || [],
		experienceDetails: profile?.technicianProfile?.experienceDetails || "",
		city: profile && profile?.technicianProfile?.city ? JSON.parse(profile?.technicianProfile?.city) : "",
		serviceCoverageState: profile && profile?.technicianProfile?.serviceCoverageState ? JSON.parse(profile?.technicianProfile?.serviceCoverageState) : "",
		zipCodes: profile?.technicianProfile?.zipCodes || "",
		profilePicture: "", // Add profileImage field with an empty string as initial value

	};

// Define validation schema using Yup for form validation
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Name is required"),
		phoneNo: Yup.string().required("Phone number is required"),
		education: Yup.string().required("Education is required"),
		certifications: Yup.array().min(1, "Certifications are required"),
		yearsOfExperience: Yup.number().required("Years of experience is required"),
		serviceType: Yup.string().required("Service type is required"),
		machineExpertise: Yup.array().min(1, "Select at least one machine expertise"),
		experienceDetails: Yup.string().required("Experience details are required"),
		serviceCoverageState: Yup.object().required("Service coverage state is required"),
		city: Yup.object().required("City is required"),
		zipCodes: Yup.string().required("ZipCode is required"),
		profilePicture: Yup.string().required("Profile image is required"), // Add validation for profileImage

	});
	useEffect(() => {
		// Check if the savedProfile exists before showing success message
		if (savedProfile?.savedProfile) {
			Swal.fire({
				icon: "success",
				title: "Your Profile Saved Successfully",
				timer: 3000, // Display success message for 3 seconds
				timerProgressBar: true, // Show a progress bar with the timer
			}).then((result) => {
				if (result.dismiss === Swal.DismissReason.timer) {
					console.log("I was closed by the timer");
				}
			});
			dispatch(resetTechnicianProfileSave()); // Reset the save action after showing success message
		}
	}, [savedProfile]);



	const handleSubmit =  (values, { setSubmitting }) => {
		try {
			// Create FormData object
			const formData = new FormData();

			// Append all form fields to FormData
			formData.append('name', values.name);
			formData.append('phoneNo', values.phoneNo);
			formData.append('education', values.education);
			formData.append('yearsOfExperience', values.yearsOfExperience);
			formData.append('serviceType', values.serviceType);
			formData.append('experienceDetails', values.experienceDetails);
			formData.append('zipCodes', values.zipCodes);
			formData.append('certifications', values.certifications);
			formData.append('machineExpertise', JSON.stringify(values.machineExpertise));


			// Append city as JSON string
			formData.append('city', JSON.stringify(values.city));

			// Append serviceCoverageState as JSON string
			formData.append('serviceCoverageState', JSON.stringify(values.serviceCoverageState));

			// Append profilePicture (file) to FormData
			formData.append('profilePicture', values.profilePicture);

			// Call the saveTechnicianProfile action with the FormData object
			dispatch(saveTechnicianProfile(formData));

			// Handle successful form submission
			// console.log('Form submitted successfully!');
		} catch (error) {
			// Handle error
			// console.error('Error submitting form:', error);
		} finally {
			// Set submitting state to false
			setSubmitting(false);
		}
	};




	const options = [
		{ value: "machine1", label: "Machine 1" },
		{ value: "machine2", label: "Machine 2" },
		{ value: "machine3", label: "Machine 3" },
	];

	return (
		<>

			{/* Render the navigation component */}
			{/* <Nav /> */}
			<PageHeader title={"Technician Profile"} subtitle={"Profile"} approved={profile?.technicianProfile?.isApproved} />

			<div>
				{loadingProfile && (
					<div className="loader-container">
						<div className="loader-wrapper">
							<ClipLoader loading={loadingProfile} size={100} color="white" />
						</div>
					</div>
				)}

				{!loadingProfile && (
					<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
						{({ isSubmitting, errors, touched, values, setFieldValue, setFieldTouched }) => (
							<Form>
								<div className="row">
									<div className="col-xl-4 col-md-12 col-sm-12">
										<div className="card custom-card edit-password-section">
											<div className="card-header">
												<div className="card-title">Personal Info</div>
											</div>
											<div className="card-body">
												<div className="d-flex mb-3">
													<img alt="User Avatar" className="rounded-circle avatar-lg avatar me-2" src={profile?.technicianProfile?.profilePicture ?profile?.technicianProfile?.profilePicture :"../images/faces/8.jpg" } />
													<div class="ms-auto mt-xl-2 mt-lg-0 me-lg-2">
														<label htmlFor="profileImageInput" className="btn btn-primary btn-sm mt-1 mb-1">
															<i className="fe fe-camera me-1 float-start mt-1"></i>Add profile Image
														</label>
														<input
															type="file"
															id="profileImageInput"
															className={`form-control  ${touched.profilePicture && errors.profilePicture ? "is-invalid mb-2" : ""}`}
															name="profilePicture"
															style={{ display: 'none' }}

															onChange={(event) => {
																const file = event.target.files[0];
																setFieldValue("profilePicture", file);
															}}
														/>

														{values.profilePicture && <p>Selected file: {values.profilePicture.name}</p>}

														<ErrorMessage name="profilePicture" component="div" className="invalid-feedback" />

													</div>
												</div>
												<div className="form-group mb-3">
													<label htmlFor="name" className="form-label">
														Name
													</label>
													<Field type="text" name="name" className={`form-control  ${touched.name && errors.name ? "is-invalid mb-2" : ""}`} />
													<ErrorMessage name="name" component="div" className="invalid-feedback" />
												</div>
												<div className="form-group mb-3">
													<label htmlFor="education" className="form-label">
														Email
													</label>
													<Field type="text" value={authUser?.email} disabled className={`form-control`} />
													<ErrorMessage name="email" component="div" className="invalid-feedback" />
												</div>
												<div className="form-group mb-3">
													<label htmlFor="phoneNo" className="form-label">
														Phone Number
													</label>
													<PhoneInput
														country="us" // Set your default country
														inputProps={{
															name: "phoneNo",
															className: ` w-100 h-auto form-control ${touched.phoneNo && errors.phoneNo ? "is-invalid mb-2" : ""}`,
														}}
														value={values.phoneNo}
														onChange={(phoneNo) => setFieldValue("phoneNo", phoneNo)}
													/>
													<ErrorMessage name="phoneNo" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
												</div>
											</div>
										</div>
										<div className="card custom-card panel-theme">
											<div className="card-header">
												<div className="float-start">
													<h3 className="card-title">Qualifications</h3>
												</div>
												<div className="clearfix" />
											</div>
											<div className="card-body">
												<div className="form-group mb-3">
													<label htmlFor="education" className="form-label">
														Education
													</label>
													<Field type="text" name="education" className={`form-control ${touched.education && errors.education ? "is-invalid mb-2" : ""}`} />
													<ErrorMessage name="education" component="div" className="invalid-feedback" />
												</div>
												<div className="form-group mb-3">
													<label htmlFor="yearsOfExperience" className="form-label">
														Years of Experience
													</label>
													<Field type="number" name="yearsOfExperience" className={`form-control ${touched.yearsOfExperience && errors.yearsOfExperience ? "is-invalid mb-2" : ""}`} />
													<ErrorMessage name="yearsOfExperience" component="div" className="invalid-feedback" />
												</div>
												<div className="form-group mb-3 ">
													<label htmlFor="certifications" className="form-label">
														Certifications
													</label>
													<TagsInput
														inputProps={{
															className: "react-tagsinput-input",
															placeholder: "Add Certification",
														}}
														placeholder="Add Certifications"
														onlyUnique={true}
														value={values.certifications}
														onChange={(tags) => setFieldValue("certifications", tags)}
													/>
													<ErrorMessage name="certifications" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
												</div>
											</div>
										</div>
									</div>
									<div className="col-xl-8 col-md-12 col-sm-12">
										<div className="card custom-card">
											<div className="card-header">
												<h3 className="card-title">Service Area</h3>
											</div>
											<div className="card-body">
												<div className="row">
													<div className="col-lg-6 col-md-12">
														<div className="form-group">
															<label htmlFor="serviceCoverageState">Service Coverage State</label>

															<Select
																id="serviceCoverageState"
																name="serviceCoverageState"
																options={updatedStates("US")}
																value={values.serviceCoverageState}
																onChange={(value) => {
																	setFieldValue("serviceCoverageState", value);
																}}
															/>
															<ErrorMessage name="serviceCoverageState" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
														</div>
													</div>
													<div className="col-lg-6 col-md-12">
														<div className="form-group">
															<label htmlFor="city">Service Coverage City</label>
															<Select
																id="city"
																name="city"
																options={updatedCities("US", values.serviceCoverageState ? values.serviceCoverageState.value : null)}
																value={values.city}
																onChange={(value) => setFieldValue("city", value)}
															/>{" "}
															<ErrorMessage name="city" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="zipCodes">Service Coverage Zip Codes</label>
															<Field type="number" name="zipCodes" className={`form-control ${touched.zipCodes && errors.zipCodes ? "is-invalid mb-2" : ""}`} />
															<ErrorMessage name="zipCodes" component="div" className="invalid-feedback" />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className={` ${touched.serviceType && errors.serviceType ? "is-invalid mb-2" : ""}`}>Service Type</label>
															<div>
																<div className="form-check form-check-inline">
																	<label className="form-check-label">
																		<Field type="radio" name="serviceType" value="incall" className="form-check-input" />
																		Incall
																	</label>
																</div>
																<div className="form-check form-check-inline">
																	<label className="form-check-label">
																		<Field type="radio" name="serviceType" value="outcall" className="form-check-input" />
																		Outcall
																	</label>
																</div>
															</div>
															<ErrorMessage name="serviceType" component="div" className="invalid-feedback" />
														</div>
													</div>
												</div>
												<div className="form-group">
													<label htmlFor="machineExpertise">Types of Machine Expertise</label>
													{data?.eMachine && (
														<CreatableSelect
															id="machineExpertise"
															name="machineExpertise"
															options={newEMachine}
															value={newEMachine.filter(option => {
																// Flatten the nested array values.machineExpertise
																const flattenedMachineExpertise = values?.machineExpertise?.flat();
																return flattenedMachineExpertise.includes(option.value);
															})}
															isMulti
															onChange={(selectedOptions) => {
																setFieldValue("machineExpertise", selectedOptions ? selectedOptions.map((option) => option.value) : []);
															}}
															onCreateOption={(newOption) => {
																// Add logic to handle creating a new option
																const nextValue = newEMachine.length + 1;
																const newValue = { value: nextValue, label: newOption };

																// Update the options list
																const updatedOptions = [...data.eMachine, newValue];

																// Update the state with the updated options list
																setNewEMachine(updatedOptions);
																setCreateEMachine([...createEMachine]);

																setFieldValue("machineExpertise", [...values.machineExpertise, newValue.value]);
															}}
														/>
													)}
													<ErrorMessage name="machineExpertise" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="form-group">
															<label htmlFor="experienceDetails">Tell us more about your experience</label>
															<Field as="textarea" name="experienceDetails" className={`form-control ${touched.experienceDetails && errors.experienceDetails ? "is-invalid mb-2" : ""}`} />
															<ErrorMessage name="experienceDetails" component="div" className="invalid-feedback" />
														</div>
													</div>
												</div>
											</div>
											<div className="card-footer text-end">
												{!profile?.technicianProfile?.name && (
													<button type="submit" className="btn btn-success" disabled={isSubmitting || savedProfile?.loadingSaveProfile || savedProfile?.savedProfile !== null}>
														Submit <ClipLoader loading={savedProfile?.loadingSaveProfile} size={"10"} />
													</button>
												)}
												{profile?.technicianProfile?.name && !company?.name && !authUser.profile?.Company?.id && profile?.technicianProfile?.isApproved && (
													<div className="card-footer float-right ">
														<NavLink to="/technician/company" className="btn btn-primary float-right">
															Setup Company
														</NavLink>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				)}
			</div>
		</>
	);
};

export default TechnicianProfileTheme;
