//  editEmachine.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's edit E-Machine state on admin panel.
import axios from "axios";
import { logout } from "_store/login.slice";
import Swal from "sweetalert2";
import { history } from "_helpers";

// Action Types
const EDIT_MACHINE_REQUEST = "EDIT_MACHINE_REQUEST";
const EDIT_MACHINE_SUCCESS = "EDIT_MACHINE_SUCCESS";
const EDIT_MACHINE_FAILURE = "EDIT_MACHINE_FAILURE";
const RESET_EDIT_MACHINE_REQUEST = "RESET_EDIT_MACHINE_REQUEST";

// Action Creators
const editMachineRequest = () => ({
    type: EDIT_MACHINE_REQUEST,
});

export const resetEditMachineRequest = () => ({
    type: RESET_EDIT_MACHINE_REQUEST,
});

export const editMachineSuccess = (machine) => ({
    type: EDIT_MACHINE_SUCCESS,
    payload: machine,
});

const editMachineFailure = (error) => ({
    type: EDIT_MACHINE_FAILURE,
    payload: error,
});

// Thunk Action
export const editEMachine = (machineData) => {
    return async (dispatch) => {
        dispatch(editMachineRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/e-machine/details?eMachineId=`+machineData.eMachineId, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            dispatch(editMachineSuccess(response.data.response));
            // Swal.fire({
            //     icon: "success",
            //     title: response.data.message,
            //     timer: 3000,
            // });
            // history.navigate("/customer/service-request-list");
            // Additional logic if needed
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(editMachineFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    editMachine: null,
    loadingEditMachine: false,
    errorEditMachine: null,
};

// Reducer
const editEmachineReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_MACHINE_REQUEST:
            return {
                loadingEditMachine: true,
                errorEditMachine: null,
                editMachine: null,
            };
        case RESET_EDIT_MACHINE_REQUEST:
            return {
                editMachine: null,
                loadingEditMachine: false,
                errorEditMachine: null,
            };
        case EDIT_MACHINE_SUCCESS:
            return {
                ...state,
                loadingEditMachine: false,
                editMachine: action.payload,
            };
        case EDIT_MACHINE_FAILURE:
            return {
                ...state,
                loadingEditMachine: false,
                errorEditMachine: action.payload,
            };
        default:
            return state;
    }
};

export default editEmachineReducer;
