//  users.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's users state.
import { fetchWrapper } from "_helpers";

// Action types
export const GET_ALL_START = "GET_ALL_START";
export const GET_ALL_SUCCESS = "GET_ALL_SUCCESS";
export const GET_ALL_FAILURE = "GET_ALL_FAILURE";
export const SET_PROFILE_ACTIVE = "SET_PROFILE_ACTIVE";


// Action creators
export const getAllStart = () => ({
  type: GET_ALL_START,
});

export const getAllSuccess = (data) => ({
  type: GET_ALL_SUCCESS,
  payload: data,
});
export const setActiveProfile = (data) => ({
	type: SET_PROFILE_ACTIVE,
	payload: data,
  });

export const getAllFailure = (error) => ({
  type: GET_ALL_FAILURE,
  payload: error,
});

// Thunk action creator for fetching all users
export const getAll = () => async (dispatch) => {
  dispatch(getAllStart());
  try {
    const response = await fetchWrapper.get(`${process.env.REACT_APP_API_URL}/users`);
    dispatch(getAllSuccess(response));
  } catch (error) {
    dispatch(getAllFailure(error.message));
  }
};


// Initial state
const initialState = {
  users: {},
  profileActive: 'Technician',
  loading: false,
  error: null,
};

// Reducer
export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case GET_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
	  case SET_PROFILE_ACTIVE:
      return {
        ...state,
        loading: false,
		profileActive:action.payload
      };
    default:
      return state;
  }
};

