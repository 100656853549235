// custom history object to allow navigation outside react components
//  history.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file contain the custom history object to allow navigation outside react components
//
export const history = {
    navigate: null,
    location: null
};