//  Loader.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file representing the loader component of the application.
//
import React from "react";

const LoaderComponent = () => {
	return (
		<div id="loader">
			<img src={"../../images/images/media/loader.svg"} alt="" />
		</div>
	);
};

export default LoaderComponent;
