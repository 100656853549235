//  RegisterTechnician.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface for registering a technician.
//  allows users to register as technicians by providing their full name, email, and password.
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { registerUser, registrationReset } from "../../_store/register.slice";

/**
 * Component for registering a technician.
 *
 * This component allows users to register as technicians by providing their full name, email, and password.
 * It utilizes Formik for form management and Redux for state management.
 *
 * @returns {JSX.Element} RegisterTechnician component.
 */
const RegisterTechnician = () => {
	// Redux hooks for accessing state and dispatch
	const dispatch = useDispatch();
	const alertVariant = useSelector((state) => state.register.alertVariant);
	const alertMessage = useSelector((state) => state.register.alertMessage);
	const isLoading = useSelector((state) => state.register.isLoading);

	// Effect hook to reset registration state on component mount
	useEffect(() => {
		dispatch(registrationReset());
	}, []);

	/**
	 * Function to handle form submission.
	 *
	 * This function is called when the user submits the registration form.
	 * It dispatches the registerUser action with the form values.
	 *
	 * @param {Object} values - Form values.
	 * @param {Function} setSubmitting - Function to set submitting state.
	 */
	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			setSubmitting(true);
			dispatch(registerUser(values));
		} catch (error) {
			// Handle any errors
		} finally {
			setSubmitting(false);
		}
	};

	// Formik hook for form management
	const formik = useFormik({
		initialValues: {
			fullName: "",
			email: "",
			password: "",
			roleId: 3,
		},
		onSubmit: handleSubmit,
		validate: (values) => {
			const errors = {};
			if (!values.fullName) {
				errors.fullName = "Full name is required";
			}
			if (!values.email) {
				errors.email = "Email is required";
			} else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
				errors.email = "Invalid email address";
			}
			if (!values.password) {
				errors.password = "Password is required";
			}
			return errors;
		},
	});

	// JSX rendering
	return (
		<div className="container">
			<div className="col-md-6 offset-md-3 mt-5">
				<div className="card">
					<h4 className="card-header">Register Technician</h4>
					<div className="card-body">
						{alertMessage && (
							<div className={`alert ${alertVariant}`} role="alert">
								{alertMessage}
							</div>
						)}
						<form onSubmit={formik.handleSubmit}>
							<div className="mb-3">
								<label htmlFor="fullName" className="form-label">
									Full Name
								</label>
								<input
									type="text"
									id="fullName"
									name="fullName"
									className={`form-control ${formik.touched.fullName && formik.errors.fullName ? "is-invalid" : ""}`}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.fullName}
								/>
								{formik.touched.fullName && formik.errors.fullName && <div className="invalid-feedback">{formik.errors.fullName}</div>}
							</div>
							<div className="mb-3">
								<label htmlFor="email" className="form-label">
									Email
								</label>
								<input
									type="email"
									id="email"
									name="email"
									className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
								/>
								{formik.touched.email && formik.errors.email && <div className="invalid-feedback">{formik.errors.email}</div>}
							</div>
							<div className="mb-3">
								<label htmlFor="password" className="form-label">
									Password
								</label>
								<input
									type="password"
									id="password"
									name="password"
									className={`form-control ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
								/>
								{formik.touched.password && formik.errors.password && <div className="invalid-feedback">{formik.errors.password}</div>}
							</div>
							<button type="submit" className="btn btn-primary" disabled={isLoading}>
								Register <ClipLoader loading={isLoading} size={"13px"} color="white" />
							</button>
						</form>
					</div>
				</div>
				<div className="card-footer">
					<div className="mt-3">
						<p>
							Already have an account? <Link to="/login">Login</Link>
						</p>
						<p>
							Want to have an account For Customer? <Link to="/register">Register as Customer</Link>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegisterTechnician;
