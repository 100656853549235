//  customerEmachine.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's customer E-Machine state on customer Panel.
import axios from "axios";
import { logout } from "_store/login.slice";
import Swal from "sweetalert2";
import { history } from "_helpers";

// Action Types
const FETCH_CUSTOMER_EMACHINE_REQUEST = "FETCH_CUSTOMER_EMACHINE_REQUEST";
const FETCH_CUSTOMER_EMACHINE_SUCCESS = "FETCH_CUSTOMER_EMACHINE_SUCCESS";
const FETCH_CUSTOMER_EMACHINE_FAILURE = "FETCH_CUSTOMER_EMACHINE_FAILURE";
const RESET_CUSTOMER_EMACHINE_REQUEST = "RESET_CUSTOMER_EMACHINE_REQUEST";

// Action Creators
const fetchCustomerEmachineRequest = () => ({
    type: FETCH_CUSTOMER_EMACHINE_REQUEST,
});

export const resetCustomerEmachineRequest = () => ({
    type: RESET_CUSTOMER_EMACHINE_REQUEST,
});

export const fetchCustomerEmachineRequestSuccess = (emachine) => ({
    type: FETCH_CUSTOMER_EMACHINE_SUCCESS,
    payload: emachine,
});

const fetchCustomerEmachineRequestFailure = (error) => ({
    type: FETCH_CUSTOMER_EMACHINE_FAILURE,
    payload: error,
});

// Thunk Action
export const fetchCustomerEmachine = ({ pageSize, searchFilters, sortConfig, currentPage, perPage }) => {
    return async (dispatch) => {
        dispatch(fetchCustomerEmachineRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/customer/request/e-machines`, {
                params: {pageSize, sortConfig, currentPage,perPage},
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            dispatch(fetchCustomerEmachineRequestSuccess(response.data.response));
            // history.navigate('/customer/service-request-list')
            // Additional logic if needed
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(fetchCustomerEmachineRequestFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    cuatomerEmachine: null,
    loadingCustomerEmachine: false,
    errorCustomerEmachine: null,
};

// Reducer
const customerEmachineReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CUSTOMER_EMACHINE_REQUEST:
            return {
                ...state,
                loadingCustomerEmachine: true,
                errorCustomerEmachine: null,
            };
        case RESET_CUSTOMER_EMACHINE_REQUEST:
            return {
                cuatomerEmachine: null,
                loadingCustomerEmachine: false,
                errorCustomerEmachine: null,
            };
        case FETCH_CUSTOMER_EMACHINE_SUCCESS:
            return {
                ...state,
                loadingCustomerEmachine: false,
                cuatomerEmachine: action.payload,
            };
        case FETCH_CUSTOMER_EMACHINE_FAILURE:
            return {
                ...state,
                loadingCustomerEmachine: false,
                errorCustomerEmachine: action.payload,
            };
        default:
            return state;
    }
};

export default customerEmachineReducer;
