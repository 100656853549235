//  login.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's login state.
import persistedRootReducer, { history } from "_store";
import axios from "axios";
import { resetCompanyProfileRequest } from "./tehnician/company.slice";
import { resetTechnicianProfileRequest } from "./tehnician/profile.slice";

// Action Types
const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";
const LOGOUT = "LOGOUT";
const LOGIN_RESET = "LOGIN_RESET";

/**
 * Action creator to reset login state.
 * @returns {Object} Action object
 */
export const loginReset = () => ({
	type: LOGIN_RESET,
});

/**
 * Action creator for login request.
 * @returns {Object} Action object
 */
export const loginRequest = () => ({
	type: LOGIN_REQUEST,
});

/**
 * Action creator for successful login.
 * @param {Object} user - User object
 * @returns {Object} Action object
 */
export const loginSuccess = (user) => ({
	type: LOGIN_SUCCESS,
	payload: user,
});

/**
 * Action creator for login failure.
 * @param {string} error - Error message
 * @returns {Object} Action object
 */
export const loginFailure = (error) => ({
	type: LOGIN_FAILURE,
	payload: error,
});

/**
 * Action creator for user logout.
 * @returns {Object} Action object
 */
export const logout = () => ({
	type: LOGOUT,
});

/**
 * Async action creator for user login.
 * @param {Object} credentials - User credentials (email and password)
 * @returns {Function} Asynchronous action
 */
export const login = ({ email, password }) => {
	return async (dispatch) => {
		dispatch(loginReset());
		dispatch(loginRequest());
		try {
			const baseUrl = `${process.env.REACT_APP_API_URL}/api/public`;
			console.log(baseUrl);
			const response = await axios.post(`${baseUrl}/authenticate`, { email, password });

			if (response.status === 200) {
				const user = response.data;
				localStorage.setItem("user", JSON.stringify(user.response));
				dispatch(resetCompanyProfileRequest());
				dispatch(resetTechnicianProfileRequest());
				dispatch(loginSuccess(user.response));
				
				const { from } = history.location.state || { from: { pathname: "/" } };
				history.push(from);
				// window.location.href ='/'

			} else {
				dispatch(loginFailure(response.data.message));
			}
		} catch (error) {
			console.log(error?.response?.data?.message);
			dispatch(loginFailure(error?.response?.data?.message?.email || "Something went wrong!"));
		}
	};
};

// Reducer
const initialState = {
	isLoading:false,
	user: JSON.parse(localStorage.getItem("user")),
	error: null,
};

/**
 * Reducer for authentication state.
 * @param {Object} state - Current state
 * @param {Object} action - Action object
 * @returns {Object} New state
 */
export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_REQUEST:
			return {
				...state,
				isLoading:true,
				error: null,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				user: action.payload,
				error: null,
				isLoading:false,

			};
		case LOGIN_FAILURE:
			return {
				...state,
				error: action.payload,
				isLoading:false,

			};
		case LOGOUT:
			localStorage.clear();

			history.push("/login");
			return {
				...state,
				user: null,
				error: null,
			};
		case LOGIN_RESET:
			return {
				user: JSON.parse(localStorage.getItem("user")),
				error: null,
				isLoading:false,

			};
		default:
			return state;
	}
};
