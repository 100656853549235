import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./login.slice";
import { usersReducer } from "./users.slice";
import { createBrowserHistory } from "history";
import registrationReducer from "./register.slice";
import technicianProfileReducer from "./tehnician/profile.slice";
import saveTechnicianProfileReducer from "./tehnician/profileSave.slice";
import companyProfileReducer from "./tehnician/company.slice";
import saveCompanyReducer from "./tehnician/companySave.slice";
import userProfileReducer from "./admin/users.slice";
import technicianProfilePrerequisiteReducer from "./tehnician/prerequsite.slice";
import saveEMachineReducer from "./tehnician/machineSave.slice";
import machinesReducer from "./tehnician/machine.slice";
import serviceRequestReducer from "./customer/service.slice";
import saveServiceReducer from"./customer/serviceSave.slice";
import eMachineListingReducer from"./customer/eMachineListing.slice";
import adminServiceRequestReducer from "./admin/adminService.slice";
import assignTechnicianReducer from "./admin/assignTechnician.slice";
import updateServiceStatusReducer from "./customer/updateServiceStatus.slice";
import editServiceRequestReducer from "./customer/editService.slice";
import updateServiceRequestReducer from "./customer/updateService.slice";
import technicianServiceRequestReducer from "./tehnician/technicianService.slice";
import cancelStatusReducer from "./customer/updateCancelStatus.slice";
import dashboardStatsReducer from "./dashboardStats.Slice";
import customerEmachineReducer from "./customer/customerEmachine.slice";
import adminEmachineReducer from "./admin/adminEmachine.slice";
import editEmachineReducer from "./admin/editEmachine.slice";
import updateEmachineReducer from "./admin/updateEmachine.slice";
import approveEmachineReducer from "./admin/approveEmachine.slice";
export const history = createBrowserHistory();

const rootPersistConfig = {
	key: "rootss",
	storage,
};

const userPersistConfig = {
	key: "userss",
	storage,
};

// Create a persisted reducer for the userReducer
const persistedUserReducer = persistReducer(userPersistConfig, authReducer);

const persistedRootReducer = persistReducer(
	rootPersistConfig,
	combineReducers({
		auth: persistedUserReducer,
		users: usersReducer,
		register: registrationReducer,
		technician: combineReducers({
			profile: technicianProfileReducer,
			savedProfile: saveTechnicianProfileReducer,
			prerequisite: technicianProfilePrerequisiteReducer
		}),
		admin:userProfileReducer,
		company: combineReducers({
			profile: companyProfileReducer,
			savedCompany: saveCompanyReducer,
		}),
		saveEMachine: saveEMachineReducer,
		machines: machinesReducer,
		serviceRequest:serviceRequestReducer,
		saveService:saveServiceReducer,
		eMachines:eMachineListingReducer,
		adminService:adminServiceRequestReducer,
		assignTech:assignTechnicianReducer,
		serviceStatus:updateServiceStatusReducer,
		editService:editServiceRequestReducer,
		updateService:updateServiceRequestReducer,
		technicianService:technicianServiceRequestReducer,
		cancelStatus:cancelStatusReducer,
		dashboardStats:dashboardStatsReducer,
        customerEmachine:customerEmachineReducer,
		adminEmachine:adminEmachineReducer,
		editMachine:editEmachineReducer,
		updateMachine:updateEmachineReducer,
		approveMachine:approveEmachineReducer,
	})
);

export default persistedRootReducer;
