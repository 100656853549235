//  Nav.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will render navigation links based on user authentication status
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { logout } from "_store/login.slice";
import { setActiveProfile } from "_store/users.slice";

/**
 * Navigation component.
 * Renders navigation links based on user authentication status.
 */
const Nav = () => {
	// Accessing authenticated user from Redux store
	const authUser = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const activeProfile = useSelector((state) => state.users.profileActive);
	const { company } = useSelector((state) => state.company.profile);

	// Dispatching logout action when logout button is clicked
	const logoutUser = () => dispatch(logout());
	const handleProfileSwitch = () => {
		const newProfile = activeProfile === "Technician" ? "Company" : "Technician";
		dispatch(setActiveProfile(newProfile));
	};
	useEffect(() => {
		console.log({ activeProfile });
	}, [activeProfile]);
	// If user is not authenticated, do not render navigation
	if (!authUser || authUser.length <= 0) return null;

	return (
		<nav className="navbar navbar-expand navbar-dark bg-dark px-3">
			<div className="navbar-nav">
				{/* Home link */}
				<NavLink to="/" className="nav-item nav-link">
					Home
				</NavLink>

				{/* Logout button */}
				<button onClick={logoutUser} className="btn btn-link nav-item nav-link">
					Logout
				</button>
				<div style={{ display: "flex" }}>
					{authUser.roleId === "3" && (
						<NavLink to="/technician/profile" className="nav-item nav-link">
							Profile
						</NavLink>
					)}
					{authUser.roleId === "3" && ((company?.name|| authUser.profile?.Company?.id) && ( authUser.profile)) && (
						<NavLink to="/technician/company" className="nav-item nav-link">
							Company
						</NavLink>
					)}
				</div>
			</div>
		</nav>
	);
};

export { Nav };
