//  adminService.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's admin service state on admin panel.
import { logout } from "_store/login.slice";
import axios from "axios";
import Swal from "sweetalert2";

// Action Types
const FETCH_ADMIN_SERVICE_REQUEST = "FETCH_ADMIN_SERVICE_REQUEST";
const FETCH_ADMIN_SERVICE_REQUEST_SUCCESS = "FETCH_ADMIN_SERVICE_REQUEST_SUCCESS";
const FETCH_ADMIN_SERVICE_REQUEST_FAILURE = "FETCH_ADMIN_SERVICE_REQUEST_FAILURE";
const RESET_ADMIN_SERVICE_REQUEST = "RESET_ADMIN_SERVICE_REQUEST";

// Action Creators
const fetchAdminServiceRequest = () => ({
    type: FETCH_ADMIN_SERVICE_REQUEST,
});

const fetchAdminServiceRequestSuccess = (serviceRequest) => ({
    type: FETCH_ADMIN_SERVICE_REQUEST_SUCCESS,
    payload: serviceRequest,
});

const fetchAdminServiceRequestFailure = (error) => ({
    type: FETCH_ADMIN_SERVICE_REQUEST_FAILURE,
    payload: error,
});

export const resetAdminServiceRequest = () => ({
    type: RESET_ADMIN_SERVICE_REQUEST,
});

// Thunk Action
export const fetchAdminServices = ({pageSize, searchFilters, sortConfig, currentPage,perPage}) => {
    return async (dispatch) => {
        dispatch(fetchAdminServiceRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/service-requests`, {
                params: {pageSize, sortConfig, currentPage,perPage},
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            // await new Promise((resolve) => setTimeout(resolve, 4000));
            dispatch(fetchAdminServiceRequestSuccess(response.data?.response));
        } catch (error) {
            if (error.response && error.response.status === 404) {
                dispatch(fetchAdminServiceRequestSuccess(null));
            }

            if (error.response && error.response.status === 401) {
                // If the response status is 401 (Unauthorized), dispatch the logout action
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(fetchAdminServiceRequestFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    adminServiceRequest: null,
    loadingAdminService: false,
    error: null,
};

// Reducer
const adminServiceRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_SERVICE_REQUEST:
            return {
                ...state,
                loadingAdminService: true,
                error: null,
            };
        case RESET_ADMIN_SERVICE_REQUEST:
            return {
                adminServiceRequest: null,
                loadingAdminService: false,
                error: null,
            };
        case FETCH_ADMIN_SERVICE_REQUEST_SUCCESS:
            return {
                ...state,
                loadingAdminService: false,
                adminServiceRequest: action.payload,
            };
        case FETCH_ADMIN_SERVICE_REQUEST_FAILURE:
            return {
                ...state,
                loadingAdminService: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default adminServiceRequestReducer;
