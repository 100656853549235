//  Header.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file representing the Header section of the application.
//
import { history } from "_helpers";
import { logout } from "_store/login.slice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
const Header = () => {
	const dispatch = useDispatch();

	var elem = document.documentElement;
	const openFullscreen = () => {
		let open = document.querySelector(".full-screen-open");
		let close = document.querySelector(".full-screen-close");

		if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.webkitRequestFullscreen) {
				/* Safari */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) {
				/* IE11 */
				elem.msRequestFullscreen();
			}
			close.classList.add("d-block");
			close.classList.remove("d-none");
			open.classList.add("d-none");
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				/* Safari */
				document.webkitExitFullscreen();
				console.log("working");
			} else if (document.msExitFullscreen) {
				/* IE11 */
				document.msExitFullscreen();
			}
			close.classList.remove("d-block");
			open.classList.remove("d-none");
			close.classList.add("d-none");
			open.classList.add("d-block");
		}
	};
	const logoutUser = () => dispatch(logout());
	const { user: authUser } = useSelector((state) => state.auth);
	return (
		<>
			{/* app-header */}
			<header className="app-header">
				{/* Start::main-header-container */}
				<div className="main-header-container container-fluid">
					{/* Start::header-content-left */}
					<div className="header-content-left">
						{/* Start::header-element */}
						<div className="header-element">
							<div className="horizontal-logo">
								<span onClick={()=>history.navigate("/")} className="header-logo">
									LSoulutions
									{/*<img src="/images/brand-logos/desktop-logo.png" alt="logo" className="desktop-logo" />*/}
									{/*<img src="/images/brand-logos/toggle-logo.png" alt="logo" className="toggle-logo" />*/}
									{/*<img src="/images/brand-logos/desktop-white.png" alt="logo" className="desktop-dark" />*/}
									{/*<img src="/images/brand-logos/toggle-dark.png" alt="logo" className="toggle-dark" />*/}
									{/*<img src="/images/brand-logos/desktop-white.png" alt="logo" className="desktop-white" />*/}
									{/*<img src="/images/brand-logos/toggle-white.png" alt="logo" className="toggle-white" />*/}
								</span>
							</div>
						</div>
						{/* End::header-element */}
						{/* Start::header-element */}
						<div className="header-element">
							{/* Start::header-link */}
							<a aria-label="Hide Sidebar" className="sidemenu-toggle header-link animated-arrow hor-toggle horizontal-navtoggle mx-0 my-auto" data-bs-toggle="sidebar" href="javascript:void(0);">
								<span />
							</a>
							{/* End::header-link */}
						</div>
						{/* End::header-element */}
						{/* Start::header-element */}
						<div className="header-element header-search">
							{/* Start::header-link */}
							{/* <a href="javascript:void(0);" class="header-link" data-bs-toggle="modal" data-bs-target="#searchModal">
              <i class="bx bx-search-alt-2 header-link-icon"></i>
          </a> */}
							<div className="main-header-search ms-3 d-none d-lg-block my-auto">
								<input className="form-control" placeholder="Search for anything..." type="search" />{" "}
								<button className="btn">
									<i className="fe fe-search" aria-hidden="true" />
								</button>
							</div>
							{/* End::header-link */}
						</div>
						{/* End::header-element */}
					</div>
					{/* End::header-content-left */}
					{/* Start::header-content-right */}
					<div className="header-content-right">
						{/* Start::header-element */}

						{/* End::header-element */}
						{/* Start::header-element */}
						<div className="header-element header-search d-block d-lg-none">
							{/* Start::header-link */}
							<a href="javascript:void(0);" className="header-link" data-bs-toggle="modal" data-bs-target="#searchModal">
								<i className="ti ti-search header-link-icon" />
							</a>
							{/* End::header-link */}
						</div>
						{/* End::header-element */}
						{/* Start::header-element */}
						<div className="header-element header-theme-mode">
							{/* Start::header-link|layout-setting */}
							<a href="javascript:void(0);" className="header-link layout-setting">
								<span className="light-layout">
									{/* Start::header-link-icon */}
									<i className="fe fe-moonfe fe-moon header-link-icon align-middle" />
									{/* End::header-link-icon */}
								</span>
								<span className="dark-layout">
									{/* Start::header-link-icon */}
									<i className="fe fe-sun header-link-icon" />
									{/* End::header-link-icon */}
								</span>
							</a>
							{/* End::header-link|layout-setting */}
						</div>
						{/* End::header-element */}
						{/* Start::header-element */}
						<div className="header-element header-fullscreen">
							{/* Start::header-link */}
							<a onClick={()=>openFullscreen()} href="#" className="header-link">
								<i className="fe fe-maximize full-screen-open header-link-icon" />
								<i className="fe fe-minimize full-screen-close header-link-icon d-none" />
							</a>
							{/* End::header-link */}
						</div>
						{/* End::header-element */}
						{/* Start::header-element */}
						<div className="header-element notifications-dropdown">
							{/* Start::header-link|dropdown-toggle */}
							{/* <a href="javascript:void(0);" className="header-link dropdown-toggle" data-bs-auto-close="outside" data-bs-toggle="dropdown">
								<i className="fe fe-bell header-link-icon" />
								<span className="pulse-success" />
							</a> */}
							{/* End::header-link|dropdown-toggle */}
							{/* Start::main-header-dropdown */}
							<div className="main-header-dropdown dropdown-menu dropdown-menu-end" data-popper-placement="none">
								<div className="p-3">
									<div className="d-flex align-items-center justify-content-between">
										<p className="mb-0 fs-17 fw-semibold">Notifications</p>
										<span className="badge bg-success fw-normal" id="notifiation-data">
											5 Unread
										</span>
									</div>
								</div>
								<div className="dropdown-divider" />
								<ul className="list-unstyled mb-0" id="header-notification-scroll">
									<li className="dropdown-item">
										<div className="d-flex align-items-start">
											<div className="pe-2">
												<span className="avatar avatar-md bg-primary-gradient box-shadow-primary avatar-rounded">
													<i className="ri-chat-4-line fs-18" />
												</span>
											</div>
											<div className="flex-grow-1 d-flex align-items-center justify-content-between">
												<div>
													<p className="mb-0 fw-semibold">
														<a href="default-chat.html">New review received</a>
													</p>
													<span className="text-muted fw-normal fs-12 header-notification-text">2 hours ago</span>
												</div>
												<div>
													<a href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close2">
														<i className="ti ti-x fs-16" />
													</a>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start">
											<div className="pe-2">
												<span className="avatar avatar-md bg-secondary-gradient box-shadow-primary avatar-rounded">
													<i className="ri-mail-line fs-18" />
												</span>
											</div>
											<div className="flex-grow-1 d-flex align-items-center justify-content-between">
												<div>
													<p className="mb-0 fw-semibold">
														<a href="default-chat.html">New Mails Received</a>
													</p>
													<span className="text-muted fw-normal fs-12 header-notification-text">1 week ago</span>
												</div>
												<div>
													<a href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close2">
														<i className="ti ti-x fs-16" />
													</a>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start">
											<div className="pe-2">
												<span className="avatar avatar-md bg-success-gradient box-shadow-primary avatar-rounded">
													<i className="ri-shopping-cart-line fs-18" />
												</span>
											</div>
											<div className="flex-grow-1 d-flex align-items-center justify-content-between">
												<div>
													<p className="mb-0 fw-semibold">
														<a href="default-chat.html">New Order Received</a>
													</p>
													<span className="text-muted fw-normal fs-12 header-notification-text">1 day ago</span>
												</div>
												<div>
													<a href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close2">
														<i className="ti ti-x fs-16" />
													</a>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start">
											<div className="pe-2">
												<span className="avatar avatar-md bg-warning-gradient box-shadow-primary avatar-rounded">
													<i className="ri-refresh-fill fs-18" />
												</span>
											</div>
											<div className="flex-grow-1 d-flex align-items-center justify-content-between">
												<div>
													<p className="mb-0 fw-semibold">
														<a href="default-chat.html">New Updates available</a>
													</p>
													<span className="text-muted fw-normal fs-12 header-notification-text">1 day ago</span>
												</div>
												<div>
													<a href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close2">
														<i className="ti ti-x fs-16" />
													</a>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start">
											<div className="pe-2">
												<span className="avatar avatar-md bg-info-gradient box-shadow-primary avatar-rounded">
													<i className="ri-shopping-bag-fill fs-18" />
												</span>
											</div>
											<div className="flex-grow-1 d-flex align-items-center justify-content-between">
												<div>
													<p className="mb-0 fw-semibold">
														<a href="default-chat.html">New Order Placed</a>
													</p>
													<span className="text-muted fw-normal fs-12 header-notification-text">1 day ago</span>
												</div>
												<div>
													<a href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close2">
														<i className="ti ti-x fs-16" />
													</a>
												</div>
											</div>
										</div>
									</li>
								</ul>
								<div className="p-3 empty-header-item1 border-top">
									<div className="d-grid">
										<a href="default-chat.html" className="btn text-muted p-0 border-0">
											View all Notification
										</a>
									</div>
								</div>
								<div className="p-5 empty-item1 d-none">
									<div className="text-center">
										<span className="avatar avatar-xl avatar-rounded bg-secondary-transparent">
											<i className="ri-notification-off-line fs-2" />
										</span>
										<h6 className="fw-semibold mt-3">No New Notifications</h6>
									</div>
								</div>
							</div>
							{/* End::main-header-dropdown */}
						</div>
						{/* End::header-element */}
						{/* Start::header-element */}
						<div className="header-element meassage-dropdown d-none d-xl-block">
							{/* Start::header-link|dropdown-toggle */}
							{/* <a href="javascript:void(0);" className="header-link dropdown-toggle" data-bs-auto-close="outside" data-bs-toggle="dropdown">
								<i className="fe fe-message-square header-link-icon" />
								<span className="pulse-danger" />
							</a> */}
							{/* End::header-link|dropdown-toggle */}
							{/* Start::main-header-dropdown */}
							<div className="main-header-dropdown dropdown-menu dropdown-menu-end" data-popper-placement="none">
								<div className="p-3">
									<div className="d-flex align-items-center justify-content-between">
										<p className="mb-0 fs-17 fw-semibold">You have Messages</p>
										<span className="badge bg-success fw-normal" id="message-data">
											5 Unread
										</span>
									</div>
								</div>
								<div className="dropdown-divider" />
								<ul className="list-unstyled mb-0" id="header-notification-scroll1">
									<li className="dropdown-item">
										<div className="d-flex align-items-start">
											<div className="pe-2">
												<img src="/images/faces/1.jpg" alt="img" className="rounded-circle avatar" />
											</div>
											<div className="flex-grow-1 d-flex align-items-center justify-content-between">
												<div>
													<p className="mb-0 fw-semibold">
														<a href="default-chat.html">
															Madeleine
															<span className="text-muted fs-12 fw-normal ps-1 d-inline-block"> 3 hours ago </span>
														</a>
													</p>
													<span className="text-muted fw-normal fs-12 header-notification-text">Hey! there I' am available....</span>
												</div>
												<div>
													<a href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close3">
														<i className="ti ti-x fs-16" />
													</a>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start">
											<div className="pe-2">
												<img src="/images/faces/2.jpg" alt="img" className="rounded-circle avatar" />
											</div>
											<div className="flex-grow-1 d-flex align-items-center justify-content-between">
												<div>
													<p className="mb-0 fw-semibold">
														<a href="default-chat.html">
															Anthony
															<span className="text-muted fs-12 fw-normal ps-1 d-inline-block"> 5 hours ago </span>
														</a>
													</p>
													<span className="text-muted fw-normal fs-12 header-notification-text">New product Launching...</span>
												</div>
												<div>
													<a href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close3">
														<i className="ti ti-x fs-16" />
													</a>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start">
											<div className="pe-2">
												<img src="/images/faces/3.jpg" alt="img" className="rounded-circle avatar" />
											</div>
											<div className="flex-grow-1 d-flex align-items-center justify-content-between">
												<div>
													<p className="mb-0 fw-semibold">
														<a href="default-chat.html">
															Olivia
															<span className="text-muted fs-12 fw-normal ps-1 d-inline-block">45 mintues ago </span>
														</a>
													</p>
													<span className="text-muted fw-normal fs-12 header-notification-text">New Schedule release......</span>
												</div>
												<div>
													<a href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close3">
														<i className="ti ti-x fs-16" />
													</a>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start">
											<div className="pe-2">
												<img src="/images/faces/4.jpg" alt="img" className="rounded-circle avatar" />
											</div>
											<div className="flex-grow-1 d-flex align-items-center justify-content-between">
												<div>
													<p className="mb-0 fw-semibold">
														<a href="default-chat.html">
															Sanderson
															<span className="text-muted fs-12 fw-normal ps-1 d-inline-block">20 mintues ago </span>
														</a>
													</p>
													<span className="text-muted fw-normal fs-12 header-notification-text">New Schedule release......</span>
												</div>
												<div>
													<a href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close3">
														<i className="ti ti-x fs-16" />
													</a>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start">
											<div className="pe-2">
												<img src="/images/faces/2.jpg" alt="img" className="rounded-circle avatar" />
											</div>
											<div className="flex-grow-1 d-flex align-items-center justify-content-between">
												<div>
													<p className="mb-0 fw-semibold">
														<a href="default-chat.html">
															Senrio
															<span className="text-muted fs-12 fw-normal ps-1 d-inline-block"> 10 hours ago </span>
														</a>
													</p>
													<span className="text-muted fw-normal fs-12 header-notification-text">New product Launching...</span>
												</div>
												<div>
													<a href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close3">
														<i className="ti ti-x fs-16" />
													</a>
												</div>
											</div>
										</div>
									</li>
								</ul>
								<div className="p-3 empty-header-item1 border-top">
									<div className="d-grid">
										<a href="default-chat.html" className="btn text-muted p-0 border-0">
											See all Messages
										</a>
									</div>
								</div>
								<div className="p-5 empty-item1 d-none">
									<div className="text-center">
										<span className="avatar avatar-xl avatar-rounded bg-secondary-transparent">
											<i className="ri-notification-off-line fs-2" />
										</span>
										<h6 className="fw-semibold mt-3">No New Notifications</h6>
									</div>
								</div>
							</div>
							{/* End::main-header-dropdown */}
						</div>
						{/* End::header-element */}
						{/* Start::header-element */}
						<div className="header-element cart-dropdown">
							{/* Start::header-link|dropdown-toggle */}
							{/* <a href="javascript:void(0);" className="header-link dropdown-toggle" data-bs-auto-close="outside" data-bs-toggle="dropdown">
								<i className="fe fe-shopping-cart header-link-icon" />
								<span className="badge rounded-pill bg-success cart-badge fw-normal" id="cart-icon-badge">
									5
								</span>
							</a> */}
							{/* End::header-link|dropdown-toggle */}
							{/* Start::main-header-dropdown */}
							<div className="main-header-dropdown dropdown-menu dropdown-menu-end" data-popper-placement="none">
								<div className="p-3">
									<div className="d-flex align-items-center justify-content-between">
										<p className="mb-0 fs-17 fw-semibold">Cart Items</p>
										<span className="badge bg-success fw-normal" id="cart-data">
											5 Items
										</span>
									</div>
								</div>
								<div>
									<hr className="dropdown-divider" />
								</div>
								<ul className="list-unstyled mb-0" id="header-cart-items-scroll">
									<li className="dropdown-item">
										<div className="d-flex align-items-start cart-dropdown-item">
											<img src="/images/pngs/1.png" alt="img" className="avatar avatar-sm avatar-rounded br-5 me-3" />
											<div className="flex-grow-1">
												<div className="d-flex align-items-start justify-content-between mb-0">
													<div className="mb-0 fs-13 text-dark fw-semibold">
														<a href="cart.html">Cup Set</a>
													</div>
													<div>
														<span className="text-black mb-1">$1,299.00</span>
														<a href="javascript:void(0);" className="header-cart-remove float-end dropdown-item-close">
															<i className="ti ti-trash" />
														</a>
													</div>
												</div>
												<div className="min-w-fit-content d-flex align-items-start justify-content-between">
													<ul className="header-product-item d-flex">
														<li>White Set</li>
													</ul>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start cart-dropdown-item">
											<img src="/images/pngs/3.png" alt="img" className="avatar avatar-sm avatar-rounded br-5 me-3" />
											<div className="flex-grow-1">
												<div className="d-flex align-items-start justify-content-between mb-0">
													<div className="mb-0 fs-13 text-dark fw-semibold">
														<a href="cart.html">Wired headset</a>
													</div>
													<div>
														<span className="text-black mb-1">$179.29</span>
														<a href="javascript:void(0);" className="header-cart-remove float-end dropdown-item-close">
															<i className="ti ti-trash" />
														</a>
													</div>
												</div>
												<div className="min-w-fit-content d-flex align-items-start justify-content-between">
													<ul className="header-product-item">
														<li>Analog</li>
													</ul>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start cart-dropdown-item">
											<img src="/images/pngs/5.png" alt="img" className="avatar avatar-sm avatar-rounded br-5 me-3" />
											<div className="flex-grow-1">
												<div className="d-flex align-items-start justify-content-between mb-0">
													<div className="mb-0 fs-13 text-dark fw-semibold">
														<a href="cart.html">modern Chair</a>
													</div>
													<div>
														<span className="text-black mb-1">$29.00</span>
														<a href="javascript:void(0);" className="header-cart-remove float-end dropdown-item-close">
															<i className="ti ti-trash" />
														</a>
													</div>
												</div>
												<div className="min-w-fit-content d-flex align-items-start justify-content-between">
													<ul className="header-product-item d-flex">
														<li>Decorative</li>
													</ul>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start cart-dropdown-item">
											<img src="/images/pngs/4.png" alt="img" className="avatar avatar-sm avatar-rounded br-5 me-3" />
											<div className="flex-grow-1">
												<div className="d-flex align-items-start justify-content-between mb-0">
													<div className="mb-0 fs-13 text-dark fw-semibold">
														<a href="cart.html">Flower Vase</a>
													</div>
													<div>
														<span className="text-black mb-1">$4,999.00</span>
														<a href="javascript:void(0);" className="header-cart-remove float-end dropdown-item-close">
															<i className="ti ti-trash" />
														</a>
													</div>
												</div>
												<div className="min-w-fit-content d-flex align-items-start justify-content-between">
													<ul className="header-product-item d-flex">
														<li>Decorative</li>
													</ul>
												</div>
											</div>
										</div>
									</li>
									<li className="dropdown-item">
										<div className="d-flex align-items-start cart-dropdown-item">
											<img src="/images/pngs/6.png" alt="img" className="avatar avatar-sm avatar-rounded br-5 me-3" />
											<div className="flex-grow-1">
												<div className="d-flex align-items-start justify-content-between mb-0">
													<div className="mb-0 fs-13 text-dark fw-semibold">
														<a href="cart.html">Sun Glasses</a>
													</div>
													<div>
														<span className="text-black mb-1">$129.00</span>
														<a href="javascript:void(0);" className="header-cart-remove float-end dropdown-item-close">
															<i className="ti ti-trash" />
														</a>
													</div>
												</div>
												<div className="d-flex align-items-start justify-content-between">
													<ul className="header-product-item d-flex">
														<li>Brown</li>
													</ul>
												</div>
											</div>
										</div>
									</li>
								</ul>
								<div className="p-3 empty-header-item border-top">
									<div className="d-grid">
										<a href="checkout.html" className="btn text-muted p-0 border-0">
											Proceed to checkout
										</a>
									</div>
								</div>
								<div className="p-5 empty-item d-none">
									<div className="text-center">
										<span className="avatar avatar-xl avatar-rounded bg-warning-transparent">
											<i className="ri-shopping-cart-2-line fs-2" />
										</span>
										<h6 className="fw-bold mb-1 mt-3">Your Cart is Empty</h6>
										<span className="mb-3 fw-normal fs-13 d-block">Add some items to make me happy :)</span>
										<span className="btn btn-primary btn-wave btn-sm m-1" data-abc="true">
											continue shopping <i className="bi bi-arrow-right ms-1" />
										</span>
									</div>
								</div>
							</div>
							{/* End::main-header-dropdown */}
						</div>
						{/* End::header-element */}
						{/* Start::header-element */}
						<div className="header-element profile-1">
							{/* Start::header-link|dropdown-toggle */}
							<a href="#" className=" dropdown-toggle leading-none d-flex px-1" id="mainHeaderProfile" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
								<div className="d-flex align-items-center">
									<div className="">
										<img src="/images/faces/9.jpg" alt="img" className="rounded-circle avatar  profile-user brround cover-image" />
									</div>
								</div>
							</a>
							{/* End::header-link|dropdown-toggle */}
							<ul className="main-header-dropdown dropdown-menu pt-0 overflow-hidden header-profile-dropdown dropdown-menu-end" aria-labelledby="mainHeaderProfile">
								
								<li onClick={() => logoutUser()}>
									<a className="dropdown-item d-flex">
										<i className="ti ti-logout fs-18 me-2 op-7" />
										Log Out
									</a>
								</li>
							</ul>
						</div>
						{/* End::header-element */}
						{/* Start::header-element */}
						<div className="header-element d-none d-sm-block">
							{/* Start::header-link|switcher-icon */}
							<a href="#" className="header-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvassidebar">
								<i className="fe fe-menu header-link-icon" />
							</a>
							{/* End::header-link|switcher-icon */}
						</div>
						{/* End::header-element */}
						{/* Start::header-element */}
						{/* <div className="header-element">
							<a href="#" className="header-link switcher-icon" data-bs-toggle="offcanvas" data-bs-target="#switcher-canvas">
								<i className="fe fe-settings header-link-icon" />
							</a>
						</div> */}
						{/* End::header-element */}
					</div>
					{/* End::header-content-right */}
				</div>
				{/* End::main-header-container */}
			</header>
			{/* /app-header */}
		</>
	);
};

export default Header;
