//  dashboardStats.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's dashboard stats state.
import { logout } from "_store/login.slice";
import axios from "axios";
import Swal from "sweetalert2";

// Action Types
const FETCH_DASHBOARD_STATS_REQUEST = "FETCH_DASHBOARD_STATS_REQUEST";
const FETCH_DASHBOARD_STATS__SUCCESS = "FETCH_DASHBOARD_STATS__SUCCESS";
const FETCH_DASHBOARD_STATS__FAILURE = "FETCH_DASHBOARD_STATS__FAILURE";
const RESET_DASHBOARD_STATS_ = "RESET_DASHBOARD_STATS_";

// Action Creators
const fetchDashboardStatsRequest = () => ({
    type: FETCH_DASHBOARD_STATS_REQUEST,
});

const fetchDashboardStatsSuccess = (stats) => ({
    type: FETCH_DASHBOARD_STATS__SUCCESS,
    payload: stats,
});

const fetchDashboardStatsFailure = (error) => ({
    type: FETCH_DASHBOARD_STATS__FAILURE,
    payload: error,
});

export const resetDashboardStats = () => ({
    type: RESET_DASHBOARD_STATS_,
});

// Thunk Action
export const fetchDashboardStats = () => {
    return async (dispatch) => {
        dispatch(fetchDashboardStatsRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/users/stats`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            dispatch(fetchDashboardStatsSuccess(response.data?.response));
        } catch (error) {
            if (error.response && error.response.status === 404) {
                dispatch(fetchDashboardStatsSuccess(null));
            }

            if (error.response && error.response.status === 401) {
                // If the response status is 401 (Unauthorized), dispatch the logout action
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(fetchDashboardStatsFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    stats: null,
    loadingStats: false,
    error: null,
};

// Reducer
const dashboardStatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DASHBOARD_STATS_REQUEST:
            return {
                ...state,
                loadingStats: true,
                error: null,
            };
        case RESET_DASHBOARD_STATS_:
            return {
                stats: null,
                loadingStats: false,
                error: null,
            };
        case FETCH_DASHBOARD_STATS__SUCCESS:
            return {
                ...state,
                loadingStats: false,
                stats: action.payload,
            };
        case FETCH_DASHBOARD_STATS__FAILURE:
            return {
                ...state,
                loadingStats: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default dashboardStatsReducer;
