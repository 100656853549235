//  Register.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface for user registration,

//  allowing users to sign up with their full name, email, and password.
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { registerUser, registrationReset } from "../../_store/register.slice";

/**
 * Register component for user registration.
 *
 * Displays a registration form allowing users to sign up with their full name, email, and password.
 *
 * @returns {JSX.Element} Register component
 */
const Register = () => {
	const dispatch = useDispatch();

	// Redux selectors
	const alertVariant = useSelector((state) => state.register.alertVariant);
	const alertMessage = useSelector((state) => state.register.alertMessage);
	const isLoading = useSelector((state) => state.register.isLoading);

	/**
	 * Reset registration state on component mount.
	 */
	useEffect(() => {
		dispatch(registrationReset());
	}, []);

	/**
	 * Handle form submission.
	 *
	 * Dispatches registerUser action with form values.
	 *
	 * @param {object} values - Form values
	 * @param {object} setSubmitting - Form submission status
	 */
	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			setSubmitting(true);
			dispatch(registerUser(values));
		} catch (error) {
			// Handle error
		} finally {
			setSubmitting(false);
		}
	};

	// Formik configuration
	const formik = useFormik({
		initialValues: {
			fullName: "",
			email: "",
			password: "",
			roleId: 4,
		},
		onSubmit: handleSubmit,
		validate: (values) => {
			const errors = {};
			if (!values.fullName) {
				errors.fullName = "Full name is required";
			}
			if (!values.email) {
				errors.email = "Email is required";
			} else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
				errors.email = "Invalid email address";
			}
			if (!values.password) {
				errors.password = "Password is required";
			}
			return errors;
		},
	});

	// JSX rendering
	return (
		<div className="container">
			<div className="col-md-6 offset-md-3 mt-5">
				<div className="card">
					<h4 className="card-header">Register</h4>
					<div className="card-body">
						{/* Display alert message */}
						{alertMessage && (
							<div className={`alert ${alertVariant}`} role="alert">
								{alertMessage}
							</div>
						)}
						<form onSubmit={formik.handleSubmit}>
							{/* Full Name input */}
							<div className="mb-3">
								<label htmlFor="fullName" className="form-label">
									Full Name
								</label>
								<input
									type="text"
									id="fullName"
									name="fullName"
									className={`form-control ${formik.touched.fullName && formik.errors.fullName ? "is-invalid" : ""}`}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.fullName}
								/>
								{/* Display Full Name error */}
								{formik.touched.fullName && formik.errors.fullName && <div className="invalid-feedback">{formik.errors.fullName}</div>}
							</div>
							{/* Email input */}
							<div className="mb-3">
								<label htmlFor="email" className="form-label">
									Email
								</label>
								<input
									type="email"
									id="email"
									name="email"
									className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
								/>
								{/* Display Email error */}
								{formik.touched.email && formik.errors.email && <div className="invalid-feedback">{formik.errors.email}</div>}
							</div>
							{/* Password input */}
							<div className="mb-3">
								<label htmlFor="password" className="form-label">
									Password
								</label>
								<input
									type="password"
									id="password"
									name="password"
									className={`form-control ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
								/>
								{/* Display Password error */}
								{formik.touched.password && formik.errors.password && <div className="invalid-feedback">{formik.errors.password}</div>}
							</div>
							{/* Submit button */}
							<button type="submit" className="btn btn-primary" disabled={isLoading}>
								Register <ClipLoader loading={isLoading} size={"13px"} color="white" />
							</button>
						</form>
					</div>
				</div>
				{/* Footer */}
				<div className="card-footer">
					<div className="mt-3">
						{/* Link to Login page */}
						<p>
							Already have an account? <Link to="/login">Login</Link>
						</p>
						{/* Link to Technician registration page */}
						<p>
							Want to have an account For Technician? <Link to="/register/technician">Register as Technician</Link>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Register;
