//  updateCancelStatus.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store's update cancel status state on customer panel.
import axios from "axios";
import { logout } from "_store/login.slice";
import Swal from "sweetalert2";
import {fetchServices} from "./service.slice";
import { history } from "_helpers";

// Action Types
const CANCEL_STATUS_REQUEST = "CANCEL_STATUS_REQUEST";
const CANCEL_STATUS_SUCCESS = "CANCEL_STATUS_SUCCESS";
const CANCEL_STATUS_FAILURE = "CANCEL_STATUS_FAILURE";
const RESET_CANCEL_STATUS_REQUEST = "RESET_CANCEL_STATUS_REQUEST";

// Action Creators
const updateServiceStatusRequest = () => ({
    type: CANCEL_STATUS_REQUEST,
});

export const resetUpdateServiceStatusRequest= () => ({
    type: RESET_CANCEL_STATUS_REQUEST,
});

export const updateServiceStatusSuccess = (service) => ({
    type: CANCEL_STATUS_SUCCESS,
    payload: service,
});

const updateServiceStatusFailure = (error) => ({
    type: CANCEL_STATUS_FAILURE,
    payload: error,
});

// Thunk Action
export const updateCancelStatus = (serviceData) => {
    return async (dispatch) => {
        dispatch(updateServiceStatusRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/customer/service-requests/cancel`, serviceData, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            dispatch(updateServiceStatusRequest(response.data.response));

            Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 3000,
            });
            history.navigate('/customer/service-request-list')
            // Additional logic if needed
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(updateServiceStatusFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    cancelServiceStatus: null,
    loadingCancelServiceStatus: false,
    errorCancelServiceStatus: null,
};

// Reducer
const cancelStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case CANCEL_STATUS_REQUEST:
            return {
                ...state,
                loadingCancelServiceStatus: true,
                errorServiceStatus: null,
            };
        case RESET_CANCEL_STATUS_REQUEST:
            return {
                cancelServiceStatus: null,
                loadingCancelServiceStatus: false,
                errorCancelServiceStatus: null,
            };
        case CANCEL_STATUS_SUCCESS:
            return {
                ...state,
                loadingCancelServiceStatus: false,
                cancelServiceStatus: action.payload,
            };
        case CANCEL_STATUS_FAILURE:
            return {
                ...state,
                loadingCancelServiceStatus: false,
                errorCancelServiceStatus: action.payload,
            };
        default:
            return state;
    }
};

export default cancelStatusReducer;
