//  editService.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store's edit service state on customer Panel.
import { logout } from "_store/login.slice";
import axios from "axios";
import Swal from "sweetalert2";
import { history } from "_helpers";

// Action Types
const FETCH_EDIT_SERVICE_REQUEST = "FETCH_EDIT_SERVICE_REQUEST";
const FETCH_EDIT_SERVICE_REQUEST_SUCCESS = "FETCH_EDIT_SERVICE_REQUEST_SUCCESS";
const FETCH_EDIT_SERVICE_REQUEST_FAILURE = "FETCH_EDIT_SERVICE_REQUEST_FAILURE";
const RESET_EDIT_SERVICE_REQUEST = "RESET_EDIT_SERVICE_REQUEST";

// Action Creators
const fetchEditServiceRequest = () => ({
    type: FETCH_EDIT_SERVICE_REQUEST,
});

const fetchEditServiceRequestSuccess = (serviceRequest) => ({
    type: FETCH_EDIT_SERVICE_REQUEST_SUCCESS,
    payload: serviceRequest,
});

const fetchEditServiceRequestFailure = (error) => ({
    type: FETCH_EDIT_SERVICE_REQUEST_FAILURE,
    payload: error,
});

export const resetEditServiceRequest = () => ({
    type: RESET_EDIT_SERVICE_REQUEST,
});

// Thunk Action
export const editServices = ({serviceData}) => {
    return async (dispatch) => {
        dispatch(fetchEditServiceRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/customer/service-requests/request-details?requestId=`+serviceData.requestId, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            await new Promise((resolve) => setTimeout(resolve, 4000));
            dispatch(fetchEditServiceRequestSuccess(response.data?.response));

        } catch (error) {
            if (error.response && error.response.status === 404) {
                dispatch(fetchEditServiceRequestSuccess(null));
            }

            if (error.response && error.response.status === 401) {
                // If the response status is 401 (Unauthorized), dispatch the logout action
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(fetchEditServiceRequestFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    editServiceRequest: null,
    loadingEditService: false,
    error: null,
};

// Reducer
const editServiceRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EDIT_SERVICE_REQUEST:
            return {
                ...state,
                editServiceRequest:null,
                loadingEditService: true,
                error: null,
            };
        case RESET_EDIT_SERVICE_REQUEST:
            return {
                editServiceRequest: null,
                loadingEditService: false,
                error: null,
            };
        case FETCH_EDIT_SERVICE_REQUEST_SUCCESS:
            return {
                ...state,
                loadingEditService: false,
                editServiceRequest: action.payload,
            };
        case FETCH_EDIT_SERVICE_REQUEST_FAILURE:
            return {
                ...state,
                loadingEditService: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default editServiceRequestReducer;
