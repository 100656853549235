//  approval.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's approve profile state on admin panel.
import { logout } from "_store/login.slice";
import axios from "axios";
import Swal from "sweetalert2";
import { fetchUserProfile, resetUserProfile } from "./users.slice";

// Action Types
const APPROVE_PROFILE_REQUEST = "APPROVE_PROFILE_REQUEST";
const APPROVE_PROFILE_SUCCESS = "APPROVE_PROFILE_SUCCESS";
const APPROVE_PROFILE_FAILURE = "APPROVE_PROFILE_FAILURE";

// Action Creators
const approveProfileRequest = () => ({
	type: APPROVE_PROFILE_REQUEST,
});

const approveProfileSuccess = () => ({
	type: APPROVE_PROFILE_SUCCESS,
});

const approveProfileFailure = (error) => ({
	type: APPROVE_PROFILE_FAILURE,
	payload: error,
});

// Thunk Action
export const approveProfile = (profile, perPage, currentPage,sortConfig) => {
	return async (dispatch) => {
		dispatch(approveProfileRequest());
		const user = JSON.parse(localStorage.getItem("user"));
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/admin/users/technician/approve`, {
				params: { technicianId: profile?.Technician?.id, approved: !profile?.Technician?.isApproved },
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			});
			dispatch(approveProfileSuccess());
			dispatch(resetUserProfile());
			dispatch(
				fetchUserProfile({
					currentPage,
					perPage,
					sortConfig,
				})
			);
			// Dispatch any additional actions if needed
		} catch (error) {
			if (error.response && error.response.status === 401) {
				// If the response status is 401 (Unauthorized), dispatch the logout action
				Swal.fire({
					icon: "error",
					title: "You have been logged out! Please login again",
					timer: 3000,
				});
				dispatch(logout());
			} else {
				dispatch(approveProfileFailure(error.message));
			}
		}
	};
};

// Initial State
const initialState = {
	loadingApprove: false,
	error: null,
};

// Reducer
const approveProfileReducer = (state = initialState, action) => {
	switch (action.type) {
		case APPROVE_PROFILE_REQUEST:
			return {
				...state,
				loadingApprove: true,
				error: null,
			};
		case APPROVE_PROFILE_SUCCESS:
			return {
				...state,
				loadingApprove: false,
			};
		case APPROVE_PROFILE_FAILURE:
			return {
				...state,
				loadingApprove: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default approveProfileReducer;
