//  AdminLayout.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file as a wrapper for the main content of the Admin Application,
//  including the navigation bar and sidebar.
import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { history } from "_helpers";
import { login, loginReset } from "_store/login.slice";
import SwitcherComponent from "partials/Switcher";
import Header from "partials/Header";
import PageHeader from "partials/PageHeader";
import LoaderComponent from "partials/Loader";
import SideBarComponent from "partials/Sidebar";
import Footer from "partials/Footer";
import SideBarOffCanvas from "partials/SideBarOffCanvas";
const AdminLayout = ({ children }) => {
	const { user: authUser } = useSelector((x) => x.auth);

	return (
		<>
			<SwitcherComponent />
			<LoaderComponent />
			<div className={`page ${!authUser && "d-none"}`}>
				<Header />
				<SideBarComponent />
				{/* Start::app-content */}
				<div className="main-content app-content">
					<div className="container-fluid">
						
						{children}
					</div>
				</div>
				{/* End::app-content */}

				<Footer />
				<SideBarOffCanvas />
			</div>
			<div className={`${authUser && "d-none"}`}>{children}</div>
		</>
	);
};

export default AdminLayout;
