//  PrivateRoute.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will use for  user private routes
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { history } from "_helpers";
import Layout from "./Layouts/Layout";

export { PrivateRoute };

function PrivateRoute({ children }) {
	const { user: authUser } = useSelector((x) => x.auth);
	
	if (!authUser) {
		return <Navigate to="/login" state={{ from: history.location }} />;
	}
	return<Layout>{(authUser && !authUser?.roleId == 4) || !authUser?.roleId == 3 ? <h1>Not Allowed</h1> : children}</Layout>	;
}
