//  service.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store's service request state on customer panel.
import { logout } from "_store/login.slice";
import axios from "axios";
import Swal from "sweetalert2";

// Action Types
const FETCH_SERVICE_REQUEST = "FETCH_SERVICE_REQUEST";
const FETCH_SERVICE_REQUEST_SUCCESS = "FETCH_SERVICE_REQUEST_SUCCESS";
const FETCH_SERVICE_REQUEST_FAILURE = "FETCH_SERVICE_REQUEST_FAILURE";
const RESET_SERVICE_REQUEST = "RESET_SERVICE_REQUEST";

// Action Creators
const fetchServiceRequest = () => ({
    type: FETCH_SERVICE_REQUEST,
});

const fetchServiceRequestSuccess = (serviceRequest) => ({
    type: FETCH_SERVICE_REQUEST_SUCCESS,
    payload: serviceRequest,
});

const fetchServiceRequestFailure = (error) => ({
    type: FETCH_SERVICE_REQUEST_FAILURE,
    payload: error,
});

export const resetServiceRequest = () => ({
    type: RESET_SERVICE_REQUEST,
});

// Thunk Action
export const fetchServices = ({pageSize, searchFilters, sortConfig, currentPage,perPage}) => {
    return async (dispatch) => {
        dispatch(fetchServiceRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/customer/service-requests`, {
                params: {pageSize, sortConfig, currentPage,perPage},
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            // await new Promise((resolve) => setTimeout(resolve, 4000));
            dispatch(fetchServiceRequestSuccess(response.data?.response));
        } catch (error) {
            if (error.response && error.response.status === 404) {
                dispatch(fetchServiceRequestSuccess(null));
            }

            if (error.response && error.response.status === 401) {
                // If the response status is 401 (Unauthorized), dispatch the logout action
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(fetchServiceRequestFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    serviceRequest: null,
    loadingService: false,
    error: null,
};

// Reducer
const serviceRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SERVICE_REQUEST:
            return {
                ...state,
                loadingService: true,
                error: null,
            };
        case RESET_SERVICE_REQUEST:
            return {
                serviceRequest: null,
                loadingService: false,
                error: null,
            };
        case FETCH_SERVICE_REQUEST_SUCCESS:
            return {
                ...state,
                loadingService: false,
                serviceRequest: action.payload,
            };
        case FETCH_SERVICE_REQUEST_FAILURE:
            return {
                ...state,
                loadingService: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default serviceRequestReducer;
