//  eMachineListing.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store's E-Machine listing state on customer Panel.
import axios from "axios";
import { logout } from "_store/login.slice";
import Swal from "sweetalert2";
import { history } from "_helpers";

// Action Types
const FETCH_EMACHINE_REQUEST = "FETCH_EMACHINE_REQUEST";
const FETCH_EMACHINE_SUCCESS = "FETCH_EMACHINE_SUCCESS";
const FETCH_EMACHINE_FAILURE = "FETCH_EMACHINE_FAILURE";
const RESET_EMACHINE_REQUEST = "RESET_EMACHINE_REQUEST";

// Action Creators
const fetchEmachineRequest = () => ({
    type: FETCH_EMACHINE_REQUEST,
});

export const resetEmachineRequest = () => ({
    type: RESET_EMACHINE_REQUEST,
});

export const fetchEmachineRequestSuccess = (emachine) => ({
    type: FETCH_EMACHINE_SUCCESS,
    payload: emachine,
});

const fetchEmachineRequestFailure = (error) => ({
    type: FETCH_EMACHINE_FAILURE,
    payload: error,
});

// Thunk Action
export const eMachineListing = () => {
    return async (dispatch) => {
        dispatch(fetchEmachineRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/customer/e-machines`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            dispatch(fetchEmachineRequestSuccess(response.data.response));
            // history.navigate('/customer/service-request-list')
            // Additional logic if needed
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else {
                dispatch(fetchEmachineRequestFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    emachine: null,
    loadingEmachine: false,
    errorEmachine: null,
};

// Reducer
const eMachineListingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EMACHINE_REQUEST:
            return {
                ...state,
                loadingEmachine: true,
                errorEmachine: null,
            };
        case RESET_EMACHINE_REQUEST:
            return {
                emachine: null,
                loadingEmachine: false,
                errorEmachine: null,
            };
        case FETCH_EMACHINE_SUCCESS:
            return {
                ...state,
                loadingEmachine: false,
                emachine: action.payload,
            };
        case FETCH_EMACHINE_FAILURE:
            return {
                ...state,
                loadingEmachine: false,
                errorEmachine: action.payload,
            };
        default:
            return state;
    }
};

export default eMachineListingReducer;
