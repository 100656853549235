//  Footer.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file representing the footer section of the application.
//

import React from "react";

const Footer = () => {
	const currentYear = new Date().getFullYear();

	return (
		<footer className="footer mt-auto py-3 bg-white text-center">
			<div className="container">
				<span className="text-muted">
					Copyright © <span id="year">{currentYear}</span>{" "}
					<a href="javascript:void(0);" className="text-dark fw-semibold">
						LSolutions
					</a>
					. All rights reserved
				</span>
			</div>
		</footer>
	);
};

export default Footer;
