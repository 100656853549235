//  EmachineRegisterTheme.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to Create E-Machine
import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ClipLoader } from "react-spinners";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import "../../styles.custom.css";
import Select from "react-select";
import { saveEMachine } from "_store/tehnician/machineSave.slice";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import PageHeader from "partials/PageHeader";

const EMachineRegisterTheme = ({ machine: { savedEMachine, loadingSaveEMachine, errorSaveEMachine }, saveEMachine }) => {
	const operatingSystems = [
		{ label: "Windows", value: "Windows" },
		{ label: "macOS", value: "macOS" },
		{ label: "Linux", value: "Linux" },
		{ label: "Android", value: "Android" },
		{ label: "iOS", value: "iOS" },
		{ label: "Chrome OS", value: "Chrome OS" },
		{ label: "Ubuntu", value: "Ubuntu" },
		{ label: "Debian", value: "Debian" },
		{ label: "Fedora", value: "Fedora" },
		{ label: "Red Hat Enterprise Linux", value: "Red Hat Enterprise Linux" },
		{ label: "CentOS", value: "CentOS" },
		{ label: "Arch Linux", value: "Arch Linux" },
		{ label: "OpenSUSE", value: "OpenSUSE" },
		{ label: "FreeBSD", value: "FreeBSD" },
		{ label: "NetBSD", value: "NetBSD" },
		{ label: "OpenBSD", value: "OpenBSD" },
		{ label: "Unix", value: "Unix" },
		{ label: "Solaris", value: "Solaris" },
		{ label: "AIX", value: "AIX" },
		{ label: "HP-UX", value: "HP-UX" },
		{ label: "IBM i", value: "IBM i" },
		{ label: "RTOS", value: "RTOS" },
		{ label: "QNX", value: "QNX" },
		{ label: "VxWorks", value: "VxWorks" },
		{ label: "Embedded Linux", value: "Embedded Linux" },
		{ label: "Windows Embedded", value: "Windows Embedded" },
	];

	const initialValues = {
		deviceName: "",
		serialNumber: "",
		model: "",
		manufacturer: "",
		purchaseDate: null,
		warrantyExpiryDate: null,
		description: "",
		macAddress: "",
		location: "",
		operatingSystem: "",
	};

	const validationSchema = Yup.object().shape({
		deviceName: Yup.string().required("Device Name is required"),
		serialNumber: Yup.string().required("Serial Number is required"),
		model: Yup.string().required("Model is required"),
		manufacturer: Yup.string().required("Manufacturer is required"),
		purchaseDate: Yup.date().nullable().required("Purchase Date is required"),
		warrantyExpiryDate: Yup.date().nullable().required("Warranty Expiry Date is required"),
		description: Yup.string().required("Description is required"),
		macAddress: Yup.string().required("MAC Address is required"),
		location: Yup.string().required("Location is required"),
		operatingSystem: Yup.object().required("Operating System is required"),
	});

	const handleSubmit = (values, { setSubmitting }) => {
		// setLoading(true);
		// Your submission logic goes here
		// You can dispatch an action to save the e-machine data
		saveEMachine({ ...values, operatingSystem: values.operatingSystem.value });
		setSubmitting(false);
	};

	return (
		<>
			{/* Render the navigation component */}
			{/* <Nav /> */}
			<PageHeader title={"Create E Machine"} subtitle={"E Machine"} />

			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
				{({ isSubmitting, errors, touched, values, setFieldValue, setFieldTouched }) => (
					<Form>
						<div className="row">
							<div className="col-xl-4 col-md-12 col-sm-12">
								<div className="card custom-card edit-password-section">
									<div className="card-header">
										<div className="card-title">Device Information</div>
									</div>
									<div className="card-body">
										<div className="form-group mb-3">
											<label className="form-label" htmlFor="deviceName">Device Name</label>
											<Field type="text" name="deviceName" className={`form-control ${touched.deviceName && errors.deviceName ? "is-invalid mb-2" : ""}`} />
											<ErrorMessage name="deviceName" component="div" className="invalid-feedback" />
										</div>
										<div className="form-group mb-3">
											<label className="form-label" htmlFor="serialNumber">Serial Number</label>
											<Field type="text" name="serialNumber" className={`form-control ${touched.serialNumber && errors.serialNumber ? "is-invalid mb-2" : ""}`} />
											<ErrorMessage name="serialNumber" component="div" className="invalid-feedback" />
										</div>
										<div className="form-group mb-3">
											<label className="form-label" htmlFor="model">Model</label>
											<Field type="text" name="model" className={`form-control ${touched.model && errors.model ? "is-invalid mb-2" : ""}`} />
											<ErrorMessage name="model" component="div" className="invalid-feedback" />
										</div>
										<div className="form-group mb-3">
											<label className="form-label" htmlFor="manufacturer">Manufacturer</label>
											<Field type="text" name="manufacturer" className={`form-control ${touched.manufacturer && errors.manufacturer ? "is-invalid mb-2" : ""}`} />
											<ErrorMessage name="manufacturer" component="div" className="invalid-feedback" />
										</div>
									</div>
								</div>
								<div className="card custom-card panel-theme">
									<div className="card-header">
										<div className="float-start">
											<h3 className="card-title">Date Section</h3>
										</div>
										<div className="clearfix" />
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<label className="form-label" htmlFor="purchaseDate">Purchase Date</label> <br />
													<DatePicker
														name="purchaseDate"
														selected={values.purchaseDate}
														onChange={(date) => setFieldValue("purchaseDate", date)}
														className={`form-control ${touched.purchaseDate && errors.purchaseDate ? "is-invalid" : ""}`}
													/>
													<ErrorMessage name="purchaseDate" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label className="form-label" htmlFor="warrantyExpiryDate">Warranty Expiry Date</label>
													<br />
													<DatePicker
														name="warrantyExpiryDate"
														selected={values.warrantyExpiryDate}
														onChange={(date) => setFieldValue("warrantyExpiryDate", date)}
														className={`form-control ${touched.warrantyExpiryDate && errors.warrantyExpiryDate ? "is-invalid" : ""}`}
													/>
													<ErrorMessage name="warrantyExpiryDate" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-8 col-md-12 col-sm-12">
								<div className="card custom-card panel-theme">
									<div className="card-header">
										<div className="float-start">
											<h3 className="card-title">Location Section</h3>
										</div>
										<div className="clearfix" />
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<label className="form-label" htmlFor="macAddress">MAC Address</label>
													<Field type="text" name="macAddress" className={`form-control ${touched.macAddress && errors.macAddress ? "is-invalid" : ""}`} />
													<ErrorMessage name="macAddress" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label className="form-label" htmlFor="location">Location</label>
													<Field type="text" name="location" className={`form-control ${touched.location && errors.location ? "is-invalid" : ""}`} />
													<ErrorMessage name="location" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card custom-card">
									<div className="card-header">
										<h3 className="card-title">OS & Description Section</h3>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<label className="form-label" htmlFor="operatingSystem">Operating System</label>
													<Select
														className={`${touched.operatingSystem && errors.operatingSystem ? "is-invalid" : ""}`}
														placeholder="Select Operating System"
														name="operatingSystem"
														options={operatingSystems}
														classNamePrefix="react-select"
														onChange={(selectedOption) => {
															console.log(selectedOption);
															setFieldValue("operatingSystem", selectedOption);
														}}
														value={operatingSystems.find((option) => option.value === values.operatingSystem)}
													/>
													<ErrorMessage name="operatingSystem" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="form-group">
														<label className="form-label" htmlFor="description">Description</label>
														<Field as="textarea" name="description" className={`form-control ${touched.description && errors.description ? "is-invalid" : ""}`} />
														<ErrorMessage name="description" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="card-footer text-end">
										<button type="submit" className="btn btn-success" disabled={isSubmitting || loadingSaveEMachine}>
											{loadingSaveEMachine ? <ClipLoader loading={loadingSaveEMachine} size={10} /> : "Submit"}
										</button>
									</div>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

const mapStateToProps = (state) => ({
	machine: state.saveEMachine, // Assuming the user data is stored in state.admin.user
});

const mapDispatchToProps = {
	saveEMachine,
};

export default connect(mapStateToProps, mapDispatchToProps)(EMachineRegisterTheme);
