//  createMachine.slice.js
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will relates to a specific set of data and actions within the store 's create machine state on customer Panel.
import axios from "axios";
import { logout } from "_store/login.slice";
import Swal from "sweetalert2";
import { history } from "_helpers";

// Action Types
const CREATE_MACHINE_REQUEST = "CREATE_MACHINE_REQUEST";
const CREATE_MACHINE_SUCCESS = "CREATE_MACHINE_SUCCESS";
const CREATE_MACHINE_FAILURE = "CREATE_MACHINE_FAILURE";
const RESET_CREATE_MACHINE_REQUEST = "RESET_CREATE_MACHINE_REQUEST";

// Action Creators
const createMachineRequest = () => ({
    type: CREATE_MACHINE_REQUEST,
});

export const resetCreateMachineeSaved = () => ({
    type: RESET_CREATE_MACHINE_REQUEST,
});

export const createMachineSuccess = (machine) => ({
    type: CREATE_MACHINE_SUCCESS,
    payload: machine,
});

const createMachineFailure = (error) => ({
    type: CREATE_MACHINE_FAILURE,
    payload: error,
});

// Thunk Action
export const createMachine = (machineData) => {
    return async (dispatch) => {
        dispatch(createMachineRequest());
        const user = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/customer/e-machine/create`, machineData, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            dispatch(createMachineSuccess(response.data.response));
            Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 3000,
            });
            // history.navigate("/customer/service-request-list");
            // Additional logic if needed
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "You have been logged out! Please login again",
                    timer: 3000,
                });
                dispatch(logout());
            } else if (error.response && error.response.status === 403) {
                Swal.fire({
                    icon: "error",
                    title: "Machine with this name already exists",
                    timer: 3000,
                });
            } else {
                dispatch(createMachineFailure(error.message));
            }
        }
    };
};

// Initial State
const initialState = {
    createMachine: null,
    loadingCreateMachine: false,
    errorCreateMachine: null,
};

// Reducer
const createMachineReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_MACHINE_REQUEST:
            return {
                ...state,
                loadingCreateMachine: true,
                errorCreateMachine: null,
            };
        case RESET_CREATE_MACHINE_REQUEST:
            return {
                createMachine: null,
                loadingCreateMachine: false,
                errorCreateMachine: null,
            };
        case CREATE_MACHINE_SUCCESS:
            return {
                ...state,
                loadingCreateMachine: false,
                createMachine: action.payload,
            };
        case CREATE_MACHINE_FAILURE:
            return {
                ...state,
                loadingCreateMachine: false,
                errorCreateMachine: action.payload,
            };
        default:
            return state;
    }
};

export default createMachineReducer;
