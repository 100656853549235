//  editServiceRequest.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface for customer to manage service request listing.
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { history } from "_helpers";
import PageHeader from "../../../partials/PageHeader";
import Swal from "sweetalert2";
import { eMachineListing, resetEmachineRequest } from "../../../_store/customer/eMachineListing.slice";
import { editServices, resetEditServiceRequest } from "../../../_store/customer/editService.slice";
import { updateServices, resetUpdateServiceRequest } from "../../../_store/customer/updateService.slice";
import { updateCancelStatus } from "../../../_store/customer/updateCancelStatus.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../partials/Pagination";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { saveService } from "../../../_store/customer/serviceSave.slice";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { ClipLoader } from "react-spinners";
import * as Yup from "yup";
import { useParams, useLocation, Link } from "react-router-dom";
import { City, State } from "country-state-city";
const EditServiceRequest = ({
	service: { savedService, loadingSaveService, errorSaveService },
	saveService,
	eMachineListing,
	eMachines,
	editServices,
	editService,
	updateServices,
	updateCancelStatus,
	cancelStatus,
	updateService,
    isLoadingData,
	updateLoadingData
}) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const queryParams = new URLSearchParams(location.search);
	const params = useParams();

// Initializes service data with the requestId parameter provided in the URL
useEffect(() => {
    editServices({ serviceData: { requestId: parseInt(params.id) } }); //[Warning] Parameters should be clearly named for better readability
}, []);


	const [newEMachine, setNewEMachine] = useState([]);

	// Function is mapping states of a country to label, value, and other properties
	const updatedStates = (countryId) => State.getStatesOfCountry(countryId).map((state) => ({ label: state.name, value: state.isoCode, ...state }));

	// Function is mapping cities of a state to label, value, and other properties
	const updatedCities = (countryId, stateId) => {
		const cities = City.getCitiesOfState(countryId, stateId).map((city) => ({ label: city.name, value: city.name, ...city }));
		return cities;
	};

	useEffect(() => {
		// Call eMachineListing function
		eMachineListing();
		return () => {
			// Reset eMachineRequest
			resetEmachineRequest();
		};
	}, [eMachineListing]);

	useEffect(() => {
		return () => {
			// Reset edit service request
			resetEditServiceRequest();
		};
	}, [editServices]);

	useEffect(() => {
		console.log();
	}, [editService]);
	useEffect(() => {
		// Logging the machines from eMachines before formatting
		console.log(eMachines?.emachine?.machines); // Retrieving and mapping devices from eMachines into newEMachine state
		const formattedDevices = eMachines?.emachine?.machines.map((device) => ({
			label: device.deviceName,
			value: device.id,
		}));

		setNewEMachine(formattedDevices);
	}, [eMachines]);

	const initialValues = {
		// Setting initial values for form fields
		eMachineId: editService?.EMachine?.id ? {  label: editService.EMachine.deviceName, value: editService.EMachine.id, } : { label: "", value: "", },
		description: editService?.description,
		state: editService && editService?.state ? updatedStates("US").filter((state) => state.isoCode == editService?.state)[0] : "",
		city:
			editService && editService?.city
				? updatedCities("US", updatedStates("US").filter((state) => state.isoCode == editService?.state)[0].value).filter((city) => city.name == editService?.city)[0]
				: "",
		zipCode: editService?.zipCode,
	};

// Form validation schema using Yup
	const validationSchema = Yup.object().shape({
		eMachineId: Yup.object().required("E Machine selection is required"),
		state: Yup.object().required("State is required"),
		city: Yup.object().required("City is required"),
		zipCode: Yup.number().required("ZipCode is required"),
		description: Yup.string().required("Problem Description is required"),
	});

	const handleSubmit = (values) => {
		// Update services with extracted values from the input fields
		updateServices({ ...values, eMachineId: values.eMachineId.value, state: values.state.value, city: values.city.value, requestId: parseInt(params.id) });
	};

	const cancelRequest = (e) => {
		// Prevent default form submission and update cancel status with request ID
		e.preventDefault();
		updateCancelStatus({ requestId: parseInt(params.id) });
	};

	return (
		<>
			<PageHeader title={"Edit Service Request"} subtitle={"Service"} />

			{/* Main content wrapper */}
			<div className="content-wrapper">
				{/* Content header */}

				{/* Users list */}
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
									{({ isSubmitting, errors, touched, values, setFieldValue, setFieldTouched }) => (
										<Form>
											<div className="row">
												<div className="col-lg-12 col-md-12 col-sm-12">
													<div className="card custom-card edit-password-section">
														<div className="card-header">
															<div className="card-title">Service Request</div>
														</div>
														{editService?.id && (
															<div className="card-body">
																<div className="row">
																	<div className="col-lg-3">
																		<div className="form-group mb-3">
																			<label className="form-label" htmlFor="eMachineId">
																				E-Machine
																			</label>
																			<Select
																				className={`${touched.eMachineId && errors.eMachineId ? "is-invalid" : ""}`}
																				placeholder="Select E-Machine"
																				name="eMachineId"
																				options={newEMachine}
																				classNamePrefix="react-select"
																				onChange={(selectedOption) => {
																					console.log(selectedOption);
																					setFieldValue("eMachineId", selectedOption);
																				}}
																				value={newEMachine.find((option) => option.value === values.eMachineId.value)}
																			/>
																			<ErrorMessage name="eMachineId" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
																		</div>
																	</div>
																	<div className="col-lg-9">
																		<div className="row">
																			<div className="col-lg-4">
																				<div className="form-group">
																					<label htmlFor="state" className="form-label">
																						{" "}
																						State
																					</label>

																					<Select
																						id="state"
																						name="state"
																						options={updatedStates("US")}
																						value={values.state}
																						onChange={(value) => {
																							setFieldValue("state", value);
																						}}
																					/>
																					<ErrorMessage name="state" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
																				</div>
																			</div>
																			<div className="col-lg-4">
																				<div className="form-group">
																					<label htmlFor="city" className="form-label">
																						{" "}
																						City
																					</label>
																					<Select
																						id="city"
																						name="city"
																						options={updatedCities("US", values.state ? values.state.value : null)}
																						value={values.city}
																						onChange={(value) => setFieldValue("city", value)}
																					/>{" "}
																					<ErrorMessage name="city" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
																				</div>
																			</div>
																			<div className="col-lg-4">
																				<div className="form-group">
																					<label htmlFor="zipCode" className="form-label">
																						Zip Code
																					</label>
																					<Field type="text" name="zipCode" className={`form-control ${touched.zipCode && errors.zipCode ? "is-invalid mb-2" : ""}`} />
																					<ErrorMessage name="zipCode" component="div" className="invalid-feedback" />
																				</div>
																			</div>
																		</div>
																		{/*<div className="form-group mb-3">*/}
																		{/*    <label className="form-label" htmlFor="location">Location</label>*/}
																		{/*    <Field type="text" name="location" className={`form-control ${touched.location && errors.location ? "is-invalid mb-2" : ""}`} />*/}
																		{/*    <ErrorMessage name="location" component="div" className="invalid-feedback" />*/}
																		{/*</div>*/}
																	</div>
																</div>
																<div className="row">
																	<div className="col-lg-12">
																		<div className="form-group mb-3">
																			<label className="form-label" htmlFor="problem">
																				Problem Description{" "}
																			</label>
																			<Field type="text" as="textarea" name="description" className={`form-control ${touched.description && errors.description ? "is-invalid mb-2" : ""}`} />
																			<ErrorMessage name="description" component="div" className="invalid-feedback" />
																		</div>
																	</div>
																</div>
																<button type="submit" className="btn btn-success generate-btn" disabled={isSubmitting || updateLoadingData}>
																	<ClipLoader loading={updateLoadingData} size={10} /> Update
																</button>
																&nbsp;{" "}
																<button
																	type="submit"
																	className="btn btn-danger"
																	onClick={(e) => {
																		cancelRequest(e);
																	}}>
																	Cancel Request
																</button>
																{/*<button type="submit" className="btn btn-success">*/}
																{/*    Submit*/}
																{/*</button>*/}
															</div>
														)}
                                                        {!editService?.id && 
                                                        <div className="card-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                                                                    <ClipLoader loading={isLoadingData}/>

                                                        </div>
                                                        }
													</div>
												</div>
											</div>
										</Form>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	service: state.saveService, // Assuming the data is stored in state.saveService
	eMachines: state.eMachines, // Assuming the emachines data is stored in state.emachines
	editService: state.editService?.editServiceRequest, // Assuming the edit service data is stored in state.editService
	cancelStatus: state.cancelStatus, // Assuming the cancel service data is stored in state.cancelStatus
    isLoadingData :state.editService?.loadingEditService,
	updateService:state.updateService,
    updateLoadingData :state.updateService?.loadingUpdateService
});

const mapDispatchToProps = {
	saveService,
	eMachineListing,
	editServices,
	updateServices,
	updateCancelStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditServiceRequest);
