//  DashboardTheme.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to manage client and technician dashboard.
import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {useSelector, useDispatch, connect} from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {fetchDashboardStats,resetDashboardStats} from "../_store/dashboardStats.Slice";
import { history } from "_helpers";
import { login, loginReset } from "_store/login.slice";
import SwitcherComponent from "partials/Switcher";
import Header from "partials/Header";
import PageHeader from "partials/PageHeader";
import LoaderComponent from "partials/Loader";
import SideBarComponent from "partials/Sidebar";
import Footer from "partials/Footer";
import SideBarOffCanvas from "partials/SideBarOffCanvas";
import {ClipLoader} from "react-spinners";

const DashboardTheme = ({ fetchDashboardStats, resetDashboardStats,dashboardStats,isLoadingData}) => {
	const { user: authUser } = useSelector((x) => x.auth);
	useEffect(() => {
		// console.log();
		fetchDashboardStats();
		return () => {
			// Reset user service request state when component unmounts
			resetDashboardStats();
		};
	}, [fetchDashboardStats]);
	useEffect(() => {

	}, [dashboardStats]);

	return (
		<>
		{authUser.roleId ==3 &&<PageHeader title={"Technician Dashboard"} subtitle={"Home"} />}
		{authUser.roleId ==4 &&<PageHeader title={"Customer Dashboard"} subtitle={"Home"} />}

		{authUser && <h2>Welcome {authUser?.fullName} to your dashboard!</h2>}
			{isLoadingData ? (
				<div className="text-center">
					<ClipLoader  size={50} loading={true} />
				</div>
			) : (
				<>
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
							<div class="row total-sales-card-section">
								<div class="col-lg-6 col-md-6 col-sm-12 col-xl-4">
									<div class="card custom-card overflow-hidden">
										<div class="card-body">
											<div class="row">
												<div class="col">
													<h6 class="fw-normal fs-14">Number of service requests completed.</h6>
													<h3 class="mb-2 number-font fs-24">{dashboardStats?.stats?.completedCount ? dashboardStats?.stats?.completedCount : 0 }</h3>
													{/*<p class="text-muted mb-0">*/}
													{/*        <span class="text-primary">*/}
													{/*            <i*/}
													{/*				class="ri-arrow-up-s-line bg-primary text-white rounded-circle fs-13 p-0 fw-semibold align-bottom"></i>*/}
													{/*            3%</span> last month*/}
													{/*</p>*/}
												</div>
												<div class="col col-auto mt-2">
													<div
														class="counter-icon bg-primary-gradient box-shadow-primary rounded-circle ms-auto mb-0">
														<i class="las la-list-alt mb-5 "></i>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-6 col-md-6 col-sm-12 col-xl-4">
									<div class="card custom-card overflow-hidden">
										<div class="card-body">
											<div class="row">
												<div class="col">
													<h6 class="fw-normal fs-14">Number of service requests in progress. </h6>
													<h3 class="mb-2 number-font fs-24">{dashboardStats?.stats?.inProgressCount ? dashboardStats?.stats?.inProgressCount : 0 }</h3>
													{/*<p class="text-muted mb-0">*/}
													{/*        <span class="text-secondary">*/}
													{/*            <i*/}
													{/*				class="ri-arrow-up-s-line bg-secondary text-white rounded-circle fs-13 p-0 fw-semibold align-bottom"></i>*/}
													{/*            3%</span> last month*/}
													{/*</p>*/}
												</div>
												<div class="col col-auto mt-2">
													<div
														class="counter-icon bg-danger-gradient box-shadow-danger rounded-circle  ms-auto mb-0">
														<i class="las la-tasks mb-5 "></i>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>


							</div>
						</div>
					</div>
				</>
			)}

		</>
	);
};

const mapStateToProps = (state) => ({
	dashboardStats:state.dashboardStats,
	isLoadingData:state.dashboardStats?.loadingStats,
});

const mapDispatchToProps = {
	fetchDashboardStats,
	resetDashboardStats,

};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTheme);