//  App.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This is the main component of your application.
//
//  It contains the structure and logic for your app's user interface.

import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

import { history } from "_helpers";
import { Nav, PrivateRoute, PrivateRouteAdmin } from "_components";
import { Home } from "home";
import Login from "./_components/auth/Login";
import Register from "./_components/auth/Register";
// import "bootstrap/dist/css/bootstrap.min.css";
import RegisterTechnician from "_components/auth/RegisterTechnician";
import ActivateAccount from "_components/auth/ActivateAccount";
import Dashboard from "_components/admin/DashboardAdmin";
import UserMangement from "_components/admin/UserMangement";
import TechnicianProfile from "_components/technician/TechnicianProfile";
import Company from "_components/technician/Company";
import React, { useEffect } from "react";
import EMachineManagement from "_components/admin/EMachine/EMachineManagement";
import EMachineRegister from "_components/admin/EMachine/EMachineRegister";
import DashboardTheme from "_components/DashboardTheme";
import LoginTheme from "_components/auth/LoginTheme";
import RegisterTheme from "_components/auth/RegisterTheme";
import RegisterTechnicianTheme from "_components/auth/RegisterTechnicianTheme";
import Layout from "_components/Layouts/Layout";
import TechnicianProfileTheme from "_components/technician/TechnicianProfileTheme";
import CompanyTheme from "_components/technician/CompanyTheme";
import DashboardAdmin from "_components/admin/DashboardAdmin";
import UserManagementTheme from "_components/admin/UserManagementTheme";
import EMachineManagementTheme from "_components/admin/EMachine/EMachineManagementTheme";
import EMachineRegisterTheme from "_components/admin/EMachine/EMachineRegisterTheme";
import ServiceRequestList from "./_components/customer/serviceRequest/serviceRequestList";
import GenerateServiceRequest from "./_components/customer/generateServiceRequest/generateServicerequest";
import ServiceRequestListing from"./_components/admin/servicesRequestListing/serviceRequestListing";
import EditServiceRequest from"./_components/customer/editServiceRequest/editServiceRequest";
import TechnicianServiceRequestListing from"./_components/technician/technicianServiceRequestListing";
import AdminEmachineRequest from "./_components/admin/adminEmachineRequest/adminEmachineRequest";
import CustomerEmachineRequest from "./_components/customer/customerEmachineRequest/customerEmachineRequest";
import EditEmachineRequest from "./_components/admin/editEmachineRequest/editEmachineRequest";
export { App };

function App() {
	// init custom history object to allow navigation from
	// anywhere in the react app (inside or outside components)
	history.navigate = useNavigate();
	history.location = useLocation();
	useEffect(() => {
		if (process.env.REACT_APP_API_URL !== "http://localhost:3000") {
			const noop = () => {}; // Define a no-operation function
			console.log = noop; // Override console.log with noop
			console.error = noop; // Override console.error with noop
			console.warn = noop; // Override console.warn with noop
			console.info = noop; // Override console.info with noop
			console.debug = noop; // Override console.debug with noop
		}
	}, []);

	return (
		<div>
			<div>
				<Routes>
					<Route
						path="/"
						element={
							<PrivateRoute>
								<DashboardTheme />
							</PrivateRoute>
						}
					/>
					<Route
						path="/admin"
						element={
							<PrivateRoute>
								<DashboardAdmin/>
							</PrivateRoute>
						}
					/>
					<Route
						path="/technician/profile/old"
						element={
							<PrivateRoute>
								<TechnicianProfile />
							</PrivateRoute>
						}
					/>
					<Route
						path="/technician/profile"
						element={
							<PrivateRoute>
								<TechnicianProfileTheme />
							</PrivateRoute>
						}
					/>
					<Route
						path="/technician/company/old"
						element={
							<PrivateRoute>
								<Company />
							</PrivateRoute>
						}
					/>
					<Route
						path="/technician/company"
						element={
							<PrivateRoute>
								<CompanyTheme />
							</PrivateRoute>
						}
					/>
					<Route
						path="/technician/service-request"
						element={
							<PrivateRoute>
								<TechnicianServiceRequestListing />
							</PrivateRoute>
						}
					/>
					<Route
						exact={true}
						path="/admin/users/old"
						element={
							<PrivateRouteAdmin>
								<UserMangement />
							</PrivateRouteAdmin>
						}
					/>
					<Route
						exact={true}
						path="/dashboard/users"
						element={
							<PrivateRouteAdmin>
								<UserManagementTheme/>
							</PrivateRouteAdmin>
						}
					/>
					<Route
						exact={true}
						path="/dashboard/e-machine/old"
						element={
							<PrivateRouteAdmin>
								<EMachineManagement />
							</PrivateRouteAdmin>
						}
					/>
					<Route
						exact={true}
						path="/dashboard/e-machine"
						element={
							<PrivateRouteAdmin>
								<EMachineManagementTheme/>
							</PrivateRouteAdmin>
						}
					/>
					<Route
						exact={true}
						path="/dashboard/e-machine/register/old"
						element={
							<PrivateRouteAdmin>
								<EMachineRegister />
							</PrivateRouteAdmin>
						}
					/>
					<Route
						exact={true}
						path="/dashboard/e-machine/register"
						element={
							<PrivateRouteAdmin>
								<EMachineRegisterTheme />
							</PrivateRouteAdmin>
						}
					/>
					<Route
						exact={true}
						path="/dashboard/service-request-listing"
						element={
							<PrivateRouteAdmin>
								<ServiceRequestListing />
							</PrivateRouteAdmin>
						}
					/>
					<Route
						exact={true}
						path="/dashboard/e-machine-request/:id"
						element={
							<PrivateRouteAdmin>
								<EditEmachineRequest />
							</PrivateRouteAdmin>
						}
					/>
					<Route
						exact={true}
						path="/dashboard/e-machine-request-listing"
						element={
							<PrivateRouteAdmin>
								<AdminEmachineRequest />
							</PrivateRouteAdmin>
						}
					/>
					<Route
						exact={true}
						path="/dashboard"
						element={
							<PrivateRouteAdmin>
								<Dashboard />
							</PrivateRouteAdmin>
						}
					/>
					<Route
						exact={true}
						path="/customer/service-request-list"
						element={
							<PrivateRoute>
								<ServiceRequestList />
							</PrivateRoute>
						}
					/>
					<Route
						exact={true}
						path="/customer/generate-service-request"
						element={
							<PrivateRoute>
								<GenerateServiceRequest/>
							</PrivateRoute>
						}
					/>
					<Route
						exact={true}
						path="/customer/e-machine-request"
						element={
							<PrivateRoute>
								<CustomerEmachineRequest/>
							</PrivateRoute>
						}
					/>
					<Route
						exact={true}
						path="/customer/service-request-list/:id"
						element={
							<PrivateRoute>
								<EditServiceRequest/>
							</PrivateRoute>
						}
					/>

					<Route path="/login/old" element={<Login />} />
					<Route path="/register/old" element={<Register />} />
					<Route path="/register/technician/old" element={<RegisterTechnician />} />

					<Route path="/theme" exact={true} element={<DashboardTheme />} />
					<Route
						path="/login"
						element={
							<Layout>
								<LoginTheme />
							</Layout>
						}
					/>
					<Route path="/register" element={<RegisterTheme />} />
					<Route path="/register/technician" element={<RegisterTechnicianTheme />} />
					<Route path="/activate/:id" element={<ActivateAccount />} />
					{/* <Route path="*" element={<Navigate to="/" />} /> */}
				</Routes>
			</div>
		</div>
	);
}
