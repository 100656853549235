//  PageHeader.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file representing the Header section of the application based on user authentication.
//
import React, { useEffect } from "react";

const PageHeader = ({ title, subtitle, approved = 'not-allowed' }) => {

	return (
		<div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
			<div className="">
				<h1 className="page-title fw-semibold fs-20 mb-0">{title}</h1>
				<div className="">
					<nav>
						<ol className="breadcrumb mb-0">
							<li className="breadcrumb-item">
								<a href="#">{subtitle}</a>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								{title}
							</li>
						</ol>
					</nav>
				</div>
			</div>
			{/* <div className="ms-auto pageheader-btn">
				<button type="button" className="btn btn-primary btn-wave waves-effect waves-light me-2">
					{" "}
					<i className="fe fe-plus mx-1 align-middle"></i>Add Account
				</button>
				<button type="button" className="btn btn-success btn-wave waves-effect waves-light">
					{" "}
					<i className="fe fe-log-in mx-1 align-middle"></i>Export
				</button>
			</div> */}

			{approved !='not-allowed' && approved && (
					<span class="badge bg-outline-primary custom-badge fs-15  m-2">
						<i class="ti ti-user me-1"></i>Approved
					</span>
			)}
			{approved !='not-allowed' &&approved != null && !approved && (
				<span class="badge bg-outline-danger custom-badge fs-15 m-2">
					<i class="ti ti-user me-1"></i>Not Approved
				</span>
			)}
		</div>
	);
};

export default PageHeader;
