//  companyTheme.jsx
//
//  Created by Transdata on 5/3/24
//
//  Copyright © 2024 Transdata Inc. All rights reserved.
//
//  This file will provide the interface to create company
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Nav } from "_components";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { fetchCompanyProfile } from "_store/tehnician/company.slice";
import { ClipLoader } from "react-spinners";
import { resetCompanySaved, saveCompany } from "_store/tehnician/companySave.slice";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../styles.custom.css";
import Select from "react-select";
import { State, City } from "country-state-city";
import { fetchTechnicianProfilePrerequisite } from "_store/tehnician/prerequsite.slice";
import CreatableSelect from "react-select/creatable";
import PageHeader from "partials/PageHeader";

/**
 * Company component
 * @returns JSX.Element
 */
const CompanyTheme = () => {
	const dispatch = useDispatch();
	const { user: authUser } = useSelector((state) => state.auth);
	const { company, loadingCompany } = useSelector((state) => state.company.profile);
	const { savedCompany } = useSelector((state) => state.company);
	const [newEMachine, setNewEMachine] = useState([]);
	const [createEMachine, setCreateEMachine] = useState([]);
	const {
		prerequisite: { data, loadingPrerequisite },
	} = useSelector((state) => state.technician);
	const {
		profile: { profile },
	} = useSelector((state) => state.technician);

// Fetches technician profile prerequisite on component mount
// and company profile based on profile data
	useEffect(() => {
		console.log({ profile });
		dispatch(fetchTechnicianProfilePrerequisite());
		dispatch(fetchCompanyProfile(profile?.technicianProfile || authUser?.profile));
	}, []);

// Updates new eMachine data when prerequisite data changes
	useEffect(() => {
		console.log(data, "company");
		setNewEMachine(data?.eMachine);
	}, [data]);

// Logs company information when it changes
	useEffect(() => {
		console.log({ company });
	}, [company]);

	// Mapping states to label and value
	const updatedStates = (countryId) => State.getStatesOfCountry(countryId).map((state) => ({ label: state.name, value: state.isoCode, ...state }));

// Mapping cities to label and value
	const updatedCities = (countryId, stateId) => {
		const cities = City.getCitiesOfState(countryId, stateId).map((city) => ({ label: city.name, value: city.name, ...city }));
		return cities;
	};

	const initialValues = {
		// Setting initial values for form fields using company data or empty string
		name: company?.name || "",
		phoneNo: company?.phoneNo || "",
		email: company?.email || "",
		website: company?.website || "",
		owner: company?.owner || "",
		address: company?.address || "",
		serviceType: company?.serviceType || "",
		state: company && company.state ? JSON.parse(company.state) : "", // Parsing state if present in company
		city: company && company.city ? JSON.parse(company.city) : "", // Parsing city if present in company
		zipCodes: company?.zipCodes || "",
		machineExpertise: company?.machineExpertise.map((id) => parseInt(id, 10)) || [2], // Converting machine expertise IDs to integers
	};

	const validationSchema = Yup.object().shape({
		// Validating form fields using Yup schema
		name: Yup.string().required("Name is required"),
		phoneNo: Yup.string().required("Phone number is required"),
		email: Yup.string().email("Invalid email").required("Email is required"),
		website: Yup.string().url("Invalid URL").required("Website URL is required"),
		owner: Yup.string().required("Owner name is required"),
		address: Yup.string().required("Address is required"),
		serviceType: Yup.string().required("Service type is required"),
		state: Yup.object().required("State is required"),
		city: Yup.object().required("City is required"),
		zipCodes: Yup.number().required("ZipCode is required"),
		machineExpertise: Yup.array().min(1, "Select at least one machine expertise"), // Validating machine expertise array
	});

	const handleSubmit = (values, { setSubmitting }) => {
		// Save company profile information with technicianId and machines
		dispatch(saveCompany({ ...values, technicianId: profile?.technicianProfile?.id, machines: createEMachine }));
		setSubmitting(false);
	};

	useEffect(() => {
		// Display success message when company profile is saved
		if (savedCompany?.savedCompany) {
			Swal.fire({
				icon: "success",
				title: "Company Profile Saved Successfully",
				timer: 3000,
			});
			dispatch(resetCompanySaved());
		}
	}, [savedCompany]);

	const options = [
		{ value: "machine1", label: "Machine 1" },
		{ value: "machine2", label: "Machine 2" },
		{ value: "machine3", label: "Machine 3" },
	];

	const stateOptions = [
		{ value: "state1", label: "State 1" },
		{ value: "state2", label: "State 2" },
		{ value: "state3", label: "State 3" },
	];
	return (
		<>
			{/* Render the navigation component */}
			{/* <Nav /> */}
			<PageHeader title={"Company Profile"} subtitle={"Profile"} approved={company?.isApproved} />

			<div>
				{loadingCompany && (
					<div className="loader-container">
						<div className="loader-wrapper">
							<ClipLoader loading={loadingCompany} size={100} color="white" />
						</div>
					</div>
				)}

				{!loadingCompany && (
					<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
						{({ isSubmitting, errors, touched, values, setFieldValue, setFieldTouched }) => (
							<Form>
												{console.log(newEMachine.filter((option) => values?.machineExpertise.includes(option.value)),'filtere')}
								<div className="row">
									<div className="col-xl-4 col-md-12 col-sm-12">
										<div className="card custom-card edit-password-section">
											<div className="card-header">
												<div className="card-title">Company Info</div>
											</div>
											<div className="card-body">
												<div className="d-flex mb-3">
													<img alt="User Avatar" className="rounded-circle avatar-lg avatar me-2" src="../images/faces/8.jpg" />
												</div>
												<div className="form-group mb-3">
													<label htmlFor="name" className="form-label">
														Name
													</label>
													<Field type="text" name="name" className={`form-control ${touched.name && errors.name ? "is-invalid mb-2" : ""}`} />
													<ErrorMessage name="name" component="div" className="invalid-feedback" />
												</div>
												<div className="form-group mb-3">
													<label htmlFor="email">Email</label>
													<Field type="text" name="email" className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`} />
													<ErrorMessage name="email" component="div" className="invalid-feedback" />
												</div>
												<div className="form-group mb-3">
													<label htmlFor="address">Street Address</label>
													<Field type="text" name="address" className={`form-control ${touched.address && errors.address ? "is-invalid mb-2" : ""}`} />
													<ErrorMessage name="address" component="div" className="invalid-feedback" />
												</div>
												<div className="form-group mb-3">
													<label htmlFor="phoneNo" className="form-label">
														Phone Number
													</label>
													<PhoneInput
														country="us" // Set your default country
														inputProps={{
															name: "phoneNo",
															className: ` w-100 h-auto form-control ${touched.phoneNo && errors.phoneNo ? "is-invalid mb-2" : ""}`,
														}}
														value={values.phoneNo}
														onChange={(phoneNo) => setFieldValue("phoneNo", phoneNo)}
													/>
													<ErrorMessage name="phoneNo" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
												</div>
												<div className="form-group mb-3">
													<label htmlFor="website">Website</label>
													<Field type="text" name="website" className={`form-control ${touched.website && errors.website ? "is-invalid mb-2" : ""}`} />
													<ErrorMessage name="website" component="div" className="invalid-feedback" />
												</div>
												<div className="form-group mb-3">
													<label htmlFor="owner">Owner Name</label>
													<Field type="text" name="owner" className={`form-control ${touched.owner && errors.owner ? "is-invalid mb-2" : ""}`} />
													<ErrorMessage name="owner" component="div" className="invalid-feedback" />
												</div>
											</div>
										</div>
										{/* <div className="card custom-card panel-theme">
											<div className="card-header">
												<div className="float-start">
													<h3 className="card-title">Qualifications</h3>
												</div>
												<div className="clearfix" />
											</div>
											<div className="card-body">
												<div className="form-group mb-3">
													<label htmlFor="education" className="form-label">
														Education
													</label>
													<Field type="text" name="education" className={`form-control ${touched.education && errors.education ? "is-invalid mb-2" : ""}`} />
													<ErrorMessage name="education" component="div" className="invalid-feedback" />
												</div>
												<div className="form-group mb-3">
													<label htmlFor="yearsOfExperience" className="form-label">
														Years of Experience
													</label>
													<Field type="number" name="yearsOfExperience" className={`form-control ${touched.yearsOfExperience && errors.yearsOfExperience ? "is-invalid mb-2" : ""}`} />
													<ErrorMessage name="yearsOfExperience" component="div" className="invalid-feedback" />
												</div>
												<div className="form-group mb-3 ">
													<label htmlFor="certifications" className="form-label">
														Certifications
													</label>
													<TagsInput
														inputProps={{
															className: "react-tagsinput-input",
															placeholder: "Add Certification",
														}}
														placeholder="Add Certifications"
														onlyUnique={true}
														value={values.certifications}
														onChange={(tags) => setFieldValue("certifications", tags)}
													/>
													<ErrorMessage name="certifications" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
												</div>
											</div>
										</div> */}
									</div>
									<div className="col-xl-8 col-md-12 col-sm-12">
										<div className="card custom-card">
											<div className="card-header">
												<h3 className="card-title">Service Area</h3>
											</div>
											<div className="card-body">
												<div className="row">
													<div className="col-lg-6 col-md-12">
														<div className="form-group">
															<label htmlFor="serviceCoverageState">Service Coverage State</label>

															<Select
																id="state"
																name="state"
																options={updatedStates("US")}
																value={values.state}
																onChange={(value) => {
																	setFieldValue("state", value);
																}}
															/>
															<ErrorMessage name="serviceCoverageState" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
														</div>
													</div>
													<div className="col-lg-6 col-md-12">
														<div className="form-group">
															<label htmlFor="city">Service Coverage City</label>
															<Select
																id="city"
																name="city"
																options={updatedCities("US", values.state ? values.state.value : null)}
																value={values.city}
																onChange={(value) => setFieldValue("city", value)}
															/>{" "}
															<ErrorMessage name="city" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label htmlFor="zipCodes">Service Coverage Zip Codes</label>
															<Field type="number" name="zipCodes" className={`form-control ${touched.zipCodes && errors.zipCodes ? "is-invalid mb-2" : ""}`} />
															<ErrorMessage name="zipCodes" component="div" className="invalid-feedback" />
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group">
															<label className={` ${touched.serviceType && errors.serviceType ? "is-invalid mb-2" : ""}`}>Service Type</label>
															<div>
																<div className="form-check form-check-inline">
																	<label className="form-check-label">
																		<Field type="radio" name="serviceType" value="incall" className="form-check-input" />
																		Incall
																	</label>
																</div>
																<div className="form-check form-check-inline">
																	<label className="form-check-label">
																		<Field type="radio" name="serviceType" value="outcall" className="form-check-input" />
																		Outcall
																	</label>
																</div>
															</div>
															<ErrorMessage name="serviceType" component="div" className="invalid-feedback" />
														</div>
													</div>
												</div>
												<div className="form-group">
													<label htmlFor="machineExpertise">Types of Machine Expertise</label>
													{data?.eMachine && (
														<CreatableSelect
															id="machineExpertise"
															name="machineExpertise"
															options={newEMachine}
															value={newEMachine.filter((option) => values?.machineExpertise.includes(option.value))}
															isMulti
															onChange={(selectedOptions) => {
																setFieldValue("machineExpertise", selectedOptions ? selectedOptions.map((option) => option.value) : []);
															}}
															onCreateOption={(newOption) => {
																// Add logic to handle creating a new option
																const nextValue = newEMachine.length + 1;
																const newValue = { value: nextValue, label: newOption };

																// Update the options list
																const updatedOptions = [...data.eMachine, newValue];

																// Update the state with the updated options list
																setNewEMachine([...newEMachine]);
																setCreateEMachine([...createEMachine]);

																setFieldValue("machineExpertise", [...values.machineExpertise, newValue.value]);
															}}
														/>
													)}
													<ErrorMessage name="machineExpertise" component="div" style={{ color: "#dc3545", marginTop: ".25rem", fontSize: ".875em" }} />
												</div>
											</div>
											<div className="card-footer text-end">
												{!company?.name && (
													<button type="submit" className="btn btn-success" disabled={isSubmitting || savedCompany?.loadingSaveCompany || savedCompany?.savedCompany !== null}>
														Submit <ClipLoader loading={savedCompany?.loadingSaveCompany} size={"10"} />
													</button>
												)}
											</div>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				)}
			</div>
		</>
	);
};

export default CompanyTheme;
